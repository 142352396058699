img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 .07em !important;
    vertical-align: -.1em !important;
    background: 0 0 !important;
    padding: 0 !important
}

body {
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, .2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, .4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, .2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1)
}

:where(.is-layout-flex) {
    gap: .5em
}

:where(.is-layout-grid) {
    gap: .5em
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em
}

.wp-block-yoast-faq-block .schema-faq-question {
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin: 0;
    padding: 15px 40px 15px 15px;
    line-height: 1.4;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block
}

.wp-block-yoast-faq-block .schema-faq-question.faq-q-open {
    border-bottom: 1px solid #d1dfee
}

.wp-block-yoast-faq-block .schema-faq-question:after {
    content: "+";
    position: absolute;
    top: 0;
    right: 15px;
    text-align: center;
    font-weight: 700;
    color: #000;
    font-size: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.wp-block-yoast-faq-block .schema-faq-question.faq-q-open:after {
    content: "-"
}

.wp-block-yoast-faq-block p.schema-faq-answer {
    margin: 0;
    padding: 15px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.4;
    border-bottom: 1px solid #dedee0;
    display: none
}

.note-content {
    display: block;
    overflow: hidden;
    border-top: 1px solid var(--heading-border-color)
}

.note-content>:not(:last-child) {
    margin-bottom: 1.5rem
}

.note-header {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px
}

.yes-toggle .note-header {
    cursor: pointer
}

.yes-toggle .note-content {
    display: none
}

.notice-text:not(:empty) {
    display: block;
    padding-top: 15px
}

.submitting:before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: .9;
    background: var(--solid-light)
}

.submitting {
    cursor: wait
}

.gb-download:not(.submitting) .rb-loader {
    display: none
}

.fallback-info {
    font-size: var(--rem-mini);
    font-style: italic;
    margin-bottom: 15px;
    color: var(--meta-fcolor)
}

.accordion-item-content {
    display: none;
    padding-top: 1.5rem;
    border-bottom: 1px solid var(--flex-gray-15)
}

.gb-accordion-item:last-child .accordion-item-content {
    border-bottom: none
}

.yes-open .gb-accordion-item:first-child .accordion-item-content {
    display: block
}

.accordion-item-content>* {
    margin-bottom: 1.5rem
}

#ays_tooltip {
    width: auto;
    padding: 3px;
    box-sizing: border-box;
    word-break: keep-all;
    -webkit-hyphens: none;
    white-space: nowrap
}

#ays_tooltip p {
    margin: 0;
    padding: 0
}

.dialog-widget-content {
    background-color: var(--e-a-bg-default);
    position: absolute;
    border-radius: 3px;
    box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, .2);
    overflow: hidden
}

.dialog-message {
    line-height: 1.5;
    box-sizing: border-box
}

.dialog-close-button {
    cursor: pointer;
    position: absolute;
    margin-block-start: 15px;
    right: 15px;
    color: var(--e-a-color-txt);
    font-size: 15px;
    line-height: 1;
    transition: var(--e-a-transition-hover)
}

.dialog-close-button:hover {
    color: var(--e-a-color-txt-hover)
}

.dialog-prevent-scroll {
    overflow: hidden;
    max-height: 100vh
}

.dialog-type-lightbox {
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.dialog-type-alert .dialog-widget-content,
.dialog-type-confirm .dialog-widget-content {
    margin: auto;
    width: 400px;
    padding: 20px
}

.dialog-type-alert .dialog-header,
.dialog-type-confirm .dialog-header {
    font-size: 15px;
    font-weight: 500
}

.dialog-type-alert .dialog-header:after,
.dialog-type-confirm .dialog-header:after {
    content: "";
    display: block;
    border-block-end: var(--e-a-border);
    padding-block-end: 10px;
    margin-block-end: 10px;
    margin-inline-start: -20px;
    margin-inline-end: -20px
}

.dialog-type-alert .dialog-message,
.dialog-type-confirm .dialog-message {
    min-height: 50px
}

.dialog-type-alert .dialog-buttons-wrapper,
.dialog-type-confirm .dialog-buttons-wrapper {
    padding-block-start: 10px;
    display: flex;
    justify-content: flex-end;
    gap: 15px
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    padding: 8px 16px;
    outline: 0;
    border: none;
    border-radius: var(--e-a-border-radius);
    background-color: var(--e-a-btn-bg);
    color: var(--e-a-btn-color-invert);
    transition: var(--e-a-transition-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
    border: none
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover {
    background-color: var(--e-a-btn-bg-hover);
    color: var(--e-a-btn-color-invert)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:active {
    background-color: var(--e-a-btn-bg-active)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:not([disabled]),
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:not([disabled]) {
    cursor: pointer
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:disabled {
    background-color: var(--e-a-btn-bg-disabled);
    color: var(--e-a-btn-color-disabled)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:not(.elementor-button-state) .elementor-state-icon,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:not(.elementor-button-state) .elementor-state-icon {
    display: none
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt {
    background: 0 0;
    color: var(--e-a-color-txt)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:hover {
    background: var(--e-a-bg-hover);
    color: var(--e-a-color-txt-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel:disabled,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel:disabled,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt:disabled {
    background: 0 0;
    color: var(--e-a-color-txt-disabled)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt-border,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt-border {
    border: 1px solid var(--e-a-color-txt-muted)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success {
    background-color: var(--e-a-btn-bg-success)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-success:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-success:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-success:hover {
    background-color: var(--e-a-btn-bg-success-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary {
    background-color: var(--e-a-btn-bg-primary);
    color: var(--e-a-btn-color)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary:hover {
    background-color: var(--e-a-btn-bg-primary-hover);
    color: var(--e-a-btn-color)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt {
    background: 0 0;
    color: var(--e-a-color-primary-bold)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-cancel.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.dialog-ok.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-ok:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-btn-txt.dialog-take_over:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.dialog-cancel:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-primary.e-btn-txt:hover {
    background: var(--e-a-bg-primary)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro {
    background-color: var(--e-a-btn-bg-accent)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:hover {
    background-color: var(--e-a-btn-bg-accent-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.go-pro:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-accent:active,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.go-pro:active {
    background-color: var(--e-a-btn-bg-accent-active)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info {
    background-color: var(--e-a-btn-bg-info)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-info:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-info:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-info:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-info:hover {
    background-color: var(--e-a-btn-bg-info-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning {
    background-color: var(--e-a-btn-bg-warning)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-warning:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-warning:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-warning:hover {
    background-color: var(--e-a-btn-bg-warning-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger {
    background-color: var(--e-a-btn-bg-danger)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button.elementor-button-danger:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.e-danger:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button.elementor-button-danger:hover {
    background-color: var(--e-a-btn-bg-danger-hover)
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button i,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button i {
    margin-inline-end: 5px
}

.dialog-type-alert .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-alert .dialog-buttons-wrapper .dialog-button:visited,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:focus,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:hover,
.dialog-type-confirm .dialog-buttons-wrapper .dialog-button:visited {
    color: initial
}

.elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.elementor *,
.elementor :after,
.elementor :before {
    box-sizing: border-box
}

.elementor a {
    box-shadow: none;
    text-decoration: none
}

.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none
}

.elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure {
    margin: 0
}

.elementor iframe,
.elementor object,
.elementor video {
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none
}

.elementor .elementor-background-video-container {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr
}

.elementor .elementor-background-video-container {
    transition: opacity 1s;
    pointer-events: none
}

.elementor .elementor-background-video-container.elementor-loading {
    opacity: 0
}

.elementor .elementor-background-video-embed {
    max-width: none
}

.elementor .elementor-background-video-embed,
.elementor .elementor-background-video-hosted {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.elementor .elementor-background-slideshow {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.elementor .elementor-background-slideshow {
    z-index: 0
}

.elementor .elementor-background-slideshow__slide__image {
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover
}

.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self)
}

.elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap)
}

.elementor-invisible {
    visibility: hidden
}

.elementor-ken-burns {
    transition-property: transform;
    transition-duration: 10s;
    transition-timing-function: linear
}

.elementor-ken-burns--out {
    transform: scale(1.3)
}

.elementor-ken-burns--active {
    transition-duration: 20s
}

.elementor-ken-burns--active.elementor-ken-burns--out {
    transform: scale(1)
}

.elementor-ken-burns--active.elementor-ken-burns--in {
    transform: scale(1.3)
}

:root {
    --page-title-display: block
}

.elementor-section {
    position: relative
}

.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

@media (max-width:1024px) {
    .elementor-section .elementor-container {
        flex-wrap: wrap
    }
}

.elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex
}

.elementor-widget-wrap>.elementor-element {
    width: 100%
}

.elementor-widget-wrap.e-swiper-container {
    width: calc(100% - (var(--e-column-margin-left, 0px) + var(--e-column-margin-right, 0px)))
}

.elementor-widget {
    position: relative
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px
}

.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex
}

.elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0
}

@media (min-width:768px) {
    .elementor-column.elementor-col-25 {
        width: 25%
    }

    .elementor-column.elementor-col-50 {
        width: 50%
    }

    .elementor-column.elementor-col-100 {
        width: 100%
    }
}

@media (min-width:1025px) {
    #elementor-device-mode:after {
        content: "desktop"
    }
}

@media (min-width:1) {
    #elementor-device-mode:after {
        content: "widescreen"
    }
}

@media (max-width:1) {
    #elementor-device-mode:after {
        content: "laptop";
        content: "tablet_extra"
    }
}

@media (max-width:1024px) {
    #elementor-device-mode:after {
        content: "tablet"
    }
}

@media (max-width:1) {
    #elementor-device-mode:after {
        content: "mobile_extra"
    }
}

@media (max-width:767px) {
    .elementor-column {
        width: 100%
    }

    #elementor-device-mode:after {
        content: "mobile"
    }
}

.e-con {
    --border-radius: 0;
    --border-block-start-width: 0px;
    --border-inline-end-width: 0px;
    --border-block-end-width: 0px;
    --border-inline-start-width: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: min(100%, var(--container-max-width, 1140px));
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-block-start: 0px;
    --margin-inline-end: 0px;
    --margin-block-end: 0px;
    --margin-inline-start: 0px;
    --padding-block-start: var(--container-default-padding-block-start, 10px);
    --padding-inline-end: var(--container-default-padding-inline-end, 10px);
    --padding-block-end: var(--container-default-padding-block-end, 10px);
    --padding-inline-start: var(--container-default-padding-inline-start, 10px);
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    margin-block-start: var(--bc-margin-block-start, var(--margin-block-start));
    margin-inline-end: var(--bc-margin-inline-end, var(--margin-inline-end));
    margin-block-end: var(--bc-margin-block-end, var(--margin-block-end));
    margin-inline-start: var(--bc-margin-inline-start, var(--margin-inline-start));
    padding-inline-start: var(--bc-padding-inline-start, var(--padding-inline-start));
    padding-inline-end: var(--bc-padding-inline-end, var(--padding-inline-end));
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s)
}

.e-con.e-flex {
    --flex-direction: column;
    --flex-basis: auto;
    --flex-grow: 0;
    --flex-shrink: 1;
    flex: var(--flex-grow) var(--flex-shrink) var(--flex-basis)
}

.e-con>.e-con-inner {
    text-align: var(--text-align);
    padding-block-start: var(--bc-padding-block-start, var(--padding-block-start));
    padding-block-end: var(--bc-padding-block-end, var(--padding-block-end))
}

.e-con.e-flex>.e-con-inner {
    flex-direction: var(--flex-direction)
}

.e-con,
.e-con>.e-con-inner {
    display: var(--display)
}

.e-con-boxed.e-flex {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: normal;
    align-content: normal
}

.e-con-boxed {
    text-align: initial;
    gap: initial
}

.e-con.e-flex>.e-con-inner {
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto
}

.e-con>.e-con-inner {
    gap: var(--gap);
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    padding-inline-start: 0;
    padding-inline-end: 0;
    height: 100%
}

:is(.elementor-section-wrap, [data-elementor-id])>.e-con {
    --margin-inline-end: auto;
    --margin-inline-start: auto;
    max-width: min(100%, var(--width))
}

.e-con .elementor-widget.elementor-widget {
    margin-block-end: 0
}

.e-con:before,
.e-con>.elementor-background-slideshow:before,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, .3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-block-start-width));
    left: calc(0px - var(--border-inline-start-width));
    width: max(100% + var(--border-inline-end-width) + var(--border-inline-start-width), 100%);
    height: max(100% + var(--border-block-start-width) + var(--border-block-end-width), 100%)
}

.e-con:before {
    transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s)
}

.e-con>.elementor-background-slideshow,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container {
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-block-start-width));
    left: calc(0px - var(--border-inline-start-width));
    width: max(100% + var(--border-inline-end-width) + var(--border-inline-start-width), 100%);
    height: max(100% + var(--border-block-start-width) + var(--border-block-end-width), 100%)
}

:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    z-index: 1
}

:is(.e-con, .e-con>.e-con-inner)>.elementor-background-slideshow:before {
    z-index: 2
}

.e-con .elementor-widget {
    min-width: 0
}

.e-con .elementor-widget.e-widget-swiper {
    width: 100%
}

.e-con>.e-con-inner>.elementor-widget>.elementor-widget-container,
.e-con>.elementor-widget>.elementor-widget-container {
    height: 100%
}

.e-con.e-con>.e-con-inner>.elementor-widget,
.elementor.elementor .e-con>.elementor-widget {
    max-width: 100%
}

@media (max-width:767px) {
    .e-con.e-flex {
        --width: 100%;
        --flex-wrap: wrap
    }
}

.e-con {
    --bc-margin-block-start: var(--margin-top);
    --bc-margin-block-end: var(--margin-bottom);
    --bc-margin-inline-start: var(--margin-left);
    --bc-margin-inline-end: var(--margin-right);
    --bc-padding-block-start: var(--padding-top);
    --bc-padding-block-end: var(--padding-bottom);
    --bc-padding-inline-start: var(--padding-left);
    --bc-padding-inline-end: var(--padding-right)
}

.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s)
}

.elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px
}

.elementor-element .swiper .swiper-slide figure,
.elementor-element .swiper-container .swiper-slide figure {
    line-height: 0
}

.elementor-element .swiper .elementor-swiper-button,
.elementor-element .swiper-container .elementor-swiper-button,
.elementor-element .swiper-container~.elementor-swiper-button,
.elementor-element .swiper~.elementor-swiper-button {
    position: absolute;
    display: inline-flex;
    z-index: 1;
    cursor: pointer;
    font-size: 25px;
    color: hsla(0, 0%, 93.3%, .9);
    top: 50%;
    transform: translateY(-50%)
}

.elementor-element .swiper .elementor-swiper-button svg,
.elementor-element .swiper-container .elementor-swiper-button svg,
.elementor-element .swiper-container~.elementor-swiper-button svg,
.elementor-element .swiper~.elementor-swiper-button svg {
    fill: hsla(0, 0%, 93.3%, .9);
    height: 1em;
    width: 1em
}

.elementor-element .swiper .elementor-swiper-button-prev,
.elementor-element .swiper-container .elementor-swiper-button-prev,
.elementor-element .swiper-container~.elementor-swiper-button-prev,
.elementor-element .swiper~.elementor-swiper-button-prev {
    left: 10px
}

.elementor-element .swiper .elementor-swiper-button-next,
.elementor-element .swiper-container .elementor-swiper-button-next,
.elementor-element .swiper-container~.elementor-swiper-button-next,
.elementor-element .swiper~.elementor-swiper-button-next {
    right: 10px
}

.elementor-element .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container~.elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper~.elementor-swiper-button.swiper-button-disabled {
    opacity: .3
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets {
    bottom: 5px;
    left: 0;
    width: 100%
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px)
}

.elementor-lightbox:not(.elementor-popup-modal) .dialog-header,
.elementor-lightbox:not(.elementor-popup-modal) .dialog-message {
    text-align: center
}

.elementor-lightbox .dialog-header {
    display: none
}

.elementor-lightbox .dialog-widget-content {
    background: 0 0;
    box-shadow: none;
    width: 100%;
    height: 100%
}

.elementor-lightbox .dialog-message {
    animation-duration: .3s;
    height: 100%
}

.elementor-lightbox .dialog-message.dialog-lightbox-message {
    padding: 0
}

.elementor-lightbox .dialog-lightbox-close-button {
    cursor: pointer;
    position: absolute;
    font-size: var(--lightbox-header-icons-size);
    right: .75em;
    margin-top: 13px;
    padding: .25em;
    z-index: 2;
    line-height: 1;
    display: flex
}

.elementor-lightbox .dialog-lightbox-close-button svg {
    height: 1em;
    width: 1em
}

.elementor-lightbox .dialog-lightbox-close-button {
    color: var(--lightbox-ui-color);
    transition: all .3s;
    opacity: 1
}

.elementor-lightbox .dialog-lightbox-close-button svg {
    fill: var(--lightbox-ui-color)
}

.elementor-lightbox .dialog-lightbox-close-button:hover {
    color: var(--lightbox-ui-color-hover)
}

.elementor-lightbox .dialog-lightbox-close-button:hover svg {
    fill: var(--lightbox-ui-color-hover)
}

.elementor-lightbox .elementor-video-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape,
.elementor-lightbox .elementor-video-container .elementor-video-portrait,
.elementor-lightbox .elementor-video-container .elementor-video-square {
    width: 100%;
    height: 100%;
    margin: auto
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe {
    border: 0;
    background-color: #000
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-landscape video,
.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video,
.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
    aspect-ratio: var(--video-aspect-ratio, 1.77777)
}

@supports not (aspect-ratio:1/1) {

    .elementor-lightbox .elementor-video-container .elementor-video-landscape,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait,
    .elementor-lightbox .elementor-video-container .elementor-video-square {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: calc(100% / var(--video-aspect-ratio, 1.77777))
    }

    .elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-landscape video,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-portrait video,
    .elementor-lightbox .elementor-video-container .elementor-video-square iframe,
    .elementor-lightbox .elementor-video-container .elementor-video-square video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0
    }
}

.elementor-lightbox .elementor-video-container .elementor-video-square iframe,
.elementor-lightbox .elementor-video-container .elementor-video-square video {
    width: min(90vh, 90vw);
    height: min(90vh, 90vw)
}

.elementor-lightbox .elementor-video-container .elementor-video-landscape iframe,
.elementor-lightbox .elementor-video-container .elementor-video-landscape video {
    width: 100%;
    max-height: 90vh
}

.elementor-lightbox .elementor-video-container .elementor-video-portrait iframe,
.elementor-lightbox .elementor-video-container .elementor-video-portrait video {
    height: 100%;
    max-width: 90vw
}

@media (min-width:1025px) {
    .elementor-lightbox .elementor-video-container .elementor-video-landscape {
        width: 85vw;
        max-height: 85vh
    }

    .elementor-lightbox .elementor-video-container .elementor-video-portrait {
        height: 85vh;
        max-width: 85vw
    }
}

@media (max-width:1024px) {
    .elementor-lightbox .elementor-video-container .elementor-video-landscape {
        width: 95vw;
        max-height: 95vh
    }

    .elementor-lightbox .elementor-video-container .elementor-video-portrait {
        height: 95vh;
        max-width: 95vw
    }

    body {
        --body-fsize: 16px;
        --h1-fsize: 32px;
        --h2-fsize: 24px;
        --h3-fsize: 17px;
        --h4-fsize: 15px;
        --h5-fsize: 13px;
        --h6-fsize: 12px;
        --meta-fsize: 11px;
        --readmore-fsize: 11px;
        --input-fsize: 13px;
        --btn-fsize: 11px;
        --bcrumb-fsize: 13px;
        --excerpt-fsize: 13px;
        --dwidgets-fsize: 12px;
        --headline-fsize: 32px;
        --headline-s-fsize: 32px;
        --tagline-fsize: 15px;
        --tagline-s-fsize: 15px
    }
}

.elementor-slideshow--fullscreen-mode .elementor-video-container {
    width: 100%
}

.animated {
    animation-duration: 1.25s
}

.animated.infinite {
    animation-iteration-count: infinite
}

.animated.reverse {
    animation-direction: reverse;
    animation-fill-mode: forwards
}

@media (prefers-reduced-motion:reduce) {
    .animated {
        animation: none
    }
}

.elementor-shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    line-height: 0;
    direction: ltr
}

.elementor-shape-top {
    top: -1px
}

.elementor-shape-top:not([data-negative=false]) svg {
    z-index: -1
}

.elementor-shape-bottom {
    bottom: -1px
}

.elementor-shape-bottom:not([data-negative=true]) svg {
    z-index: -1
}

.elementor-shape[data-negative=false].elementor-shape-bottom,
.elementor-shape[data-negative=true].elementor-shape-top {
    transform: rotate(180deg)
}

.elementor-shape svg {
    display: block;
    width: calc(100% + 1.3px);
    position: relative;
    left: 50%;
    transform: translateX(-50%)
}

.elementor-shape .elementor-shape-fill {
    fill: #fff;
    transform-origin: center;
    transform: rotateY(0)
}

#wpadminbar * {
    font-style: normal
}

:where(.elementor-widget-n-accordion .e-n-accordion-item>.e-con) {
    border: var(--n-accordion-border-width) var(--n-accordion-border-style) var(--n-accordion-border-color)
}

:root {
    --swiper-theme-color: #007aff
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box
}

.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform
}

:root {
    --swiper-navigation-size: 44px
}

.swiper-button-lock {
    display: none
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10
}

.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: 50%;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .2)
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

.swiper-pagination-bullet:only-child {
    display: none !important
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color))
}

.swiper-pagination-lock {
    display: none
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    left: 0;
    top: 0
}

.swiper-scrollbar-lock {
    display: none
}

.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-top-color: transparent
}

.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
    animation: 1s linear infinite swiper-preloader-spin
}

@keyframes swiper-preloader-spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000
}

.elementor-kit-50343 {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px
}

.e-con {
    --container-max-width: 1140px
}

.elementor-widget:not(:last-child) {
    margin-block-end: 20px
}

.elementor-element {
    --widgets-spacing: 20px 20px
}

h1.entry-title {
    display: var(--page-title-display)
}

.elementor-945 .elementor-element.elementor-element-8c2ec30>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-8c2ec30:not(.elementor-motion-effects-element-type-background) {
    /*background-color: #88888811;*/
    padding: 50px 0px;
    background: linear-gradient(83deg, #1b1b1b 25.29%, rgba(27, 27, 27, .69) 49.52%, rgba(27, 27, 27, .39) 66.36%, rgba(27, 27, 27, 0) 94.89%);
}
@media (max-width: 768px) {
    .elementor-945 .elementor-element.elementor-element-8c2ec30:not(.elementor-motion-effects-element-type-background) {
        margin-top: -40px;
    }
}

.elementor-945 .elementor-element.elementor-element-8c2ec30 {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s
}

.elementor-945 .elementor-element.elementor-element-8c2ec30>.elementor-shape-bottom svg {
    height: 70px
}

.elementor-945 .elementor-element.elementor-element-230e784 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-945 .elementor-element.elementor-element-230e784 {
    margin-top: 0;
    margin-bottom: 20px
}

.elementor-945 .elementor-element.elementor-element-2523998 {
    --feat-ratio: 70;
    --title-size: 32px;
    --meta-fsize: 14px;
    --highlight-overlay-bg: #111318
}

[data-theme=dark] .elementor-945 .elementor-element.elementor-element-2523998 {
    --highlight-overlay-bg: #111318
}

.elementor-945 .elementor-element.elementor-element-36eecfb {
    --feat-ratio: 100;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318
}

.elementor-945 .elementor-element.elementor-element-36eecfb .p-wrap {
    --el-spacing: 10px
}

.elementor-945 .elementor-element.elementor-element-96321ff {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-945 .elementor-element.elementor-element-96321ff .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-96321ff .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-96321ff .p-wrap {
    --el-spacing: 15px
}

.elementor-945 .elementor-element.elementor-element-96321ff>.elementor-widget-container {
    margin: 0 20px
}

.elementor-945 .elementor-element.elementor-element-d0f6e85>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-d0f6e85 {
    padding: 30px 0
}

.elementor-945 .elementor-element.elementor-element-aba3016 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-945 .elementor-element.elementor-element-a2504b0.elementor-column>.elementor-widget-wrap {
    justify-content: flex-end
}

.elementor-945 .elementor-element.elementor-element-e5df68d {
    --spacer-size: 10px
}

.elementor-945 .elementor-element.elementor-element-b204ec8>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-b204ec8 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 0
}

.elementor-945 .elementor-element.elementor-element-b204ec8 {
	background: #151515;
	background-image: url(../../assets/images/bg-image.31b166a9648293966af1.svg);
	padding: 50px 0px;
    margin-top: 0;
    /*margin-bottom: 50px*/
}

.elementor-945 .elementor-element.elementor-element-87aeb0c>.elementor-widget-container {
    padding: 50px 50px 120px;
    background-color: #282828;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 20px 10px 10px
}

.elementor-945 .elementor-element.elementor-element-c0b1e24 {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-945 .elementor-element.elementor-element-c0b1e24 .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-c0b1e24 .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-c0b1e24 .p-wrap {
    --el-spacing: 15px
}

.elementor-945 .elementor-element.elementor-element-c0b1e24>.elementor-widget-container {
    margin: -100px 20px 20px
}

.elementor-945 .elementor-element.elementor-element-9e4efb4>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-9e4efb4 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 0
}

.elementor-945 .elementor-element.elementor-element-9e4efb4 {
    background: #151515;
    background-image: url(../../assets/images/bg-image.31b166a9648293966af1.svg);
    margin-top: 0;
    /*margin-bottom: 30px;*/
    padding: 50px 0px;
}

.elementor-945 .elementor-element.elementor-element-4a1436b>.elementor-widget-container {
    padding: 50px 50px 120px;
    background-color: #282828;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 20px 10px 10px
}

.elementor-945 .elementor-element.elementor-element-f858267 {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-945 .elementor-element.elementor-element-f858267 .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-f858267 .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-f858267 .p-wrap {
    --el-spacing: 15px
}

.elementor-945 .elementor-element.elementor-element-f858267>.elementor-widget-container {
    margin: -100px 20px 0
}

.elementor-945 .elementor-element.elementor-element-1c3079d>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-1c3079d .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-945 .elementor-element.elementor-element-1c3079d:not(.elementor-motion-effects-element-type-background) {
    /*background-color: #88888811;*/
    /*background: #161616;*/
    background: #151515;
    background-image: url(../../assets/images/bg-image.31b166a9648293966af1.svg);
}

.elementor-945 .elementor-element.elementor-element-1c3079d {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    margin-top: 0;
    /*margin-bottom: 30px;*/
    padding-bottom: 30px;
}

.elementor-945 .elementor-element.elementor-element-1c3079d>.elementor-shape-top svg {
    height: 70px
}

.elementor-945 .elementor-element.elementor-element-1c3079d>.elementor-shape-bottom svg {
    height: 70px
}

.elementor-945 .elementor-element.elementor-element-101df6a .p-list-box-2 .list-feat-holder {
    width: 240px;
    max-width: 240px
}

.elementor-945 .elementor-element.elementor-element-101df6a {
    --box-color: #FFFFFF;
    --dark-box-color: #111318
}

.elementor-945 .elementor-element.elementor-element-101df6a .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-101df6a .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-d649753>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-d649753 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 0
}

.elementor-945 .elementor-element.elementor-element-f30ede2>.elementor-widget-container {
    padding: 50px 50px 120px;
    background-color: #282828;
    background-image: url(../../wp-content/uploads/2019/11/George-Clooney-with-his-Ex-girlfriend-Talia-Balsam.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px 20px 10px 10px
}

.elementor-945 .elementor-element.elementor-element-7d90077 {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-945 .elementor-element.elementor-element-7d90077 .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-7d90077 .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-7d90077>.elementor-widget-container {
    margin: -100px 20px 0
}

.elementor-945 .elementor-element.elementor-element-155feec>.elementor-container {
    max-width: 1280px
}

.elementor-945 .elementor-element.elementor-element-155feec .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-945 .elementor-element.elementor-element-155feec:not(.elementor-motion-effects-element-type-background) {
    /*background-color: #88888811;*/
    /*background: #161616;*/
    background: linear-gradient(267deg, #eea900 -2.97%, #0e448a 96.94%);
    box-shadow: inset 0 4px 184px 93px #fff3e1;
}

.elementor-945 .elementor-element.elementor-element-155feec {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 30px 0
}

.elementor-945 .elementor-element.elementor-element-155feec>.elementor-shape-top svg {
    height: 70px
}

.elementor-945 .elementor-element.elementor-element-155feec>.elementor-shape-bottom svg {
    height: 70px
}

.elementor-945 .elementor-element.elementor-element-837a14a {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-945 .elementor-element.elementor-element-837a14a .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-945 .elementor-element.elementor-element-837a14a .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-945 .elementor-element.elementor-element-837a14a .p-wrap {
    --el-spacing: 15px
}

@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px
    }

    .e-con {
        --container-max-width: 1024px
    }

    .elementor-945 .elementor-element.elementor-element-8c2ec30 {
        padding: 0
    }

    .elementor-945 .elementor-element.elementor-element-2523998 {
        --feat-ratio: 60;
        --title-size: 40px
    }

    .elementor-945 .elementor-element.elementor-element-730bbfc>.elementor-element-populated {
        margin: 0;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px
    }

    .elementor-945 .elementor-element.elementor-element-36eecfb {
        --feat-ratio: 60
    }

    .elementor-945 .elementor-element.elementor-element-96321ff {
        --feat-ratio: 60
    }

    .elementor-945 .elementor-element.elementor-element-d0f6e85 {
        padding: 20px 0
    }

    .elementor-945 .elementor-element.elementor-element-a2504b0>.elementor-element-populated {
        margin: -20px 0 0;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px
    }

    .elementor-945 .elementor-element.elementor-element-b204ec8 {
        margin-top: 0;
        /*margin-bottom: 40px*/
    }

    .elementor-945 .elementor-element.elementor-element-87aeb0c>.elementor-widget-container {
        border-radius: 0 0 0 0
    }

    .elementor-945 .elementor-element.elementor-element-c0b1e24 {
        --feat-ratio: 60
    }

    .elementor-945 .elementor-element.elementor-element-c0b1e24 .block-wrap {
        --bottom-spacing: 40px
    }

    .elementor-945 .elementor-element.elementor-element-9e4efb4 {
        margin-top: 0;
        /*margin-bottom: 20px*/
    }

    .elementor-945 .elementor-element.elementor-element-4a1436b>.elementor-widget-container {
        border-radius: 0 0 0 0
    }

    .elementor-945 .elementor-element.elementor-element-f858267 {
        --feat-ratio: 60
    }

    .elementor-945 .elementor-element.elementor-element-f858267 .block-wrap {
        --bottom-spacing: 40px
    }

    .elementor-945 .elementor-element.elementor-element-1c3079d {
        margin-top: 0;
        margin-bottom: 20px
    }

    .elementor-945 .elementor-element.elementor-element-d649753 {
        margin-top: 0;
        margin-bottom: 40px
    }

    .elementor-945 .elementor-element.elementor-element-f30ede2>.elementor-widget-container {
        border-radius: 0 0 0 0
    }

    .elementor-945 .elementor-element.elementor-element-7d90077 {
        --feat-ratio: 60
    }

    .elementor-945 .elementor-element.elementor-element-155feec {
        padding: 20px 0
    }

    .elementor-945 .elementor-element.elementor-element-837a14a {
        --feat-ratio: 60
    }
}

@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px
    }

    .e-con {
        --container-max-width: 767px
    }

    .elementor-945 .elementor-element.elementor-element-2523998 {
        --feat-ratio: 60;
        --title-size: 22px
    }

    .elementor-945 .elementor-element.elementor-element-730bbfc>.elementor-element-populated {
        margin: -20px 0 0;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px
    }

    .elementor-945 .elementor-element.elementor-element-36eecfb .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-945 .elementor-element.elementor-element-96321ff {
        --title-size: 12px
    }

    .elementor-945 .elementor-element.elementor-element-96321ff .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-945 .elementor-element.elementor-element-96321ff>.elementor-widget-container {
        margin: -20px 20px 0
    }

    .elementor-945 .elementor-element.elementor-element-c0b1e24 {
        --title-size: 12px
    }

    .elementor-945 .elementor-element.elementor-element-c0b1e24 .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-945 .elementor-element.elementor-element-f858267 {
        --title-size: 12px
    }

    .elementor-945 .elementor-element.elementor-element-f858267 .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-945 .elementor-element.elementor-element-7d90077 {
        --title-size: 12px
    }

    .elementor-945 .elementor-element.elementor-element-7d90077 .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-945 .elementor-element.elementor-element-155feec {
        padding: 0
    }

    .elementor-945 .elementor-element.elementor-element-837a14a {
        --title-size: 12px
    }

    .elementor-945 .elementor-element.elementor-element-837a14a .block-wrap {
        --bottom-spacing: 20px
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-945 .elementor-element.elementor-element-8874544 {
        width: 100%
    }

    .elementor-945 .elementor-element.elementor-element-730bbfc {
        width: 100%
    }

    .elementor-945 .elementor-element.elementor-element-a2504b0 {
        width: 100%
    }
}

.elementor-50415 .elementor-element.elementor-element-b4dae32>.elementor-container {
    max-width: 1280px
}

.elementor-50415 .elementor-element.elementor-element-b4dae32 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-50415 .elementor-element.elementor-element-74ce873>.elementor-element-populated {
    margin: 0 10% 0 0;
    --e-column-margin-right: 10%;
    --e-column-margin-left: 0%
}

.elementor-50415 .elementor-element.elementor-element-06dbd43 .the-logo img {
    max-width: 194px;
    width: 194px
}

.elementor-50415 .elementor-element.elementor-element-2d9d680 {
    font-size: 13px
}

[data-theme=dark] .elementor-50415 .elementor-element.elementor-element-2d9d680 {
    color: #fff
}

.elementor-50415 .elementor-element.elementor-element-c914dd4>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 15px
}

.elementor-50415 .elementor-element.elementor-element-817b451 .heading-tagline>* {
    line-height: 1.7em
}

.elementor-50415 .elementor-element.elementor-element-1463f03 {
    --sidebar-menu-spacing: 5px
}

.elementor-50415 .elementor-element.elementor-element-06ae006.elementor-column>.elementor-widget-wrap {
    justify-content: flex-end
}

.elementor-50415 .elementor-element.elementor-element-06ae006>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 15px
}

.elementor-50415 .elementor-element.elementor-element-f942f1a .heading-tagline>* {
    line-height: 1.7em
}

.elementor-50415 .elementor-element.elementor-element-e897e62 {
    --sidebar-menu-spacing: 5px
}

.elementor-50415 .elementor-element.elementor-element-1314585 .header-social-list i {
    font-size: 23px
}

.elementor-50415 .elementor-element.elementor-element-1996ad2>.elementor-container {
    max-width: 1280px
}

.elementor-50415 .elementor-element.elementor-element-1996ad2 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-50415 .elementor-element.elementor-element-5155754 {
    --divider-border-style: solid;
    --divider-color: #88888888;
    --divider-border-width: 1px
}

.elementor-50415 .elementor-element.elementor-element-5155754 .elementor-divider-separator {
    width: 100%
}

.elementor-50415 .elementor-element.elementor-element-5155754 .elementor-divider {
    padding-block-start: 0;
    padding-block-end: 0
}

.elementor-50415 .elementor-element.elementor-element-f4fb349 {
    text-align: center;
    font-size: 13px
}

[data-theme=dark] .elementor-50415 .elementor-element.elementor-element-f4fb349 {
    color: #fff
}

@media(min-width:768px) {
    .elementor-50415 .elementor-element.elementor-element-74ce873 {
        width: 60%
    }

    .elementor-50415 .elementor-element.elementor-element-c914dd4 {
        color: var(--privacy-color);
        width: 20%
    }

    .elementor-50415 .elementor-element.elementor-element-06ae006 {
        color: var(--privacy-color);
        width: 20%
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-50415 .elementor-element.elementor-element-74ce873 {
        width: 100%
    }
    .elementor-50415 .elementor-element.elementor-element-06ae006 {
        color: var(--privacy-color);
        width: 50%
    }
    .elementor-50415 .elementor-element.elementor-element-c914dd4 {
        color: var(--privacy-color);
        width: 50%
    }
}

@media(max-width:1024px) {
    .elementor-50415 .elementor-element.elementor-element-74ce873>.elementor-element-populated {
        margin: 0;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 0%
    }
}

@media(max-width:767px) {
    .elementor-50415 .elementor-element.elementor-element-74ce873>.elementor-element-populated {
        margin: 0;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 0%
    }

    .elementor-50415 .elementor-element.elementor-element-c914dd4 {
        color: var(--privacy-color);
        width: 50%
    }

    .elementor-50415 .elementor-element.elementor-element-06ae006 {
        color: var(--privacy-color);
        width: 50%
    }
}

.elementor-50418 .elementor-element.elementor-element-dede56b .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px;
    padding-top: 0px;
}

.elementor-50418 .elementor-element.elementor-element-23da2d8 {
    --divider-border-style: wavy;
    --divider-color: #FF1800;
    --divider-border-width: 1px;
    --divider-pattern-height: 10px;
    --divider-pattern-size: 50px
}

.elementor-50418 .elementor-element.elementor-element-23da2d8 .elementor-divider-separator {
    width: 100%
}

.elementor-50418 .elementor-element.elementor-element-23da2d8 .elementor-divider {
    padding-block-start: 0;
    padding-block-end: 0
}

.elementor-50418 .elementor-element.elementor-element-726d36a .p-list-small-2 .feat-holder {
    width: 120px
}

.elementor-50418 .elementor-element.elementor-element-726d36a {
    --meta-fsize: 13px
}

.elementor-50418 .elementor-element.elementor-element-726d36a>.elementor-widget-container {
    margin: 0 0 20px
}

.elementor-50418 .elementor-element.elementor-element-29eb4ca {
    --s-icon-size: 15px;
    --s-columns: 1
}

.elementor-50418 .elementor-element.elementor-element-29eb4ca {
    --column-gap: 20px
}

.elementor-50418 .elementor-element.elementor-element-29eb4ca>.elementor-widget-container {
    margin: 0 0 20px
}

@media(max-width:1024px) {
    .elementor-50418 .elementor-element.elementor-element-29eb4ca {
        --s-columns: 3
    }

    .elementor-50418 .elementor-element.elementor-element-29eb4ca>.elementor-widget-container {
        margin: 0 0 10px
    }
}

@media(max-width:767px) {
    .elementor-50418 .elementor-element.elementor-element-29eb4ca {
        --s-icon-size: 13px;
        --s-columns: 1
    }

    .elementor-50418 .elementor-element.elementor-element-29eb4ca {
        --column-gap: 10px
    }

    .elementor-50418 .elementor-element.elementor-element-29eb4ca>.elementor-widget-container {
        margin: 0
    }
}

:root {
    --effect: all .25s cubic-bezier(0.32, 0.74, 0.57, 1);
    --timing: cubic-bezier(0.32, 0.74, 0.57, 1);
    --shadow-12: #0000001f;
    --shadow-20: #00000005;
    --round-3: 3px;
    --height-34: 34px;
    --height-40: 40px;
    --padding-40: 0 40px;
    --round-5: 5px;
    --round-7: 7px;
    --shadow-7: #00000012;
    --dark-accent: #191c20;
    --dark-accent-0: #191c2000;
    --dark-accent-90: #191c20f2;
    --meta-b-family: var(--meta-family);
    --meta-b-fcolor: #282828;
    --meta-b-fspace: normal;
    --meta-b-fstyle: normal;
    --meta-b-fweight: 600;
    --meta-b-transform: none;
    --nav-bg: #ffffff;
    --nav-bg-from: #ffffff;
    --nav-bg-to: #ffffff;
    --subnav-bg: #ffffff;
    --subnav-bg-from: #ffffff;
    --subnav-bg-to: #ffffff;
    --indicator-bg-from: #ffa052;
    --indicator-bg-to: #54595F;
    --bottom-border-color: var(--flex-gray-15);
    --column-border-color: var(--flex-gray-15);
    --audio-color: #ffa052;
    --dribbble-color: #fb70a6;
    --excerpt-color: #666666;
    --fb-color: #89abfc;
    --g-color: #ff184e;
    --live-color: #fe682e;
    --gallery-color: #729dff;
    --heading-color: var(--body-fcolor);
    --ig-color: #7e40b6;
    --linkedin-color: #007bb6;
    /*--nav-color: #282828;*/
    --nav-color: #ffaa12;
    --pin-color: #f74c53;
    --flipboard-color: #f52828;
    --privacy-color: #ffffff;
    --review-color: #ffc300;
    --soundcloud-color: #fd794a;
    --subnav-color: #282828;
    --telegam-color: #649fdf;
    --twitter-color: #00151c;
    --medium-color: #000000;
    --video-color: #ffc300;
    --vimeo-color: #44bbe1;
    --ytube-color: #ef464b;
    --nav-color-10: #28282820;
    --subnav-color-10: #28282820;
    --g-color-90: #ff184ee6;
    --absolute-dark: #242424;
    --body-family: 'Oxygen', sans-serif;
    --btn-family: 'Encode Sans Condensed', sans-serif;
    --cat-family: 'Oxygen', sans-serif;
    --dwidgets-family: 'Oxygen', sans-serif;
    --h1-family: 'Encode Sans Condensed', sans-serif;
    --h2-family: 'Encode Sans Condensed', sans-serif;
    --h3-family: 'Encode Sans Condensed', sans-serif;
    --h4-family: 'Encode Sans Condensed', sans-serif;
    --h5-family: 'Encode Sans Condensed', sans-serif;
    --h6-family: 'Encode Sans Condensed', sans-serif;
    --input-family: 'Encode Sans Condensed', sans-serif;
    --menu-family: 'Encode Sans Condensed', sans-serif;
    --meta-family: 'Encode Sans Condensed', sans-serif;
    --submenu-family: 'Encode Sans Condensed', sans-serif;
    --tagline-family: 'Encode Sans Condensed', sans-serif;
    --body-fcolor: #282828;
    --btn-fcolor: inherit;
    --h1-fcolor: inherit;
    --h2-fcolor: inherit;
    --h3-fcolor: inherit;
    --h4-fcolor: inherit;
    --h5-fcolor: inherit;
    --h6-fcolor: inherit;
    --input-fcolor: inherit;
    --meta-fcolor: #666666;
    --tagline-fcolor: inherit;
    --body-fsize: 16px;
    --btn-fsize: 12px;
    --cat-fsize: 10px;
    --dwidgets-fsize: 13px;
    --excerpt-fsize: 14px;
    --h1-fsize: 40px;
    --h2-fsize: 28px;
    --h3-fsize: 22px;
    --h4-fsize: 16px;
    --h5-fsize: 14px;
    --h6-fsize: 13px;
    --headline-fsize: 45px;
    --input-fsize: 14px;
    --menu-fsize: 17px;
    --meta-fsize: 13px;
    --submenu-fsize: 13px;
    --tagline-fsize: 28px;
    --body-fspace: normal;
    --btn-fspace: normal;
    --cat-fspace: .07em;
    --dwidgets-fspace: 0;
    --h1-fspace: normal;
    --h2-fspace: normal;
    --h3-fspace: normal;
    --h4-fspace: normal;
    --h5-fspace: normal;
    --h6-fspace: normal;
    --input-fspace: normal;
    --menu-fspace: -.02em;
    --meta-fspace: normal;
    --submenu-fspace: -.02em;
    --tagline-fspace: normal;
    --body-fstyle: normal;
    --btn-fstyle: normal;
    --cat-fstyle: normal;
    --dwidgets-fstyle: normal;
    --h1-fstyle: normal;
    --h2-fstyle: normal;
    --h3-fstyle: normal;
    --h4-fstyle: normal;
    --h5-fstyle: normal;
    --h6-fstyle: normal;
    --input-fstyle: normal;
    --menu-fstyle: normal;
    --meta-fstyle: normal;
    --submenu-fstyle: normal;
    --tagline-fstyle: normal;
    --body-fweight: 400;
    --btn-fweight: 700;
    --cat-fweight: 700;
    --dwidgets-fweight: 600;
    --h1-fweight: 700;
    --h2-fweight: 700;
    --h3-fweight: 700;
    --h4-fweight: 700;
    --h5-fweight: 700;
    --h6-fweight: 600;
    --input-fweight: 400;
    --menu-fweight: 600;
    --meta-fweight: 400;
    --submenu-fweight: 500;
    --tagline-fweight: 400;
    --flex-gray-15: #88888826;
    --flex-gray-20: #88888833;
    --flex-gray-40: #88888866;
    --flex-gray-7: #88888812;
    --dribbble-hcolor: #ff407f;
    --fb-hcolor: #1f82ec;
    --ig-hcolor: #8823b6;
    --linkedin-hcolor: #006ab1;
    --pin-hcolor: #f60c19;
    --flipboard-hcolor: #e00a0a;
    --soundcloud-hcolor: #ff5313;
    --telegam-hcolor: #3885d9;
    --twitter-hcolor: #13b9ee;
    --medium-hcolor: #ffb600;
    --vimeo-hcolor: #16b1e3;
    --ytube-hcolor: #fc161e;
    --indicator-height: 4px;
    --nav-height: 60px;
    --absolute-light: #dddddd;
    --solid-light: #fafafa;
    --em-mini: .8em;
    --rem-mini: .8rem;
    --transparent-nav-color: #ffffff;
    --swiper-navigation-size: 44px;
    --tagline-s-fsize: 22px;
    --em-small: .92em;
    --rem-small: .92rem;
    --bottom-spacing: 35px;
    --box-spacing: 5%;
    --el-spacing: 12px;
    --heading-sub-color: var(--g-color);
    --body-transform: none;
    --btn-transform: none;
    --cat-transform: uppercase;
    --dwidgets-transform: none;
    --h1-transform: none;
    --h2-transform: none;
    --h3-transform: none;
    --h4-transform: none;
    --h5-transform: none;
    --h6-transform: none;
    --input-transform: none;
    --menu-transform: none;
    --meta-transform: none;
    --submenu-transform: none;
    --tagline-transform: none;
    --awhite: #ffffff;
    --solid-white: #ffffff;
    --max-width-wo-sb: 100%;
    --alignwide-w: 1600px;
    --bookmark-color: #62b088;
    --bookmark-color-90: #62b088f2
}

.light-scheme,
[data-theme=dark] {
    --shadow-12: #00000066;
    --shadow-20: #00000033;
    --shadow-7: #0000004d;
    --dark-accent: #0e0f12;
    --dark-accent-0: #0e0f1200;
    --dark-accent-90: #0e0f12f2;
    --meta-b-fcolor: #ffffff;
    --nav-bg: #131518;
    --nav-bg-from: #131518;
    --nav-bg-to: #131518;
    --bcrumb-color: #ffffff;
    --excerpt-color: #dddddd;
    --heading-color: var(--body-fcolor);
    --nav-color: #ffffff;
    --subnav-color: #ffffff;
    --nav-color-10: #ffffff15;
    --subnav-color-10: #ffffff15;
    --body-fcolor: #ffffff;
    --h1-fcolor: #ffffff;
    --h2-fcolor: #ffffff;
    --h3-fcolor: #ffffff;
    --h4-fcolor: #ffffff;
    --h5-fcolor: #ffffff;
    --h6-fcolor: #ffffff;
    --headline-fcolor: #ffffff;
    --input-fcolor: #ffffff;
    --meta-fcolor: #bbbbbb;
    --tagline-fcolor: #ffffff;
    --flex-gray-15: #88888840;
    --flex-gray-7: #88888818;
    --solid-light: #333333;
    --heading-sub-color: var(--g-color);
    --solid-white: #191c20;
    --twitter-color: #ffffff;
    --medium-color: #ffffff
}

[data-theme=dark] {
    --subnav-bg: #191c20;
    --subnav-bg-from: #191c20;
    --subnav-bg-to: #191c20
}

body .light-scheme [data-mode=default],
body:not([data-theme=dark]) [data-mode=dark],
body[data-theme=dark] [data-mode=default] {
    display: none !important
}

body .light-scheme [data-mode=dark] {
    display: inherit !important
}

.light-scheme input::placeholder,
[data-theme=dark] input::placeholder {
    color: var(--absolute-light)
}

a,
body,
caption,
cite,
code,
div,
dl,
dt,
em,
fieldset,
form,
h1,
h2,
h3,
h4,
html,
iframe,
ins,
label,
legend,
li,
object,
p,
s,
span,
strong,
table,
tbody,
tr,
tt,
ul,
var {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0
}

html {
    font-size: var(--body-fsize);
    overflow-x: hidden;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%
}

body {
    font-family: var(--body-family);
    font-size: var(--body-fsize);
    font-weight: var(--body-fweight);
    font-style: var(--body-fstyle);
    line-height: max(var(--body-fheight, 1.7), 1.4);
    display: block;
    margin: 0;
    letter-spacing: var(--body-fspace);
    text-transform: var(--body-transform);
    color: var(--body-fcolor);
    background-color: var(--solid-white)
}

body.rtl {
    direction: rtl
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

:after,
:before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.site-outer {
    position: relative;
    display: block;
    /* overflow: clip; */
    max-width: 100%;
    background-color: var(--solid-white)
}

.top-spacing .site-outer {
    -webkit-transition: margin .5s var(--timing);
    transition: margin .5s var(--timing)
}

.site-wrap {
    position: relative;
    display: block;
    margin: 0;
    padding: 0
}

.site-wrap {
    z-index: 1;
    min-height: 45vh
}

button,
input {
    overflow: visible
}

canvas,
progress,
video {
    display: inline-block
}

progress {
    vertical-align: baseline
}

[type=checkbox],
[type=radio],
legend {
    box-sizing: border-box;
    padding: 0
}

[type=checkbox] {
    cursor: pointer
}

.rbct ins {
    text-decoration: none;
    background-color: var(--flex-gray-15)
}

figure {
    display: block;
    margin: 0
}

cite,
em {
    font-weight: 400;
    font-style: italic
}

dt {
    font-weight: 700
}

code,
var {
    font-family: monospace, monospace;
    font-size: .9em
}

a {
    text-decoration: none;
    color: inherit;
    background-color: transparent
}

strong {
    font-weight: 700
}

code {
    font-family: Courier, monospace;
    padding: 5px;
    background-color: var(--flex-gray-7)
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
    -webkit-object-fit: cover;
    object-fit: cover
}

.collapse-activated,
svg:not(:root) {
    overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
    text-transform: none;
    color: inherit;
    outline: 0 !important
}

select {
    line-height: 24px;
    position: relative;
    max-width: 100%;
    padding: 10px 20px;
    border-color: var(--flex-gray-15);
    border-radius: var(--round-5);
    outline: 0 !important;
    background: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iZ3JheSIgZD0iTTEyIDE1YTEgMSAwIDAgMS0uNzA3LS4yOTNsLTMtM2ExIDEgMCAwIDEgMS40MTQtMS40MTRMMTIgMTIuNTg2bDIuMjkzLTIuMjkzYTEgMSAwIDAgMSAxLjQxNCAxLjQxNGwtMyAzQTEgMSAwIDAgMSAxMiAxNXoiLz48L3N2Zz4=) right 5px top 50% no-repeat;
    background-size: 22px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none
}

select option {
    background-color: var(--solid-white)
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: ButtonText dotted 1px
}

fieldset {
    padding: 0
}

legend {
    font-weight: 700;
    display: table;
    max-width: 100%;
    white-space: normal;
    color: inherit
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

summary {
    display: list-item
}

[hidden],
template {
    display: none
}

.hidden {
    display: none
}

input:disabled,
select:disabled,
textarea:disabled {
    opacity: .5;
    background-color: #ffcb3330
}

h1 {
    font-family: var(--h1-family);
    font-size: var(--h1-fsize);
    font-weight: var(--h1-fweight);
    font-style: var(--h1-fstyle);
    line-height: var(--h1-fheight, 1.2);
    letter-spacing: var(--h1-fspace);
    text-transform: var(--h1-transform)
}

h1 {
    color: var(--h1-fcolor)
}

h2 {
    font-family: var(--h2-family);
    font-size: var(--h2-fsize);
    font-weight: var(--h2-fweight);
    font-style: var(--h2-fstyle);
    line-height: var(--h2-fheight, 1.25);
    letter-spacing: var(--h2-fspace);
    text-transform: var(--h2-transform)
}

h2 {
    color: var(--h2-fcolor)
}

.h3,
h3 {
    font-family: var(--h3-family);
    font-size: var(--h3-fsize);
    font-weight: var(--h3-fweight);
    font-style: var(--h3-fstyle);
    line-height: var(--h3-fheight, 1.3);
    letter-spacing: var(--h3-fspace);
    text-transform: var(--h3-transform)
}

h3 {
    color: var(--h3-fcolor)
}

.h4,
h4 {
    font-family: var(--h4-family);
    font-size: var(--h4-fsize);
    font-weight: var(--h4-fweight);
    font-style: var(--h4-fstyle);
    line-height: var(--h4-fheight, 1.45);
    letter-spacing: var(--h4-fspace);
    text-transform: var(--h4-transform)
}

h4 {
    color: var(--h4-fcolor)
}

.h5 {
    font-family: var(--h5-family);
    font-size: var(--h5-fsize);
    font-weight: var(--h5-fweight);
    font-style: var(--h5-fstyle);
    line-height: var(--h5-fheight, 1.5);
    letter-spacing: var(--h5-fspace);
    text-transform: var(--h5-transform)
}

.h6 {
    font-family: var(--h6-family);
    font-size: var(--h6-fsize);
    font-weight: var(--h6-fweight);
    font-style: var(--h6-fstyle);
    line-height: var(--h6-fheight, 1.5);
    letter-spacing: var(--h6-fspace);
    text-transform: var(--h6-transform)
}

h1.entry-title {
    font-size: var(--title-size, var(--h1-fsize))
}

h2.entry-title {
    font-size: var(--title-size, var(--h2-fsize))
}

h3.entry-title {
    font-size: var(--title-size, var(--h3-fsize))
}

h4.entry-title {
    font-size: var(--title-size, var(--h4-fsize))
}

.p-categories {
    font-family: var(--cat-family);
    font-size: var(--cat-fsize);
    font-weight: var(--cat-fweight);
    font-style: var(--cat-fstyle);
    letter-spacing: var(--cat-fspace);
    /*text-transform: var(--cat-transform)*/
}

.is-meta,
.meta-text,
.user-login-form label {
    font-family: var(--meta-family);
    font-size: var(--meta-fsize);
    font-weight: var(--meta-fweight);
    font-style: var(--meta-fstyle);
    letter-spacing: var(--meta-fspace);
    text-transform: var(--meta-transform)
}

.tipsy-inner {
    font-family: var(--meta-family);
    font-weight: var(--meta-fweight);
    font-style: var(--meta-fstyle);
    letter-spacing: var(--meta-fspace);
    text-transform: var(--meta-transform);
    color: var(--meta-fcolor)
}

figcaption {
    font-family: var(--meta-family);
    font-size: var(--meta-fsize);
    font-weight: var(--meta-fweight);
    font-style: var(--meta-fstyle);
    letter-spacing: var(--meta-fspace);
    text-transform: var(--meta-transform)
}

.is-meta {
    color: var(--meta-fcolor)
}

.meta-author {
    font-family: var(--meta-b-family);
    font-size: inherit;
    font-weight: var(--meta-b-fweight);
    font-style: var(--meta-b-fstyle);
    letter-spacing: var(--meta-b-fspace);
    text-transform: var(--meta-b-transform)
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=text],
input[type=url],
textarea {
    font-family: var(--input-family);
    font-size: var(--input-fsize);
    font-weight: var(--input-fweight);
    font-style: var(--input-fstyle);
    line-height: 1.5;
    letter-spacing: var(--input-fspace);
    text-transform: var(--input-transform);
    color: var(--input-fcolor)
}

.button,
.is-btn,
.pagination-wrap,
a.comment-reply-link,
a.show-post-comment,
button,
input[type=submit] {
    font-family: var(--btn-family);
    font-size: var(--btn-fsize);
    font-weight: var(--btn-fweight);
    font-style: var(--btn-fstyle);
    letter-spacing: var(--btn-fspace);
    text-transform: var(--btn-transform)
}

.main-menu>li>a {
    font-family: var(--menu-family);
    font-size: var(--menu-fsize);
    font-weight: var(--menu-fweight);
    font-style: var(--menu-fstyle);
    line-height: 1.5;
    letter-spacing: var(--menu-fspace);
    text-transform: var(--menu-transform)
}

.collapse-footer-menu a {
    font-family: var(--submenu-family);
    font-size: var(--submenu-fsize);
    font-weight: var(--submenu-fweight);
    font-style: var(--submenu-fstyle);
    line-height: 1.25;
    letter-spacing: var(--submenu-fspace);
    text-transform: var(--submenu-transform);
    color: var(--subnav-color)
}

.heading-title>* {
    font-family: var(--heading-family, inherit);
    font-size: var(--heading-fsize, inherit);
    font-weight: var(--heading-fweight, inherit);
    font-style: var(--heading-fstyle, inherit);
    letter-spacing: var(--heading-fspace, inherit);
    text-transform: var(--heading-transform, inherit)
}

.heading-tagline>* {
    font-family: var(--subheading-family, inherit);
    font-size: var(--subheading-fsize, inherit);
    font-weight: var(--subheading-fweight, inherit);
    font-style: var(--subhading-fstyle, inherit);
    letter-spacing: var(--subheading-fspace, inherit);
    text-transform: var(--subheading-transform, inherit)
}

.p-wrap .entry-summary {
    font-family: var(--excerpt-family, inherit);
    font-size: var(--excerpt-fsize);
    font-weight: var(--excerpt-fweight, inherit);
    font-style: var(--excerpt-fstyle, inherit);
    letter-spacing: var(--excerpt-fspace, inherit);
    text-transform: var(--excerpt-transform, inherit);
    color: var(--excerpt-color);
    column-count: var(--excerpt-columns, 1);
    column-gap: var(--excerpt-gap, 1.5em)
}

.description-text {
    font-size: var(--excerpt-fsize)
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=text],
input[type=url] {
    line-height: 24px;
    max-width: 100%;
    padding: 12px 20px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    white-space: nowrap;
    border: var(--input-border, none);
    border-radius: var(--round-3);
    outline: 0 !important;
    background-color: var(--input-bg, var(--flex-gray-7));
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

input::file-selector-button {
    font-size: var(--em-small);
    padding: 8px 20px;
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--awhite);
    border: none;
    border-radius: var(--round-7);
    background-color: var(--g-color)
}

input:hover::file-selector-button {
    opacity: .7
}

button {
    cursor: pointer;
    border: none;
    border-radius: var(--round-3);
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.button,
.is-btn,
div:where(.entry-content) button,
input[type=submit] {
    line-height: var(--height-40);
    padding: var(--padding-40);
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    white-space: nowrap;
    color: var(--awhite);
    border: none;
    border-radius: var(--round-3);
    outline: 0 !important;
    background: var(--g-color);
    -webkit-box-shadow: none;
    box-shadow: none
}

textarea {
    font-size: var(--input-fsize) !important;
    display: block;
    overflow: auto;
    width: 100%;
    max-width: 100%;
    padding: 15px 20px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    border: var(--input-border, none);
    border-radius: var(--round-7);
    outline: 0;
    background-color: var(--input-bg, var(--flex-gray-7));
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.rb-container {
    position: static;
    display: block;
    width: 100%;
    max-width: var(--rb-width, 1280px);
    margin-right: auto;
    margin-left: auto
}

.edge-padding {
    padding-right: 20px;
    padding-left: 20px
}

.menu-item,
.rb-section ul {
    list-style: none
}

.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
p {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}

.icon-holder {
    display: flex;
    align-items: center;
    cursor : pointer;
}

.svg-icon {
    width: 1.5em;
    height: auto
}

.light-scheme {
    color: var(--body-fcolor);
    --pagi-bg: var(--solid-light)
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    transition: background-color 999999s ease-in-out 0s
}

.overlay-wrap {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    flex-flow: row nowrap;
    pointer-events: none
}

.overlay-inner {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px;
    cursor: auto;
    pointer-events: auto
}

.p-gradient .overlay-inner {
    background: -webkit-gradient(linear, left top, left bottom, from(var(--dark-accent-0)), to(var(--dark-accent)));
    background: linear-gradient(to top, var(--dark-accent) 0, var(--dark-accent-90) 50%, var(--dark-accent-0) 100%)
}

.block-inner {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    row-gap: var(--bottom-spacing)
}

.is-gap-10 .block-inner {
    margin-right: -10px;
    margin-left: -10px;
    --column-gap: 10px
}

.is-gap-20 .block-inner {
    margin-right: -20px;
    margin-left: -20px;
    --column-gap: 20px
}

.block-inner>* {
    flex-basis: 100%;
    width: 100%;
    padding-right: var(--column-gap, 0);
    padding-left: var(--column-gap, 0)
}

[class*=is-gap-]>.block-inner>* {
    padding-right: var(--column-gap, 0);
    padding-left: var(--column-gap, 0)
}

.effect-fadeout.activated a {
    opacity: .25
}

.effect-fadeout.activated a:focus,
.effect-fadeout.activated a:hover {
    opacity: 1
}

.header-social-list a:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2)
}

.rb-loader {
    font-size: 10px;
    position: relative;
    display: none;
    overflow: hidden;
    width: 3em;
    height: 3em;
    transform: translateZ(0);
    animation: .8s linear infinite spin-loader;
    text-indent: -9999em;
    color: var(--g-color);
    border-radius: 50%;
    background: currentColor;
    background: linear-gradient(to right, currentColor 10%, rgba(255, 255, 255, 0) 50%)
}

.rb-loader:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    content: '';
    color: inherit;
    border-radius: 100% 0 0 0;
    background: currentColor
}

.rb-loader:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 75%;
    margin: auto;
    content: '';
    opacity: .5;
    border-radius: 50%;
    background: var(--solid-white)
}

@-webkit-keyframes spin-loader {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes spin-loader {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.rb-loader.loader-absolute {
    position: absolute;
    z-index: 10;
    bottom: 50%;
    left: 50%;
    display: block;
    margin-top: -1.5em;
    margin-left: -1.5em
}

.opacity-animate {
    -webkit-transition: opacity .25s var(--timing);
    transition: opacity .25s var(--timing)
}

.is-invisible {
    opacity: 0
}

.pagination-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: max(20px, var(--bottom-spacing));
    padding-right: var(--column-gap, 0);
    padding-left: var(--column-gap, 0)
}

.pagination-trigger.is-disable {
    pointer-events: none;
    opacity: .5
}

.loadmore-trigger span,
.pagination-trigger {
    font-size: var(--pagi-size, inherit);
    line-height: calc(var(--pagi-size, inherit) * 1.6);
    position: relative;
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;
    min-height: max(var(--height-34), 2.6em);
    padding: var(--padding-40);
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--pagi-color, currentColor);
    border-color: var(--pagi-accent-color, var(--flex-gray-7));
    border-radius: var(--round-7);
    background-color: var(--pagi-accent-color, var(--flex-gray-7))
}

.loadmore-trigger.loading span {
    opacity: 0
}

.pagination-loadmore .rb-loader {
    font-size: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.5em;
    margin-left: -1.5em
}

.mfp-bg {
    position: fixed;
    z-index: 9042;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: var(--dark-accent)
}

.mfp-wrap {
    position: fixed;
    z-index: 9043;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0 !important;
    -webkit-backface-visibility: hidden
}

.mfp-wrap button>* {
    pointer-events: none
}

.mfp-container {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    text-align: center
}

.mfp-container:before {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle
}

.mfp-ajax-cur {
    cursor: progress
}

.mfp-zoom-out-cur {
    cursor: zoom-out
}

.mfp-arrow,
.mfp-close,
.mfp-counter {
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none
}

.is-hidden,
.mfp-hide {
    display: none !important
}

.logo-wrap img.logo-transparent,
.mfp-align-top .mfp-container:before,
.mfp-loading.mfp-figure {
    display: none
}

.is-invisible {
    visibility: hidden;
    opacity: 0
}

button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.mfp-arrow {
    position: absolute;
    top: 50%;
    width: 130px;
    height: 110px;
    margin: 0;
    margin-top: -55px;
    padding: 0;
    opacity: .65;
    -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
    margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
    opacity: 1
}

.mfp-arrow:after,
.mfp-arrow:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    margin-top: 35px;
    margin-left: 35px;
    content: '';
    border: inset transparent
}

.mfp-arrow:after {
    top: 8px;
    border-top-width: 13px;
    border-bottom-width: 13px
}

.mfp-arrow:before {
    opacity: .7;
    border-top-width: 21px;
    border-bottom-width: 21px
}

.mfp-iframe-scaler {
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: #000
}

img.mfp-img {
    line-height: 0;
    display: block;
    box-sizing: border-box;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 40px 0
}

.mfp-figure {
    line-height: 0
}

.mfp-figure:after {
    position: absolute;
    z-index: -1;
    top: 40px;
    right: 0;
    bottom: 40px;
    left: 0;
    display: block;
    width: auto;
    height: auto;
    content: '';
    background: var(--dark-accent)
}

.mfp-figure figure {
    margin: 0
}

.mfp-bottom-bar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: -36px;
    cursor: auto
}

.mfp-bg.popup-animation {
    -webkit-transition: all .5s var(--timing);
    transition: all .5s var(--timing);
    opacity: 0
}

.mfp-bg.mfp-ready.popup-animation {
    opacity: .92
}

.mfp-bg.mfp-ready.popup-animation.site-access-popup {
    opacity: .99
}

.mfp-removing.mfp-bg.mfp-ready.popup-animation {
    opacity: 0 !important
}

.tipsy {
    position: absolute;
    z-index: 100000;
    padding: 5px;
    pointer-events: none
}

.tipsy-inner {
    font-size: var(--meta-fsize);
    line-height: 1;
    max-width: 200px;
    padding: 7px 10px;
    text-align: center;
    color: var(--awhite);
    border-radius: var(--round-3);
    background-color: var(--dark-accent)
}

.tipsy-arrow {
    line-height: 0;
    position: absolute;
    width: 0;
    height: 0;
    border: 5px dashed var(--dark-accent)
}

.rtl .rbi.rbi-facebook:before,
.rtl .rbi.rbi-reddit:before,
.rtl .rbi.rbi-twitter:before,
.rtl .rbi.rbi-youtube:before {
    -webkit-transform: none;
    transform: none
}

.rbi,
[class^=rbi-] {
    font-family: ruby-icon !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none
}

body.rtl .rbi:before {
    display: inline-block;
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.rbi-notification:before {
    content: '\e95a'
}

.rbi-darrow-top:before {
    content: '\e94a'
}

.rbi-search:before {
    content: '\e946'
}

.rbi-sign-out:before {
    content: "\e969";
}

.rbi-create:before {
    content: "\e909";
}

.rbi-bio:before {
    content: "\e901";
}

.rbi-facebook:before {
    content: '\e915'
}

.rbi-close:before {
    content: '\e949'
}

.rbi-reddit:before {
    content: '\e928'
}

.rbi-twitter:before {
    content: '\e988'
}

.rbi-youtube:before {
    content: '\e93e'
}

.rbi-bookmark-fill:before {
    content: '\e906'
}

.rbi-bookmark:before {
    content: '\e907'
}

.rbi-plus:before {
    content: '\e900'
}

.rbi-cright:before {
    content: '\e902'
}

.rbi-angle-up:before {
    content: '\e927'
}

.rbi-star-o:before {
    content: '\e94f'
}

.rbi-like:before {
    content: '\e97c'
}

.rbi-dislike:before {
    content: '\e97d'
}

.swiper-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    list-style: none
}

.pre-load:not(.swiper-container-initialized) {
    max-height: 0
}

.swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    transition-property: transform
}

.swiper-wrapper {
    transform: translate3d(0, 0, 0)
}

.swiper-slide {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    transition-property: transform
}

.swiper-slide-invisible-blank {
    visibility: hidden
}

.swiper-button-lock,
.swiper-pagination-lock,
.swiper-scrollbar-lock {
    display: none
}

.swiper-pagination {
    position: absolute;
    z-index: 10;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    text-align: center
}

.swiper-pagination.swiper-pagination-hidden {
    opacity: 0
}

.swiper-pagination-bullet,
body .swiper-pagination-bullet {
    position: relative;
    display: inline-block;
    width: calc(var(--nav-size, 32px)/2);
    height: calc(var(--nav-size, 32px)/2);
    margin-right: 0;
    margin-left: 0;
    -webkit-transition: -webkit-transform .25s var(--timing);
    transition: transform .25s var(--timing);
    opacity: .4;
    background: 0 0
}

.swiper-pagination-bullet:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    -webkit-transform: scale(.5);
    transform: scale(.5);
    pointer-events: none;
    color: inherit;
    border-radius: 50%;
    background: currentColor
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    opacity: 1
}

.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    -webkit-transform: scale(.75);
    transform: scale(.75)
}

button.swiper-pagination-bullet {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none
}

.swiper-pagination-bullet-active {
    opacity: 1
}

.swiper-scrollbar-drag {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--round-7);
    background: var(--g-color)
}

.swiper-zoom-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.swiper-slide-zoomed {
    cursor: move
}

.swiper-lazy-preloader {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 42px;
    height: 42px;
    margin-top: -21px;
    margin-left: -21px;
    transform-origin: 50%;
    animation: 1s linear infinite swiper-preloader-spin;
    border: 4px solid var(--swiper-preloader-color, var(--g-color));
    border-top-color: transparent;
    border-radius: 50%
}

@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg)
    }
}

.swiper-container .swiper-notification {
    position: absolute;
    z-index: -1000;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0
}

.header-wrap {
    position: relative;
    display: block
}

.navbar-wrap {
    position: relative;
    z-index: 999;
    display: block
}

.header-mobile {
    display: none
}

.navbar-wrap:not(.navbar-transparent),
.sticky-on .navbar-wrap {
    /*background: var(--nav-bg);
    background: -webkit-linear-gradient(left, var(--nav-bg-from) 0%, var(--nav-bg-to) 100%);
    background: linear-gradient(to right, var(--nav-bg-from) 0%, var(--nav-bg-to) 100%);*/
    /*background-color: var(--wp--preset--color--black);*/
        background: linear-gradient(83deg, #1b1b1b 25.29%, rgba(27, 27, 27, .69) 49.52%, rgba(27, 27, 27, .39) 66.36%, rgba(27, 27, 27, 0) 94.89%);
}

.sticky-on .navbar-wrap,
.style-shadow .navbar-wrap:not(.navbar-transparent) {
    box-shadow: 0 4px 30px var(--shadow-7);
}

.navbar-inner {
    position: relative;
    display: flex;
    align-items: stretch;
    flex-flow: row nowrap;
    justify-content: space-between;
    max-width: 100%;
    min-height: var(--nav-height)
}

.navbar-center,
.navbar-left,
.navbar-right {
    display: flex;
    align-items: stretch;
    flex-flow: row nowrap
}

.navbar-left {
    flex-grow: 1
}

.logo-wrap {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    max-height: 100%;
    margin-right: 20px
}

.the-logo a {
    -webkit-transition: opacity .25s var(--timing);
    transition: opacity .25s var(--timing)
}

.the-logo img {
    width: 100%;
    max-width: 100%
}

.logo-wrap a {
    max-height: 100%
}

.is-image-logo.logo-wrap a {
    display: block
}

.logo-wrap img {
    display: block;
    width: auto;
    max-height: var(--nav-height);
    -webkit-transition: opacity .25s var(--timing);
    transition: opacity .25s var(--timing);
    object-fit: cover
}

.more-section-outer .more-trigger {
    padding-top: 15px;
    padding-bottom: 15px
}

.header-social-list>a {
    display: inline-flex;
    align-items: center;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.logo-wrap a:hover img,
.the-logo a:hover {
    opacity: .7
}

.navbar-right>* {
    position: static;
    display: flex;
    align-items: center;
    height: 100%
}

.navbar-outer .navbar-right>* {
    color: var(--nav-color)
}

.large-menu>li {
    position: relative;
    display: block;
    float: left
}

.header-dropdown {
    position: absolute;
    z-index: 9999;
    top: -9999px;
    right: auto;
    left: 0;
    display: block;
    visibility: hidden;
    min-width: 210px;
    margin-top: 20px;
    list-style: none;
    -webkit-transition: top 0s, opacity .25s var(--timing), margin .25s var(--timing);
    transition: top 0s, opacity .25s var(--timing), margin .25s var(--timing);
    pointer-events: none;
    opacity: 0;
    border-radius: var(--round-7)
}

.flex-dropdown,
.mega-dropdown {
    position: absolute;
    z-index: 888;
    top: -9999px;
    right: auto;
    left: -10%;
    display: block;
    min-width: 180px;
    /* margin-top: 20px; */
    cursor: auto;
    -webkit-transition: top 0s ease .25s, opacity .25s var(--timing), margin .25s var(--timing) .25s;
    transition: top 0s ease .25s, opacity .25s var(--timing), margin .25s var(--timing) .25s;
    pointer-events: none;
    opacity: 0;
    background: 0 0;
}

.mega-dropdown-inner {
    position: relative;
    width: 100%;
    padding: 20px 20px 30px;
    pointer-events: auto;
    border-radius: var(--round-7);
    background: var(--subnav-bg);
    background: -webkit-linear-gradient(left, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    background: linear-gradient(to right, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    box-shadow: 0 4px 30px var(--shadow-7)
}

body:not([data-theme=dark]) .is-mega-category .mega-dropdown-inner {
    background: var(--mega-bg, var(--subnav-bg));
    background: -webkit-linear-gradient(left, var(--mega-bg, var(--subnav-bg-from)) 0%, var(--mega-bg, var(--subnav-bg-to)) 100%);
    background: linear-gradient(to right, var(--mega-bg, var(--subnav-bg-from)) 0%, var(--mega-bg, var(--subnav-bg-to)) 100%);
    background: linear-gradient(267deg, #eea900 -2.97%, #0e448a 96.94%);
    box-shadow: inset 0 4px 184px 93px #fff3e1;
}

.header-dropdown-outer.dropdown-activated .header-dropdown,
.large-menu>li:hover>.header-dropdown {
    top: calc(100% - 1px);
    bottom: auto;
    visibility: visible;
    max-width: 1240px;
    margin-top: 0;
    pointer-events: auto;
    opacity: 1
}

li.menu-item.mega-menu-loaded:hover>.flex-dropdown,
li.menu-item.mega-menu-loaded:hover>.mega-dropdown {
    top: calc(100% - 1px);
    bottom: auto;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: top 0s ease 0s, opacity .25s var(--timing), margin .25s var(--timing) 0s;
    transition: top 0s ease 0s, opacity .25s var(--timing), margin .25s var(--timing) 0s;
    opacity: 1
}

.mega-header {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%;
    margin-bottom: 20px
}

.mega-header:not(.mega-header-fw) {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--subnav-color-10)
}

.mega-link {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.mega-link i {
    margin-left: 3px;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.mega-link:hover {
    color: var(--g-color)
}

.mega-link:hover i {
    -webkit-transform: translateX(3px);
    transform: translateX(3px)
}

.mega-header:not(.mega-header-fw) i {
    width: 1em;
    margin-right: 5px;
    color: var(--g-color)
}

.main-menu-wrap {
    display: flex;
    align-items: center;
    height: 100%
}

.menu-item a>span {
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    position: relative;
    display: inline-flex;
    align-items: center;
    letter-spacing: inherit;
    column-gap: .3em
}

.main-menu>li>a {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: var(--nav-height);
    padding-right: var(--menu-item-spacing, 12px);
    padding-left: var(--menu-item-spacing, 12px);
    white-space: nowrap;
    color: var(--nav-color)
}

.large-menu>.menu-item.menu-item-has-children>a>span {
    padding-right: 2px
}

.large-menu>.menu-item.menu-item-has-children>a:after {
    font-family: ruby-icon;
    font-size: var(--rem-mini);
    content: '\e924';
    -webkit-transition: -webkit-transform .25s var(--timing), color .25s var(--timing);
    transition: transform .25s var(--timing), color .25s var(--timing);
    will-change: transform
}

.large-menu>.menu-item.menu-item-has-children:hover>a:after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.sidebar-menu {
    justify-content: var(--menu-align, initial);
    text-align: var(--menu-align, initial)
}

.wnav-holder {
    padding-right: 5px;
    padding-left: 5px
}

.wnav-holder svg {
    line-height: 0;
    width: 20px;
    height: 20px
}

.wnav-icon {
    font-size: 20px
}

.w-header-search .header-search-form {
    width: 100px;
    max-width: 100%;
    margin-bottom: 0;
    padding: 5px
}

.w-header-search .search-form-inner {
    border: none
}

.rb-search-form {
    position: relative;
    width: 100%
}

.rb-search-submit {
    width: 140px;
    position: relative;
    display: flex
}

.rb-search-submit input[type=submit] {
    padding: 0;
    opacity: 0
}

.rb-search-submit i {
    font-size: var(--icon-size, inherit);
    line-height: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: max(.4em, 10px);
    -webkit-transition: var(--effect);
    transition: var(--effect);
    pointer-events: none;
    color: var(--input-fcolor, currentColor)
}

.rb-search-form .search-icon {
    display: flex;
    flex-shrink: 0
}

.search-icon>* {
    font-size: var(--icon-size, inherit);
    margin-right: max(.4em, 10px);
    margin-left: max(.4em, 10px);
    color: var(--input-fcolor, currentColor);
    fill: var(--input-fcolor, currentColor)
}

.rb-search-submit:hover i {
    color: var(--g-color)
}

.search-form-inner {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid var(--flex-gray-15);
    border-radius: var(--round-5)
}

.header-wrap .search-form-inner {
    border-color: var(--subnav-color-10)
}

.mobile-collapse .search-form-inner {
    border-color: var(--submbnav-color-10, var(--flex-gray-15))
}

.rb-search-form .search-text {
    flex-grow: 1
}

.rb-search-form input {
    width: 100%;
    padding: .6em 0;
    border: none;
    background: 0 0
}

.rb-search-form input::placeholder {
    opacity: .8;
    color: inherit
}

img.svg {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.navbar-right {
    position: relative;
    flex-shrink: 0
}

.header-dropdown-outer {
    position: static
}

.dropdown-trigger {
    cursor: pointer
}

.header-dropdown {
    right: 0;
    left: auto;
    overflow: hidden;
    min-width: 140px;
    color: var(--subnav-color);
    background: var(--subnav-bg);
    background: -webkit-linear-gradient(left, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    background: linear-gradient(to right, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    box-shadow: 0 4px 30px var(--shadow-12)
}

.rb-menu>.menu-item>a:hover>span {
    color: var(--nav-color-h, inherit)
}

.menu-ani-4 .main-menu>.menu-item>a span {
    -webkit-transition: var(--effect);
    transition: var(--effect);
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
}

.menu-ani-4 .main-menu>.menu-item>a:hover>span {
    /*border-bottom-color: var(--nav-color-h-accent, var(--g-color));*/
    border-bottom-color: var(--nav-color-h-accent, var(--g-color))
}

.burger-icon {
    position: relative;
    display: block;
    width: 24px;
    height: 16px;
    color: var(--nav-color, inherit)
}

.burger-icon>span {
    position: absolute;
    left: 0;
    display: block;
    height: 2px;
    -webkit-transition: all .5s var(--timing);
    transition: all .5s var(--timing);
    border-radius: 2px;
    background-color: currentColor
}

.collapse-activated .burger-icon>span {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.burger-icon>span:first-child {
    top: 0;
    width: 100%
}

.burger-icon>span:nth-child(2) {
    top: 50%;
    width: 65%;
    margin-top: -1px
}

.burger-icon>span:nth-child(3) {
    top: auto;
    bottom: 0;
    width: 40%
}

.collapse-activated .burger-icon>span:nth-child(2) {
    background-color: transparent
}

.collapse-activated .burger-icon>span:first-child {
    top: 50%;
    width: 100%;
    margin-top: -1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center center
}

.collapse-activated .burger-icon>span:nth-child(3) {
    top: 50%;
    width: 100%;
    margin-top: -1px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-origin: center center
}

.login-toggle {
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-shrink: 0;
    vertical-align: middle;
    text-decoration: none !important
}

.login-toggle.is-btn {
    line-height: 30px;
    margin-right: 10px;
    padding-right: var(--login-btn-padding, 20px);
    padding-left: var(--login-btn-padding, 20px)
}

.login-toggle span:not(.login-icon-svg) {
    font-size: var(--rem-mini)
}

.notification-icon {
    display: flex
}

.notification-icon-inner {
    display: flex;
    align-items: center
}

.notification-icon i,
.notification-icon-holder {
    line-height: 1;
    position: relative
}

.notification-info {
    font-size: 11px;
    line-height: 18px;
    position: absolute;
    right: -9px;
    bottom: calc(100% - 9px);
    width: 18px;
    height: 18px;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    color: var(--awhite);
    border-radius: 50%;
    background-color: var(--g-color)
}

@-webkit-keyframes heartbeat {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }
}

.notification-trigger:hover .notification-info {
    -webkit-animation: 1s infinite heartbeat;
    animation: 1s infinite heartbeat
}

.notification-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid var(--flex-gray-15)
}

.notification-url {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-left: auto;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    opacity: .7;
    cursor: pointer;
}

.notification-url:hover {
    opacity: 1
}

.notification-url i {
    margin-left: 4px
}

.notification-content .scroll-holder {
    overflow-y: scroll;
    max-height: 50vh
}

.notification-popup {
    display: block;
    width: 140px;
    max-width: 100%;
    --bottom-spacing: 0
}

.notification-content .p-wrap {
    padding: 20px;
    border-bottom: 1px solid var(--flex-gray-15);
    --el-spacing: 10px
}

.more-trigger {
    line-height: 0;
    height: 100%;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--nav-color)
}

.more-section-outer .svg-icon {
    width: 1em
}

.more-section-outer .more-trigger {
    padding-right: var(--menu-item-spacing, 12px);
    padding-left: var(--menu-item-spacing, 12px)
}

.more-section-outer .more-trigger:hover,
.more-section-outer.dropdown-activated .more-trigger {
    color: var(--g-color)
}

.collapse-footer {
    padding: 15px;
    background-color: var(--subnav-color-10)
}

.header-search-form .h5 {
    display: block;
    margin-bottom: 10px
}

.live-search-response {
    position: relative;
    overflow: hidden
}

.is-form-layout .live-search-response {
    position: absolute;
    z-index: 1000;
    top: calc(100% + 2px);
    right: 0;
    left: 0;
    display: none;
    color: var(--body-color);
    border-top: none;
    border-radius: var(--round-5);
    background: var(--subnav-bg);
    background: -webkit-linear-gradient(left, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    background: linear-gradient(to right, var(--subnav-bg-from) 0%, var(--subnav-bg-to) 100%);
    box-shadow: 0 4px 30px var(--shadow-12)
}

.live-search-animation.rb-loader {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    opacity: 0;
    color: var(--input-fcolor, currentColor)
}

.search-loading .live-search-animation.rb-loader {
    display: block;
    opacity: 1
}

.search-loading .rb-search-submit i {
    display: none
}

.is-form-layout .search-form-inner {
    width: 100%;
    border-color: var(--search-form-color, var(--flex-gray-15))
}

.is-form-layout .search-icon {
    opacity: .5
}

.collapse-footer>* {
    margin-bottom: 12px
}

.collapse-footer>:last-child {
    margin-bottom: 0
}

.collapse-footer-menu a {
    color: var(--submbnav-color, var(--subnav-color))
}

.collapse-footer-menu-inner {
    display: flex;
    flex-flow: row wrap;
    gap: 10px
}

.collapse-footer-menu-inner li {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row nowrap
}

.collapse-footer-menu-inner li:not(:last-child):after {
    display: flex;
    width: 3px;
    height: 3px;
    margin-right: 10px;
    margin-left: 10px;
    content: '';
    opacity: .25;
    border-radius: 50%;
    background-color: currentColor
}

.collapse-copyright {
    font-size: var(--rem-mini);
    opacity: .7
}

.header-dropdown.left-direction {
    right: -10%;
    left: auto
}

.dark-mode-toggle-wrap {
    height: auto
}

.dark-mode-toggle {
    display: flex;
    align-items: center;
    cursor: pointer
}

.dark-mode-toggle-wrap:not(:first-child) .dark-mode-toggle {
    position: relative;
    margin-left: 5px;
    padding-left: 10px;
    border-left: 1px solid var(--flex-gray-40)
}

.navbar-outer .dark-mode-toggle-wrap:not(:first-child) .dark-mode-toggle {
    border-color: var(--nav-color-10)
}

.dark-mode-slide {
    position: relative;
    width: 40px;
    height: 14px;
    border-radius: 20px;
    background-color: var(--flex-gray-15)
}

.dark-mode-slide-btn {
    position: absolute;
    top: -5px;
    left: 0;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    -webkit-transition: opacity .1s;
    transition: opacity .1s;
    opacity: 0;
    border-radius: 50%;
    background-color: var(--solid-white);
    box-shadow: 0 1px 4px var(--shadow-12) !important
}

.triggered .dark-mode-slide-btn {
    -webkit-transition: opacity .25s, transform .5s;
    transition: opacity .25s, transform .5s;
    will-change: transform
}

.svg-mode-light {
    color: var(--body-fcolor)
}

.mode-icon-dark {
    color: #fff;
    background-color: var(--g-color)
}

.dark-mode-slide-btn svg {
    width: 14px;
    height: 14px
}

.dark-mode-slide-btn.activated {
    visibility: visible;
    opacity: 1
}

.mode-icon-dark:not(.activated),
.mode-icon-default.activated {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mode-icon-dark.activated,
.mode-icon-default:not(.activated) {
    -webkit-transform: translateX(16px);
    transform: translateX(16px)
}

body.switch-smooth {
    -webkit-transition: background-color .25s var(--timing);
    transition: background-color .25s var(--timing)
}

.navbar-outer {
    /* position: relative; */
    position: fixed;
    z-index: 110;
    display: block;
    width: 100%
}

.elementor-editor-active .navbar-outer {
    z-index: 0
}

.is-mstick .navbar-outer:not(.navbar-outer-template) {
    min-height: var(--nav-height)
}

.is-mstick .sticky-holder {
    z-index: 998;
    top: 0;
    right: 0;
    left: 0
}

@-webkit-keyframes stick-animation {
    from {
        -webkit-transform: translate3d(0, -100%, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0)
    }
}

@keyframes stick-animation {
    from {
        transform: translate3d(0, -100%, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}

.sticky-on .e-section-sticky,
.sticky-on.is-mstick .sticky-holder {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden
}

.stick-animated .e-section-sticky,
.stick-animated.is-mstick .sticky-holder {
    -webkit-animation-name: stick-animation;
    animation-name: stick-animation;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    will-change: transform
}

.e-section-sticky {
    z-index: 1000
}

.collapse-activated .header-mobile,
.collapse-activated .sticky-holder,
.collapse-activated body {
    overflow: hidden;
    height: 100%
}

.collapse-activated .site-outer {
    overflow: hidden;
    height: 100%;
    margin-top: 0 !important;
    -webkit-transition: none;
    transition: none
}

.collapse-activated .header-wrap {
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;
    height: 100%;
    min-height: 100vh
}

.collapse-activated .navbar-outer {
    flex-grow: 1
}

.header-mobile-wrap {
    position: relative;
    z-index: 99;
    flex-grow: 0;
    flex-shrink: 0;
    color: var(--mbnav-color, var(--nav-color));
    background: var(--mbnav-bg, var(--nav-bg));
    background: -webkit-linear-gradient(left, var(--mbnav-bg-from, var(--nav-bg-from)) 0%, var(--mbnav-bg-to, var(--nav-bg-to)) 100%);
    background: linear-gradient(to right, var(--mbnav-bg-from, var(--nav-bg-from)) 0%, var(--mbnav-bg-to, var(--nav-bg-to)) 100%);
    background: linear-gradient(to right, var(--excerpt-color, var(--nav-bg-from)) 0%, var(--mbnav-bg-to, var(--nav-bg-to)) 100%);
    box-shadow: 0 4px 30px var(--shadow-7);
}

.header-mobile-wrap .navbar-right>* {
    color: inherit
}

.mobile-logo-wrap {
    display: flex;
    flex-grow: 0;
    margin: 0
}

.mobile-logo-wrap img {
    width: auto;
    max-height: var(--mbnav-height, 42px)
}

.header-mobile .logo-title a {
    line-height: var(--mbnav-height, 42px)
}

.collapse-holder {
    display: block;
    overflow-y: auto;
    width: 100%
}

.mobile-collapse .header-search-form {
    margin-bottom: 15px
}

.mobile-collapse .rb-search-form input {
    padding-left: 0
}

.mbnav {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    min-height: var(--mbnav-height, 42px)
}

.header-mobile-wrap .navbar-right {
    align-items: center;
    justify-content: flex-end
}

.mobile-toggle-wrap {
    display: flex;
    align-items: stretch
}

.navbar-right .mobile-toggle-wrap {
    height: auto
}

.navbar-right .mobile-toggle-wrap .burger-icon>span {
    right: 0;
    left: auto
}

.navbar-right .mobile-toggle-wrap .mobile-menu-trigger {
    padding-right: 0;
    padding-left: 10px
}

.mobile-menu-trigger {
    display: flex;
    align-items: center;
    padding-right: 10px;
    cursor: pointer
}

.mobile-menu-trigger.mobile-search-icon {
    font-size: 18px;
    padding: 0 5px
}

.mobile-menu-trigger i:before {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.mobile-collapse {
    z-index: 0;
    display: flex;
    visibility: hidden;
    overflow: hidden;
    flex-grow: 1;
    height: 0;
    -webkit-transition: opacity .7s var(--timing);
    transition: opacity .7s var(--timing);
    opacity: 0;
    color: var(--submbnav-color, var(--subnav-color));
    background: var(--submbnav-bg, var(--subnav-bg));
    background: -webkit-linear-gradient(left, var(--submbnav-bg-from, var(--subnav-bg-from)) 0%, var(--submbnav-bg-to, var(--subnav-bg-to)) 100%);
    background: linear-gradient(to right, var(--submbnav-bg-from, var(--subnav-bg-from)) 0%, var(--submbnav-bg-to, var(--subnav-bg-to)) 100%)
}

.collapse-activated .mobile-collapse {
    visibility: visible;
    flex-grow: 1;
    height: auto;
    opacity: 1
}

.collapse-sections {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    gap: 15px
}

.collapse-inner {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 15px
}

.collapse-inner>:last-child {
    padding-bottom: 20px
}

.mobile-search-form .live-search-animation.rb-loader {
    top: 8px
}

.top-site-ad {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    text-align: center;
    background-color: var(--top-site-bg, var(--flex-gray-7))
}

.top-site-ad:not(.no-spacing) {
    padding-top: 15px;
    padding-bottom: 15px
}

.p-content,
.p-wrap {
    position: relative
}

.grid-box>:not(:last-child),
.p-content>:not(:last-child),
.p-wrap>:not(:last-child) {
    margin-bottom: var(--el-spacing)
}

.is-mega-category .p-wrap,
.p-small {
    --el-spacing: 7px
}

.grid-box {
    position: relative;
    height: 100%;
    padding-bottom: calc(var(--box-spacing) + 5px);
    border-radius: var(--wrap-border, var(--round-3))
}

.grid-box .p-featured {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.list-box {
    position: relative;
    border-radius: var(--wrap-border, var(--round-3))
}

.box-shadow .grid-box,
.box-shadow .list-box {
    background-color: var(--box-color, var(--default-box, transparent));
    box-shadow: 0 4px 20px var(--shadow-7)
}

.light-scheme .box-shadow .grid-box,
.light-scheme .box-shadow .list-box,
[data-theme=dark] .box-shadow .grid-box,
[data-theme=dark] .box-shadow .list-box {
    --box-shadow-color: var(--shadow-12)
}

.light-scheme .box-shadow .grid-box,
.light-scheme .box-shadow .list-box,
[data-theme=dark] .box-shadow .grid-box,
[data-theme=dark] .box-shadow .list-box {
    background-color: var(--dark-box-color, var(--default-dark-box, transparent))
}

.grid-box>:not(.feat-holder) {
    margin-right: var(--box-spacing);
    margin-left: var(--box-spacing)
}

.grid-box .feat-holder {
    z-index: 10
}

.feat-holder {
    position: relative;
    max-width: 100%
}

.p-categories,
.p-top {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    flex-grow: 1;
    gap: 3px
}

.p-top .p-categories {
    flex-grow: 0
}

.p-featured {
    position: relative;
    display: block;
    overflow: hidden;
    padding-bottom: calc(var(--feat-ratio, 60) * 1%);
    border-radius: var(--wrap-border, var(--round-3))
}

[class*=p-overlay-] .p-featured {
    margin-bottom: 0;
    border-radius: 0
}

.p-featured.ratio-v1 {
    padding-bottom: calc(var(--feat-ratio, 75) * 1%)
}

.preview-video {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: inherit;
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: cover
}

.preview-video video {
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: none
}

.show-preview .preview-video {
    display: block
}

.p-flink {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block
}

.p-featured img.featured-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    object-position: var(--feat-position, center)
}

.block-wrap:not(.p-center) .p-meta.has-bookmark {
    padding-right: 25px
}

.p-meta {
    position: relative;
    display: block
}

.meta-inner {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: .1em .7em
}

.rb-bookmark {
    font-size: var(--bookmark-fsize, 1rem);
    position: absolute;
    z-index: 10;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 100%;
    cursor: pointer;
    pointer-events: none;
    color: var(--body-fcolor)
}

.bookmarked i:not(.bookmarked-icon),
.bookmarked-icon {
    display: none
}

.bookmarked i.bookmarked-icon {
    display: block
}

.rb-bookmark i {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.rb-bookmark.in-progress {
    pointer-events: none
}

.meta-el {
    display: inline-flex;
    align-items: center;
    flex-flow: row wrap;
    width: var(--meta-width, auto);
    gap: .35em
}

.meta-avatar {
    flex-shrink: 0;
    margin-right: -.1em;
    gap: 3px
}

body .meta-avatar img {
    display: inline-flex;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: cover
}

.meta-avatar,
.meta-avatar+span {
    --meta-width: auto
}

.meta-like {
    opacity: 0
}

.elementor-editor-active .meta-like,
.meta-like.loaded {
    opacity: 1
}

.meta-like>span {
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 42px;
    padding: 5px 7px;
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    border-radius: var(--round-5);
    gap: .35em
}

.meta-like i {
    font-size: calc(1em + 3px)
}

.el-like.triggered i:before {
    content: '\e978'
}

.el-dislike.triggered i:before {
    content: '\e977'
}

span.entry-title {
    display: block
}

.entry-title i {
    font-size: .9em;
    display: inline;
    margin-right: .2em;
    vertical-align: middle;
    color: var(--title-color, currentColor)
}

.p-url {
    font-size: inherit;
    line-height: inherit;
    position: relative;
    color: var(--title-color, inherit);
    text-underline-offset: 1px
}

.is-meta .meta-author a {
    -webkit-transition: var(--effect);
    transition: var(--effect);
    text-decoration-color: transparent;
    text-decoration-line: underline;
    text-underline-offset: 1px
}

.is-meta a:hover {
    text-decoration: underline solid var(--g-color)
}

.hover-ani-2 .p-url {
    -webkit-transition: all .25s var(--timing);
    transition: all .25s var(--timing)
}

.hover-ani-2 .p-url:hover {
    color: var(--g-color)
}

.hover-ani-2 .light-scheme .p-url:hover,
.hover-ani-2 .overlay-text .p-url:hover,
.hover-ani-2[data-theme=dark] .p-url:hover {
    opacity: .7;
    color: var(--awhite)
}

.bookmark-notification {
    position: fixed;
    z-index: 5000;
    right: 0;
    bottom: 50px;
    left: calc(50% - 200px);
    display: none;
    width: 100%;
    max-width: 400px;
    padding: 0 20px;
    text-align: center
}

.bookmark-notification-inner {
    display: inline-flex;
    overflow: hidden;
    align-items: stretch;
    flex-flow: row nowrap;
    border-radius: var(--round-7);
    background-color: var(--solid-white);
    box-shadow: 0 4px 30px var(--shadow-12)
}

.follow-info {
    font-size: var(--meta-fsize);
    align-items: center;
    justify-content: center;
    padding: 20px 30px
}

.follow-desc span {
    color: #649f74
}

.follow-info strong {
    font-size: 1.1em;
    margin-left: 7px
}

.bookmark-featured {
    position: relative;
    flex-shrink: 0;
    width: 100px
}

.bookmark-featured img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.bookmark-inner {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    padding: 15px
}

.bookmark-title {
    display: inline-block;
    overflow: hidden;
    max-width: 235px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #649f74
}

.bookmark-desc {
    font-size: var(--rem-mini);
    line-height: 1.5;
    display: block;
    margin-top: 5px;
    text-align: left;
    color: var(--meta-fcolor)
}

.removed .bookmark-title,
.removed .follow-desc span {
    color: #ff7142
}

.p-category {
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    line-height: 1;
    position: relative;
    z-index: 1;
    display: inline-flex;
    flex-shrink: 0;
    color: var(--cat-fcolor, inherit)
}

.ecat-bg-4 .p-category {
    padding: 3px 7px;
    font-size: 12px;
    -webkit-transition: all .25s var(--timing);
    transition: all .25s var(--timing);
    text-decoration: none;
    color: var(--cat-fcolor, var(--awhite));
    border-radius: var(--round-3);
    background-color: var(--cat-highlight, var(--g-color))
}

.ecat-l-dot .overlay-text .p-category,
.ecat-l-dot.light-scheme .p-category,
.overlay-text .ecat-l-dot .p-category {
    color: var(--awhite);
    text-shadow: 0 1px 3px var(--shadow-7)
}

.ecat-l-dot .p-categories {
    gap: 10px
}

.ecat-l-dot .p-category:before {
    display: inline-flex;
    width: 1em;
    height: 1em;
    margin-right: 7px;
    content: '';
    border-radius: 50%;
    background-color: var(--cat-highlight, var(--g-color))
}

.ecat-l-dot .p-category {
    -webkit-transition: var(--effect);
    transition: var(--effect);
    vertical-align: middle;
    text-decoration: underline dotted transparent
}

.ecat-l-dot .p-category:hover {
    text-decoration-color: var(--cat-highlight, currentColor)
}

.feat-holder .p-top {
    position: absolute;
    z-index: 20;
    right: 12px;
    bottom: 10px;
    left: 12px;
    margin-bottom: 0
}

.p-box .feat-holder .p-top {
    right: var(--box-spacing, 12px);
    left: var(--box-spacing, 12px)
}

.ecat-bg-4 .feat-holder .p-top {
    top: auto;
    right: 0;
    bottom: 0;
    left: 0
}

.ecat-bg-4 .p-box .feat-holder .p-top {
    right: var(--box-spacing, 0);
    left: var(--box-spacing, 0)
}

.p-list-small-2 {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 10px;
}

.p-list-small-2 .feat-holder {
    flex-shrink: 0;
    width: 100px;
    margin-right: var(--featured-spacing, 15px)
}

.p-list-small-2 .p-content {
    flex-grow: 1
}

.p-wrap.p-list-small-2 .feat-holder,
.p-wrap.p-list-small-2 .p-meta {
    margin-bottom: 0
}

.is-feat-right .p-wrap {
    flex-direction: row-reverse
}

.is-feat-right .p-list-small-2 .feat-holder {
    margin-right: 0;
    margin-left: var(--featured-spacing, 15px)
}

.list-holder {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    margin-right: var(--list-holder-margin, -15px);
    margin-left: var(--list-holder-margin, -15px)
}

.list-holder>* {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    padding-right: var(--list-holder-spacing, 15px);
    padding-left: var(--list-holder-spacing, 15px)
}

.s-title-sticky {
    display: none;
    align-items: center;
    flex-flow: row nowrap;
    flex-grow: 1;
    max-width: 100%;
    margin-left: 10px;
    color: var(--nav-color)
}

.sticky-share-list {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-shrink: 0;
    height: 100%;
    margin-right: 0;
    margin-left: auto;
    padding-right: 5px;
    padding-left: 5px
}

.sticky-title {
    line-height: var(--nav-height, inherit);
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    white-space: nowrap;
    text-overflow: ellipsis
}

.sticky-share-list-buffer {
    display: none
}

.overlay-holder,
.p-highlight {
    position: relative;
    overflow: hidden;
    border-radius: var(--wrap-border, var(--round-3))
}

.block-wrap {
    position: relative;
    display: block;
    counter-reset: trend-counter
}

.block-overlay {
    --bottom-spacing: 15px
}

.block-small {
    --bottom-spacing: 25px
}

.scroll-holder {
    scrollbar-color: var(--shadow-7) var(--flex-gray-15);
    scrollbar-width: thin
}

.scroll-holder::-webkit-scrollbar {
    width: 5px;
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background: var(--flex-gray-7)
}

.scroll-holder::-webkit-scrollbar-thumb {
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background-color: var(--flex-gray-15)
}

.scroll-holder::-webkit-scrollbar-corner {
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background-color: var(--flex-gray-15)
}

.post-carousel {
    display: flex;
    flex-flow: column nowrap
}

.post-slider .slider-next,
.post-slider .slider-prev {
    font-size: var(--nav-size, 1.75rem);
    position: absolute;
    z-index: 10;
    top: 50%;
    right: auto;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    min-width: 60px;
    height: 2em;
    min-height: 60px;
    margin-top: -30px;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.light-overlay-scheme .slider-next,
.light-overlay-scheme .slider-prev,
.light-overlay-scheme .swiper-pagination-bullet {
    color: var(--awhite)
}

.post-slider .slider-next {
    right: 0;
    left: auto
}

.post-slider .slider-next:hover,
.post-slider .slider-prev:hover {
    color: var(--g-color)
}

.yt-playlist {
    overflow: hidden;
    align-items: stretch;
    border-radius: var(--round-7);
    gap: 0
}

.plist-item {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding: 10px 20px
}

.plist-item:hover {
    background-color: var(--shadow-7)
}

.yt-trigger {
    position: relative;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 70px;
    margin-right: 0;
    margin-left: auto;
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    -webkit-transform: none !important;
    transform: none !important;
    background-color: var(--g-color)
}

.play-title {
    display: block;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis
}

.qlinks-inner {
    font-size: var(--rem-mini);
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    max-width: 100%;
    list-style: none;
    gap: 5px var(--qlink-gap, 1em)
}

.qlink-label {
    margin-right: var(--label-spacing, 0);
    color: var(--label-color, var(--meta-fcolor));
}

.qlink,
.qlinks-heading {
    display: inline-flex;
    align-items: center;
    list-style: none;
    white-space: nowrap;
    color:#ffffff;
}

.qlinks-heading {
    line-height: var(--h5-fheight, 1.5)
}

.qlinks-scroll .qlinks-inner::-webkit-scrollbar {
    height: 6px;
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background: var(--flex-gray-7)
}

.qlinks-scroll .qlinks-inner::-webkit-scrollbar-thumb {
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background-color: var(--flex-gray-40)
}

.qlinks-scroll .qlinks-inner::-webkit-scrollbar-corner {
    -webkit-border-radius: var(--round-7);
    border-radius: var(--round-7);
    background-color: var(--flex-gray-40)
}

.qlink a {
    display: inline-flex;
    align-items: center;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--qlink-color, inherit);
    gap: .2em
}

.qlayout-1 .qlink a {
    text-decoration-color: transparent;
    text-decoration-line: underline
}

.light-scheme.qlayout-1,
[data-theme=dark] .qlayout-1 {
    --g-color: var(--awhite)
}

.qlayout-1 .qlink a:hover {
    text-decoration: underline;
    color: var(--g-color)
}

.block-qlinks .qlink.qlinks-heading:after,
.block-qlinks .qlink:last-child:after,
.breaking-news-prev {
    display: none
}

.breaking-news-slider .swiper-slide {
    padding-right: 50px
}

.heading-inner {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%
}

.heading-title>* {
    position: relative;
    z-index: 1;
    color: var(--wp--preset--color--white, inherit)
}

.heading-title-mobile {
    color: var(--wp--preset--color--black, inherit)
}

.heading-title a {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.heading-title a:hover {
    opacity: .7
}

.heading-tagline {
    line-height: 1;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    margin-left: auto;
    color: var(--wp--preset--color--white, inherit)
}

.heading-tagline a,
.heading-tagline i {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.heading-tagline a:hover,
.heading-tagline a:hover+i {
    opacity: .7
}

.heading-layout-2 .heading-title:before {
    display: inline-flex;
    width: .6em;
    height: .6em;
    margin-right: var(--heading-spacing, 7px);
    /*content: '';*/
    border-radius: 50%;
    background-color: var(--heading-sub-color, currentColor)
}

.heading-layout-19 .heading-inner {
    flex-flow: row wrap
}

.heading-layout-19 .heading-tagline {
    width: 100%;
    margin-top: var(--heading-spacing, 15px)
}

.f-slideup {
    position: fixed;
    z-index: 500;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: -webkit-transform .4s var(--timing);
    transition: transform .4s var(--timing);
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    will-change: transform
}

.yes-f-slideup .f-slideup {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slideup-inner {
    position: relative;
    -webkit-transition: opacity .4s var(--timing);
    transition: opacity .4s var(--timing);
    pointer-events: none;
    opacity: 0;
    background-color: var(--slideup-bg, #88888888)
}

.slideup-toggle {
    position: absolute;
    top: auto;
    right: 65px;
    bottom: 100%;
    left: auto;
    display: block;
    padding: 3px 15px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--slideup-icon-na-color, var(--awhite));
    border-top-left-radius: var(--round-5);
    border-top-right-radius: var(--round-5);
    background-color: var(--slideup-icon-na-bg, var(--g-color))
}

.yes-f-slideup .slideup-toggle {
    color: var(--slideup-icon-color, #fff);
    background-color: var(--slideup-bg, #88888888)
}

.slideup-toggle i {
    line-height: 1;
    display: block;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.yes-f-slideup .slideup-toggle:hover i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.yes-f-slideup .slideup-inner {
    visibility: visible;
    pointer-events: auto;
    opacity: 1
}

.rb-bookmark.loaded {
    visibility: visible;
    pointer-events: auto
}

.elementor-editor-active .follow-trigger {
    opacity: 1
}

.follow-trigger.in-progress i:before {
    display: inline-flex;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-animation: 1s linear infinite reaction-spinner;
    animation: 1s linear infinite reaction-spinner;
    pointer-events: none
}

.follow-trigger.in-progress i:before {
    content: '\e942'
}

.followed i:not(.followed-icon),
.followed-icon {
    display: none
}

.followed i.followed-icon {
    display: block
}

.ad-wrap {
    line-height: 1;
    display: block;
    text-align: center
}

.block-h .heading-title {
    line-height: 1;
    margin-bottom: 0
}

.heading-title i {
    margin-right: 5px
}

.privacy-bar {
    display: none;
    -webkit-transition: opacity .25s var(--timing);
    transition: opacity .25s var(--timing);
    opacity: 0
}

.privacy-bar.activated {
    display: block;
    opacity: 1
}

.privacy-bar.privacy-bottom {
    position: fixed;
    z-index: 981;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    pointer-events: none
}

.privacy-inner {
    font-size: var(--rem-mini);
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-flow: row nowrap;
    max-width: var(--rb-width, 1280px);
    margin-right: auto;
    margin-left: auto;
    padding: 7px 20px;
    pointer-events: auto;
    color: var(--privacy-color);
    border-radius: var(--round-5);
    background-color: var(--privacy-bg-color, var(--dark-accent));
    box-shadow: 0 3px 12px var(--shadow-12)
}

.privacy-bar.privacy-bottom .privacy-inner {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
    padding: 12px
}

.privacy-content {
    flex-grow: 1
}

.privacy-dismiss-btn {
    line-height: 1;
    display: flex;
    flex-shrink: 0;
    margin-left: 20px;
    padding: 5px 15px
}

.privacy-inner a:not(.is-btn) {
    font-weight: 700;
    transition: var(--effect);
    -webkit-transform: var(--effect);
    color: inherit;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px
}

.privacy-inner a:not(.is-btn):hover {
    color: var(--g-color)
}

.logo-popup-outer {
    display: flex;
    width: 100%;
    padding: 40px;
    pointer-events: auto;
    border-radius: 10px;
    background: var(--solid-white);
    box-shadow: 0 10px 30px var(--shadow-12)
}

[data-theme=dark] .logo-popup-outer {
    background-color: var(--dark-accent)
}

.rb-user-popup-form {
    position: relative;
    max-width: 350px;
    margin: auto
}

.logo-popup {
    width: 100%
}

.login-popup-header {
    position: relative;
    display: block;
    margin-bottom: 25px;
    padding-bottom: 20px;
    text-align: center
}

.login-popup-header:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    width: 30px;
    margin-left: -15px;
    content: '';
    border-bottom: 6px dotted var(--g-color)
}

.login-password label,
.login-username label {
    display: block;
    margin-bottom: var(--llabel-spacing, 8px);
    color: var(--llabel-color, var(--meta-fcolor))
}

.login-remember label {
    line-height: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--meta-fcolor);
    gap: 5px
}

.user-login-form {
    padding: var(--lform-padding, 0)
}

.user-login-form form {
    display: flex;
    flex-flow: column nowrap;
    gap: var(--linput-spacing, 15px)
}

.user-login-form form>* {
    width: 100%
}

.user-login-form input[type=checkbox] {
    margin: 0
}

.user-login-form input[type=password],
.user-login-form input[type=text] {
    display: block;
    width: 100%;
    border-radius: var(--round-7)
}

.user-login-form .login-submit {
    padding-top: 5px;
    text-align: center
}

.user-login-form input[type=submit] {
    width: 100%;
    max-width: var(--lbutton-width, 100%);
    padding: var(--lbutton-padding, 0)
}

.login-form-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 15px
}

.login-form-footer a {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.login-form-footer>:not(:last-child) {
    margin-right: 7px;
    padding-right: 7px;
    border-right: 1px solid var(--flex-gray-15)
}

.login-form-footer a:hover {
    text-decoration: underline;
    opacity: 1;
    color: var(--body-fcolor);
    text-decoration-color: var(--g-color)
}

.close-popup-btn {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    box-shadow: none;
    touch-action: manipulation
}

.close-icon {
    position: relative;
    display: block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    pointer-events: none
}

.close-icon:after,
.close-icon:before {
    position: absolute;
    top: calc(50% - 1px);
    left: 30%;
    display: block;
    width: 40%;
    height: 2px;
    content: '';
    -webkit-transition: var(--effect);
    transition: var(--effect);
    transform-origin: center center;
    opacity: .7;
    border-radius: 2px;
    background-color: currentColor
}

.close-popup-btn:hover .close-icon:after,
.close-popup-btn:hover .close-icon:before,
.privacy-dismiss-btn:hover .close-icon:after,
.privacy-dismiss-btn:hover .close-icon:before {
    transform: rotate(0);
    opacity: 1
}

.close-icon:before {
    transform: rotate(45deg)
}

.close-icon:after {
    transform: rotate(-45deg)
}

.close-popup-btn {
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0
}

#back-top {
    position: fixed;
    z-index: 980;
    right: 20px;
    bottom: 20px;
    display: block;
    cursor: pointer;
    -webkit-transition: all .5s var(--timing);
    transition: all .5s var(--timing);
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    text-decoration: none;
    pointer-events: none;
    opacity: 0;
    border: none;
    will-change: transform
}

#back-top.scroll-btn-visible {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: auto;
    opacity: 1
}

#back-top.scroll-btn-visible:hover {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0)
}

#back-top i {
    font-size: 13px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    text-align: center;
    color: #fff;
    border-radius: var(--round-3);
    background-color: var(--dark-accent)
}

.widget {
    position: relative;
    display: block
}

.sidebar-menu .menu-item {
    position: relative;
    display: block
}

.sidebar-menu>li:last-child {
    margin-bottom: 0
}

.sidebar-menu li:first-child a {
    padding-top: 0
}

.sidebar-menu a {
    font-family: var(--dwidgets-family);
    font-size: var(--dwidgets-fsize);
    font-weight: var(--dwidgets-fweight);
    font-style: var(--dwidgets-fstyle);
    position: relative;
    display: block;
    padding-top: var(--sidebar-menu-spacing, 5px);
    padding-bottom: var(--sidebar-menu-spacing, 5px);
    -webkit-transition: var(--effect);
    transition: var(--effect);
    letter-spacing: var(--dwidgets-fspace);
    text-transform: var(--dwidgets-transform);
    color: inherit
}

.sidebar-menu a:hover {
    color: var(--g-color)
}

.social-follower {
    display: grid;
    gap: var(--column-gap, 5px);
    grid-template-columns: repeat(var(--s-columns, var(--ds-columns, 2)), 1fr)
}

.follower-el {
    position: relative;
    -webkit-transition: all .5s var(--timing);
    transition: all .5s var(--timing)
}

.follower-el a {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.follower-inner {
    font-size: var(--s-icon-size, 1rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%
}

.effect-fadeout.activated .follower-el {
    opacity: .3
}

.effect-fadeout.activated .follower-el:focus,
.effect-fadeout.activated .follower-el:hover {
    opacity: 1
}

.fnlabel,
.fntotal {
    line-height: 1.3;
    margin-top: 7px;
    white-space: nowrap
}

.text-count {
    font-family: var(--meta-family);
    font-size: var(--em-small);
    font-weight: var(--meta-fweight);
    line-height: 1.5;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    white-space: nowrap;
    letter-spacing: var(--meta-fspace);
    color: var(--meta-fcolor)
}

.socials-counter.is-h-bg .follower-el:hover * {
    color: var(--awhite)
}

.fnicon {
    font-size: 1.75em;
    line-height: 1
}

.fnicon i {
    font-size: inherit;
    line-height: 1;
    text-shadow: 3px 3px 0 #ffffff10, 4px 2px 0 #00000020
}

.fb-follower {
    --s-icon-color: var(--fb-color);
    --s-icon-hcolor: var(--fb-hcolor)
}

.twitter-follower {
    --s-icon-color: var(--twitter-color);
    --s-icon-hcolor: var(--twitter-hcolor)
}

.youtube-follower {
    --s-icon-color: var(--ytube-color);
    --s-icon-hcolor: var(--ytube-hcolor)
}

.is-icon-color i {
    color: var(--s-icon-color)
}

.is-h-bg .follower-el:hover .follower-inner {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    background-color: var(--s-icon-hcolor);
    box-shadow: var(--btn-shadow, 0 3px 12px var(--s-icon-hcolor))
}

.is-gstyle-3 .fnlabel,
.is-gstyle-3 .fntotal {
    margin-top: 0;
    margin-left: 3px
}

.is-style-15 .follower-inner {
    display: grid;
    padding: 12px 7px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    border-radius: var(--round-5);
    grid-gap: 0;
    grid-template-columns: 42px auto 1fr
}

.is-style-15 .fnicon {
    height: 100%;
    text-align: center;
    grid-row: 1/3
}

.is-style-15 .text-count {
    line-height: 1;
    margin-top: 7px;
    margin-left: 4px;
    grid-column: 2/span 3;
    grid-row: 2
}

.is-style-15 .fnicon i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    justify-items: center
}

.is-style-15 .follower-inner {
    border: 1px solid var(--flex-gray-15)
}

.single-post-outer:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--flex-gray-15)
}

.single-infinite-point .rb-loader {
    margin-bottom: 25px
}

.smeta-sec {
    display: flex;
    flex-flow: column-reverse nowrap;
    text-align: left;
    gap: .1em
}

body .smeta-sec .meta-avatar img {
    width: 1.85em;
    height: 1.85em
}

.smeta-sec .meta-like>span {
    min-width: 52px
}

.sticky-share-list>* {
    line-height: 1;
    display: flex;
    flex-flow: row nowrap
}

.attribution {
    display: block;
    margin-top: 5px;
    padding-left: 65px;
    text-transform: none
}

@keyframes reaction-spinner {
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

.collapse-sections:before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 3px;
    content: '';
    background-image: linear-gradient(to right, var(--submbnav-color-10, var(--subnav-color-10)) 20%, transparent 21%, transparent 100%);
    background-image: -webkit-linear-gradient(right, var(--submbnav-color-10, var(--subnav-color-10)) 20%, transparent 21%, transparent 100%);
    background-repeat: repeat-x;
    background-position: 0 0;
    background-size: 5px
}

#reading-progress {
    display: block;
    width: 0;
    max-width: 100%;
    height: 100%;
    -webkit-transition: width .1s;
    transition: width .1s ease;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: var(--g-color);
    background: linear-gradient(90deg, var(--indicator-bg-from) 0%, var(--indicator-bg-to) 100%);
    background: -webkit-linear-gradient(right, var(--indicator-bg-from), var(--indicator-bg-to))
}

a.show-post-comment {
    line-height: var(--height-40);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: var(--padding-40);
    -webkit-transition: var(--effect);
    transition: var(--effect);
    border-radius: var(--round-7);
    background-color: var(--flex-gray-7)
}

.rb-form-rating {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 10px
}

.rb-review-stars {
    font-size: 24px;
    position: relative
}

.rb-review-stars span {
    line-height: 0;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-grow: 0;
    width: 0;
    margin-left: 10px;
    padding: 0;
    letter-spacing: 0
}

.rb-review-stars span a {
    line-height: 1;
    display: flex;
    flex-grow: 0;
    padding: 2px
}

.rb-form-rating label {
    font-size: var(--rem-mini);
    line-height: 1;
    padding-left: 7px;
    border-left: 4px solid var(--g-color)
}

.rb-form-rating.selected .rb-review-stars span:hover a i:before,
.rb-form-rating.selected a i:before,
.rb-review-stars span:hover i:before {
    content: '\e951'
}

.rb-form-rating.selected .rb-review-stars span>a:hover~a i:before,
.rb-review-stars span>a.active~a i:before,
.rb-review-stars span>a:hover~a i:before {
    content: '\e94f'
}

.rb-review-stars i {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

a.comment-reply-link {
    line-height: 24px;
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    display: block;
    padding: 0 12px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    letter-spacing: var(--btn-fspace);
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: var(--round-3)
}

a.comment-reply-link:before {
    font-family: ruby-icon;
    font-size: .9em;
    margin-right: 5px;
    content: '\e902'
}

.overlay-text .bookmark-trigger {
    border-color: var(--awhite)
}

.float-holder {
    -webkit-transform: var(--effect);
    transform: var(--effect)
}

.floating-activated .float-holder {
    position: fixed;
    z-index: 9999;
    top: auto;
    right: 20px;
    bottom: 100px;
    left: auto;
    display: block;
    width: 420px;
    max-width: 100%;
    height: auto
}

.floating-activated .float-holder:before {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 56%;
    content: ''
}

.floating-close {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 100%;
    display: block
}

.gallery-slider-nav {
    max-width: 750px;
    margin-top: 15px;
    margin-right: 0;
    margin-left: auto
}

.current-slider-count {
    font-size: 2em;
    line-height: 1;
    margin-top: 7px
}

.gallery-slider-nav .swiper-slide:not(.swiper-slide-thumb-active) img {
    opacity: .5
}

.gallery-slider-nav .swiper-slide:hover img {
    opacity: 1
}

.gallery-slider-nav img {
    -webkit-transition: opacity .25s var(--timing);
    transition: opacity .25s var(--timing)
}

.gallery-coverflow .pre-load {
    max-height: 50vh;
    opacity: 0
}

.post-slider .overlay-inner>* {
    -webkit-transition: opacity .5s var(--timing);
    transition: opacity .5s var(--timing);
    opacity: 0
}

.elementor-editor-active .post-slider .overlay-inner>* {
    opacity: 1
}

.post-slider .swiper-slide-active .overlay-inner>* {
    opacity: 1
}

.swiper-slide-active .overlay-inner>:first-child {
    -webkit-transition-delay: .3s;
    transition-delay: .3s
}

.swiper-slide-active .overlay-inner>:nth-child(2) {
    -webkit-transition-delay: .4s;
    transition-delay: .4s
}

.swiper-slide-active .overlay-inner>:nth-child(3) {
    -webkit-transition-delay: .5s;
    transition-delay: .5s
}

.swiper-slide-active .overlay-inner>* {
    -webkit-transition-delay: .6s;
    transition-delay: .6s
}

.gallery-popup-header {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 5px 0 5px 10px;
    text-align: left;
    background-color: var(--dark-accent)
}

.single-featured-popup .gallery-popup-header {
    left: auto;
    padding: 0
}

.gallery-popup-nav {
    position: static
}

.gallery-popup-header .h5:not(:empty) {
    padding-right: 20px
}

.gallery-popup-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    height: 100%;
    padding: 52px 10px
}

.single-featured-popup .gallery-popup-content {
    padding-top: 20px
}

.gallery-popup-image,
.mfp-rbgallery {
    height: 100%
}

.popup-header-right {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-shrink: 0;
    gap: 10px
}

.mfp-counter {
    font-size: var(--rem-mini)
}

.rb-gallery-popup button {
    font-size: 24px;
    cursor: pointer;
    color: var(--awhite);
    border: none;
    background: 0 0;
    text-shadow: 0 1px 4px #0008
}

.gallery-popup-image img {
    display: inline-flex;
    width: auto;
    height: 100%;
    border-radius: var(--round-5);
    object-fit: contain
}

.rb-gallery-popup .mfp-close {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    padding: 0;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    background-color: var(--dark-accent)
}

.rb-gallery-popup .mfp-close:hover {
    background-color: var(--g-color)
}

.gallery-popup-entry {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 20px;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(var(--dark-accent-0)), to(var(--dark-accent)));
    background: linear-gradient(to top, var(--dark-accent) 0, var(--dark-accent-90) 50%, var(--dark-accent-0) 100%)
}

.gallery-popup-entry>* {
    display: block
}

.gallery-popup-entry .description-text {
    margin-top: 7px
}

.featured-lightbox-trigger {
    width: 100%;
    height: 100%;
    cursor: pointer
}

.rbct {
    position: relative;
    display: block
}

.rbct>* {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 1.5rem
}

.rbct>h1,
.rbct>h2,
.rbct>h3,
.rbct>h4 {
    margin-bottom: var(--rem-mini)
}

.rbct>:last-child {
    margin-bottom: 0
}

.rbct figure {
    max-width: 100%;
    height: auto
}

.rbct input[type=password] {
    line-height: 1;
    min-width: 40%;
    margin-left: 20px
}

figcaption:not(:empty):before {
    display: inline-block;
    width: 30px;
    height: .3em;
    margin-right: 7px;
    content: '';
    border-top: 1px solid
}

.rbct dt {
    font-weight: 700
}

.rbct a.button {
    display: inline-block
}

.rbct img,
.rbct video {
    max-width: 100%;
    height: auto
}

.rbct p:empty,
cite br,
p:empty {
    display: none
}

.rbct figure a {
    border-bottom: none
}

.rbct iframe {
    overflow: hidden;
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto
}

.rbct ul {
    padding-left: 1rem;
    list-style-type: decimal
}

.rbct ul {
    list-style: circle
}

.rbct ul ul {
    list-style: disc
}

.rbct li>ul {
    padding-top: .5rem
}

.rbct li {
    position: relative;
    margin-bottom: .5rem
}

.rbct [class*=wp-block],
.rbct [class*=wp-block] * {
    list-style: none
}

.rbct>ul[class*=wp-block] {
    padding-left: 0
}

table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    text-align: left;
    border-color: var(--flex-gray-15)
}

tbody tr:nth-child(odd) {
    background-color: var(--flex-gray-7)
}

.gallery,
.rbct .gallery {
    display: flex;
    flex-flow: row wrap;
    margin-top: calc(2rem - 5px);
    margin-bottom: calc(2rem - 5px);
    text-align: center
}

.rbct iframe.instagram-media {
    margin-right: auto;
    margin-bottom: 1.5em;
    margin-left: auto
}

.elementor-widget-text-editor p a,
.rbct a:where(:not(.p-url):not(button):not(.p-category):not(.is-btn):not(.table-link-depth):not(.wp-block-button__link):not(.no-link)) {
    font-weight: var(--hyperlink-weight, 700);
    font-style: var(--hyperlink-style, normal);
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--hyperlink-color, inherit);
    text-decoration-color: var(--hyperlink-line-color, transparent);
    text-decoration-line: underline;
    text-underline-offset: 1px
}

.elementor-widget-text-editor p a:hover,
.rbct a:where(:not(.p-url):not(button):not(.p-category):not(.is-btn):not(.table-link-depth):not(.wp-block-button__link):not(.no-link)):hover {
    color: var(--g-color);
    text-decoration-color: var(--g-color)
}

.button,
.rbct input[type=button],
.rbct input[type=reset],
.rbct input[type=submit],
button {
    text-decoration: none
}

.rbct>p>img {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.elementor-widget-text-editor p:not(:last-of-type) {
    margin-bottom: 1.5em
}

.toc-content a {
    position: relative;
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    opacity: 1;
    break-inside: avoid-column
}

.toc-content a:not(:first-child) {
    border-top: 1px solid var(--flex-gray-15)
}

.toc-content a:before {
    font-size: var(--em-mini);
    display: inline-flex;
    flex-grow: 0;
    margin-right: 5px;
    padding: 0 4px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: var(--awhite);
    border-radius: var(--round-3);
    background-color: var(--dark-accent)
}

/* .toc-content a:visited,
[data-theme=dark] .toc-content a:visited:not(:hover):before {
    color: var(--flex-gray-15)
} */
[data-theme=dark] .toc-content a:visited:not(:hover):before {
    color: var(--flex-gray-15)
}

.toc-content a:hover {
    opacity: 1;
    color: var(--g-color)
}

.toc-content a:hover:before {
    background-color: var(--g-color)
}

/* body:not([data-theme=dark]) .toc-content a:visited:not(:hover):before {
    background-color: var(--flex-gray-15)
} */

.toc-content {
    padding-top: 18px;
    counter-reset: table-content
}

.toc-content a:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0
}

.toc-toggle {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    cursor: pointer;
    transition: var(--effect);
    text-decoration: none !important;
    --webkit-transition: var(--effect)
}

.toc-toggle:hover {
    color: var(--g-color)
}

.toc-toggle i {
    font-size: var(--h3-fsize);
    line-height: 24px;
    display: inline-flex;
    height: 24px;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.toc-toggle.activate i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.rbct ::selection {
    background: #cfddf0
}

[data-theme=dark] .rbct ::selection {
    background: #3e4c5f
}

.highlight-shares {
    line-height: 0;
    z-index: 999;
    cursor: pointer;
    -webkit-transition: all .3s var(--timing);
    transition: all .3s var(--timing)
}

.highlight-shares i {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 38px;
    color: var(--awhite)
}

.highlight-shares i.rbi-reddit {
    font-size: 20px;
    margin-top: -2px
}

.highlight-shares>div:first-child {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    border-radius: var(--round-7);
    background-color: var(--absolute-dark);
    box-shadow: 0 4px 15px rgba(0, 0, 0, .15)
}

.highlight-shares>div:last-child {
    left: 50%;
    margin-left: -5px;
    border-top: 5px solid var(--absolute-dark);
    box-shadow: 0 4px 15px rgba(0, 0, 0, .15)
}

.medium-entry-size .rbct>* {
    font-size: 1.1em
}

.medium-entry-size .rbct h1 {
    font-size: calc(var(--h1-fsize) * 1.1)
}

.medium-entry-size .rbct h2 {
    font-size: calc(var(--h2-fsize) * 1.1)
}

.medium-entry-size .rbct h3 {
    font-size: calc(var(--h3-fsize) * 1.1)
}

.medium-entry-size .rbct h4 {
    font-size: calc(var(--h4-fsize) * 1.1)
}

.big-entry-size .rbct>* {
    font-size: 1.2em
}

.big-entry-size .rbct h1 {
    font-size: calc(var(--h1-fsize) * 1.2)
}

.big-entry-size .rbct h2 {
    font-size: calc(var(--h2-fsize) * 1.2)
}

.big-entry-size .rbct h3 {
    font-size: calc(var(--h3-fsize) * 1.2)
}

.big-entry-size .rbct h4 {
    font-size: calc(var(--h4-fsize) * 1.2)
}

.font-resizer-trigger {
    display: inline-flex;
    align-items: center;
    flex-flow: row nowrap;
    padding-left: 5px;
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.font-resizer-trigger:after {
    font-family: ruby-icon !important;
    font-size: 9px;
    padding-left: 1px;
    content: '\e961'
}

.medium-entry-size .font-resizer-trigger {
    -webkit-transform: scale(1.07);
    transform: scale(1.07)
}

.big-entry-size .font-resizer-trigger {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.footer-wrap {
    background: linear-gradient(83deg, #1b1b1b 25.29%, rgba(27, 27, 27, .69) 49.52%, rgba(27, 27, 27, .39) 66.36%, rgba(27, 27, 27, 0) 94.89%);
    color: ffffff;
    background-color: #22222211;
    position: relative;
    z-index: 0;
    display: block
}

.footer-wrap:not(.footer-etemplate)>:first-child:not(.footer-copyright) {
    padding-top: 50px
}

.footer-wrap.left-dot:before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: block;
    width: 20%;
    height: 50%;
    content: '';
    pointer-events: none;
    opacity: .2;
    background-image: radial-gradient(var(--body-fcolor) .75px, transparent .75px);
    background-size: 16px 16px
}

[data-theme=dark] .footer-wrap.left-dot:before {
    opacity: .12
}

[data-theme=dark] .footer-etemplate .elementor-shape-fill,
[data-theme=dark] .header-template .elementor-shape-fill,
body .elementor-shape .elementor-shape-fill {
    fill: var(--solid-white)
}

.e-swiper-container .swiper-pagination-bullet:before {
    display: none
}

.e-swiper-container .swiper-pagination-bullets {
    bottom: 0
}

.site-access-popup .close-popup-btn {
    display: none;
    pointer-events: none
}

.site-access-popup {
    display: flex;
    align-items: center;
    justify-content: center
}

.elementor-widget-text-editor a.h6 {
    text-decoration: underline;
    text-decoration-color: var(--g-color)
}

#rb-checktag {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0
}

.rb-adbanner {
    min-height: 1px
}

.rb-bookmark:hover i {
    color: var(--bookmark-color);
    text-shadow: var(--btn-nshadow, 0 0 4px var(--bookmark-color-90))
}

#back-top:hover i,
.is-btn:hover,
.meta-like>span:hover,
.pagination-wrap:not(.pagination-nextprev):not(.page-links) a:hover span,
.yt-trigger:hover,
a.comment-reply-link:hover,
a.show-post-comment:hover,
input[type=submit]:hover {
    -webkit-transform: var(--btn-animation);
    transform: var(--btn-animation);
    color: var(--awhite);
    background-color: var(--g-color);
    box-shadow: var(--btn-shadow, 0 3px 12px var(--g-color-90))
}

.ecat-bg-4 .p-category:hover {
    /*box-shadow: var(--btn-nshadow, 0 3px 12px var(--cat-highlight-90, var(--g-color-90)))*/
    box-shadow: var(--btn-nshadow, 0 3px 12px var(--cat-highlight-90, var(--g-color-90)))
}

body:not(.yes-f-slideup) .slideup-toggle:hover {
    box-shadow: var(--btn-nshadow, 0 3px 12px var(--slideup-icon-na-bg-90, var(--g-color-90)))
}

.overlay-text .is-meta,
.overlay-text .meta-inner,
.overlay-text .meta-text {
    color: var(--absolute-light);
    --meta-b-fcolor: var(--awhite);
    --meta-fcolor: var(--absolute-light)
}

.overlay-text .entry-title,
.overlay-text .meta-author :not(em),
.overlay-text .rb-bookmark {
    color: var(--awhite)
}

.overlay-text .entry-summary {
    color: var(--absolute-light)
}

.meta-author {
    color: var(--meta-b-fcolor, var(--body-fcolor))
}

.meta-like .triggered {
    color: var(--meta-b-fcolor);
    background-color: var(--flex-gray-7)
}

@media(min-width:768px) {
    .rb-col-2>.block-inner>* {
        flex-basis: 50%;
        width: 50%
    }

    .rb-col-4>.block-inner>* {
        flex-basis: 25%;
        width: 25%
    }

    .rb-col-5>.block-inner>* {
        flex-basis: 20%;
        width: 20%
    }

    .overlay-inner {
        padding: 25px 20px
    }

    .p-gradient .p-highlight .overlay-inner {
        padding: 100px 25px 25px;
        background: linear-gradient(to top, var(--dark-accent) 0, var(--dark-accent-90) calc(100% - 150px), var(--dark-accent-0) 100%)
    }

    .scroll-holder {
        overflow-x: hidden;
        overflow-y: auto
    }

    .grid-box>:first-child:not(.feat-holder) {
        padding-top: calc(var(--box-spacing) + 5px)
    }

    .list-holder {
        flex-flow: row-reverse nowrap
    }

    .is-feat-left .list-holder {
        flex-flow: row nowrap
    }

    .list-feat-holder {
        flex-shrink: 0;
        width: 45%;
        max-width: 45%
    }

    .ecat-bg-4 .p-box.p-list .feat-holder .p-top {
        right: 0;
        left: 0
    }

    .list-box .list-holder {
        align-items: stretch
    }

    .list-box .feat-holder,
    .list-box .p-featured {
        height: 100%
    }

    .list-box .p-content {
        margin-top: var(--box-spacing);
        margin-bottom: var(--box-spacing);
        margin-left: var(--box-spacing)
    }

    .is-feat-left .list-box .p-content {
        margin-right: var(--box-spacing);
        margin-left: 0
    }

    .admin-bar .mfp-container {
        top: 32px;
        height: calc(100% - 32px)
    }

    .gallery-popup-content {
        padding-top: 62px
    }

    .rb-gallery-popup .mfp-arrow {
        margin-top: -60px
    }
}

@media(min-width:768px) and (max-width:1024px) {
    .rb-tcol-1>.block-inner>* {
        flex-basis: 100%;
        width: 100%
    }

    .rb-tcol-2>.block-inner>* {
        flex-basis: 50%;
        width: 50%
    }

    .meta-el:not(.tablet-hide):not(.meta-avatar):not(.no-label):not(:last-child):after {
        display: inline-flex;
        width: var(--meta-style-w, 1px);
        height: var(--meta-style-h, 6px);
        margin-left: .25em;
        content: '';
        opacity: var(--meta-style-o, .3);
        border-radius: var(--meta-style-b, 0);
        background-color: var(--meta-fcolor)
    }

    .is-thoz-scroll .block-inner {
        display: flex;
        overflow-x: scroll;
        flex-flow: row nowrap;
        -webkit-column-count: auto;
        column-count: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        text-size-adjust: none
    }

    .is-thoz-scroll .block-inner::-webkit-scrollbar {
        display: none
    }

    .is-thoz-scroll .block-inner::webkit-scrollbar-thumb {
        display: none
    }

    .is-thoz-scroll .block-inner>* {
        min-width: var(--tablet-scroll-width, 40%);
        max-width: var(--tablet-scroll-width, 40%)
    }
}

@media(min-width:1025px) {
    .heading-title>* {
        font-size: var(--heading-size-desktop, var(--heading-fsize, inherit))
    }

    .meta-el:not(.meta-avatar):not(.no-label):not(:last-child):after {
        display: inline-flex;
        width: var(--meta-style-w, 1px);
        height: var(--meta-style-h, 6px);
        margin-left: .25em;
        content: '';
        opacity: var(--meta-style-o, .3);
        border-radius: var(--meta-style-b, 0);
        background-color: var(--meta-fcolor)
    }

    #back-top {
        bottom: 40px
    }

    .e-con-boxed .e-con-inner:has(.rb-sticky) {
        align-items: flex-start !important
    }

    .admin-bar.sticky-on .e-section-sticky,
    .admin-bar.sticky-on.is-mstick .sticky-holder {
        margin-top: 32px
    }

    .collapse-footer {
        padding: 20px
    }

    .yes-tstick.sticky-on .main-menu-wrap,
    .yes-tstick.sticky-on .more-section-outer,
    .yes-tstick.sticky-on .navbar-right>:not(.dark-mode-toggle-wrap):not(.font-resizer) {
        display: none
    }

    .yes-tstick.sticky-on .s-title-sticky {
        display: flex
    }

    .yt-playlist {
        display: flex;
        flex-flow: row nowrap
    }

    .block-big {
        --bottom-spacing: 40px;
        --el-spacing: 15px
    }
}

@media(max-width:1024px) {
    :root {
        --masonry-column: 2;
        --h1-fsize: 34px;
        --h2-fsize: 24px;
        --h3-fsize: 20px;
        --headline-fsize: 34px;
        --bottom-spacing: 30px
    }

    .navbar-wrap {
        display: none
    }

    .header-mobile {
        display: flex;
        flex-flow: column nowrap
    }

    body:not(.yes-amp) .header-mobile {
        max-height: 100vh
    }

    .is-mstick .navbar-outer:not(.navbar-outer-template) {
        min-height: var(--mbnav-height, 42px)
    }

    .is-hoz-scroll .block-inner {
        display: flex;
        overflow-x: scroll;
        flex-flow: row nowrap;
        -webkit-column-count: auto;
        column-count: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        text-size-adjust: none
    }

    .is-hoz-scroll .block-inner::-webkit-scrollbar {
        display: none
    }

    .is-hoz-scroll .block-inner::webkit-scrollbar-thumb {
        display: none
    }

    .is-hoz-scroll .block-inner>* {
        min-width: var(--tablet-scroll-width, 40%);
        max-width: var(--tablet-scroll-width, 40%)
    }

    .slideup-toggle {
        right: 10px
    }

    .res-nowrap .qlinks-inner {
        overflow-x: auto;
        flex-flow: row nowrap;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        text-size-adjust: none
    }

    .qlinks-scroll .qlinks-inner {
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none
    }

    .qlinks-scroll .qlinks-inner::-webkit-scrollbar {
        display: none
    }

    .qlinks-scroll .qlinks-inner::webkit-scrollbar-thumb {
        display: none
    }
}

@media(max-width:991px) {
    .footer-wrap:not(.footer-etemplate)>:first-child:not(.footer-copyright) {
        padding-top: 40px
    }

    .burger-icon {
        color: var(--mbnav-color, var(--nav-color))
    }
}

@media(max-width:767px) {
    :root {
        --height-34: 28px;
        --height-40: 34px;
        --padding-40: 0 20px;
        --masonry-column: 1;
        --h1-fheight: 1.3;
        --h2-fheight: 1.3;
        --h3-fheight: 1.4;
        --bcrumb-fsize: 11px;
        --body-fsize: 15px;
        --btn-fsize: 11px;
        --cat-fsize: 10px;
        --dwidgets-fsize: 12px;
        --eauthor-fsize: 12px;
        --excerpt-fsize: 13px;
        --h1-fsize: 26px;
        --h2-fsize: 21px;
        --h3-fsize: 17px;
        --h4-fsize: 15px;
        --h5-fsize: 14px;
        --h6-fsize: 14px;
        --headline-fsize: 26px;
        --meta-fsize: 12px;
        --tagline-fsize: 17px;
        --rem-mini: .7rem;
        --tagline-s-fsize: 17px;
        --el-spacing: 10px;
        --rating-size: 12px;
        --b-avatar-size: 42px
    }

    .is-mhoz-scroll .block-inner {
        display: flex;
        overflow-x: scroll;
        flex-flow: row nowrap;
        -webkit-column-count: auto;
        column-count: auto;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-text-size-adjust: none;
        text-size-adjust: none
    }

    .is-mhoz-scroll .block-inner::-webkit-scrollbar {
        display: none
    }

    .is-mhoz-scroll .block-inner::webkit-scrollbar-thumb {
        display: none
    }

    .is-mhoz-scroll .block-inner>* {
        min-width: var(--tablet-scroll-width, 40%);
        max-width: var(--tablet-scroll-width, 40%)
    }

    .is-hoz-scroll .block-inner>*,
    .is-mhoz-scroll .block-inner>* {
        min-width: var(--mobile-scroll-width, 70%);
        max-width: var(--mobile-scroll-width, 70%)
    }

    .meta-el:not(.mobile-hide):not(.meta-avatar):not(.no-label):not(:last-child):after {
        display: inline-flex;
        width: var(--meta-style-w, 1px);
        height: var(--meta-style-h, 6px);
        margin-left: .25em;
        content: '';
        opacity: var(--meta-style-o, .3);
        border-radius: var(--meta-style-b, 0);
        background-color: var(--meta-fcolor)
    }

    .block-overlay {
        --bottom-spacing: 10px
    }

    .p-wrap .entry-summary {
        column-count: 1
    }

    input[type=email],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=text],
    input[type=url],
    textarea {
        padding: 7px 15px
    }

    .rb-mcol-1>.block-inner>* {
        flex-basis: 100%;
        width: 100%
    }

    body .meta-avatar img {
        width: 20px;
        height: 20px
    }

    .overlay-1 .overlay-wrap {
        position: relative;
        overflow: visible
    }

    [class*=ecat-bg-] .p-highlight .overlay-inner {
        padding-top: 0 !important
    }

    [class*=ecat-bg-] .p-highlight .p-top {
        margin-top: -1em
    }

    .p-highlight .overlay-inner {
        background: var(--highlight-overlay-bg, var(--dark-accent)) !important
    }

    .post-slider .slider-next,
    .post-slider .slider-prev {
        font-size: var(--nav-size, 1.25rem);
        min-width: 40px;
        min-height: 40px
    }

    .collapse-footer-menu .menu-item a>span {
        font-size: var(--em-small)
    }

    .rb-user-popup-form {
        max-width: 90%
    }

    .logo-popup-outer {
        padding: 20px
    }

    .p-grid-small-1 {
        display: flex;
        align-items: flex-start;
        flex-flow: row-reverse nowrap;
        width: 100%
    }

    .is-feat-left .p-grid-small-1 {
        flex-flow: row nowrap
    }

    .p-content {
        flex-grow: 1
    }

    .list-box .p-featured {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .list-box .p-content {
        margin: 0 var(--box-spacing) var(--box-spacing)
    }

    .is-feat-left .p-grid-small-1 .feat-holder {
        margin-right: 20px;
        margin-left: 0
    }

    .plist-item {
        padding: 10px
    }

    .yt-trigger {
        width: 54px
    }

    .attribution {
        padding-left: 0
    }

    .p-grid-small-1 .feat-holder {
        flex-shrink: 0;
        width: 100%;
        max-width: var(--feat-list-width, 100px);
        margin-bottom: 0;
        margin-left: 15px
    }

    .p-grid-small-1 .p-content {
        flex-grow: 1
    }

    .footer-wrap.left-dot:before {
        display: none
    }

    [class*=ecat-bg-]:not(.is-m-list):not(.ecat-bg-3) .p-list-2 .p-top {
        position: relative;
        z-index: 3;
        margin-top: calc(-2px - var(--el-spacing) - 1em);
        padding-left: 7px
    }

    span.current-slider-count {
        font-size: 1.5em
    }

    .collapse-sections:before {
        height: 2px
    }

    .bookmark-notification {
        bottom: 20px
    }

    .top-site-ad:not(.no-spacing) {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .bookmark-featured {
        width: 70px
    }

    .bookmark-title {
        width: 205px
    }

    .post-slider {
        position: relative;
        overflow: hidden;
        border-radius: var(--round-3);
        background-color: var(--dark-accent)
    }

    .p-list-2 .list-feat-holder {
        width: 100% !important;
        min-width: 100%;
        max-width: 100% !important;
        margin-bottom: calc(var(--el-spacing) + 2px)
    }

    .is-m-list .p-wrap {
        min-height: calc(var(--feat-list-width, 150px) * var(--feat-ratio, 60)/ 100)
    }

    .is-m-list .feat-holder {
        position: absolute;
        top: 0;
        right: var(--column-gap, 0);
        left: auto;
        width: var(--feat-list-width, 150px);
        margin-bottom: 0;
        padding-bottom: 0 !important
    }

    .is-m-list .p-wrap>:not(.feat-holder):not(.grid-box):not(.list-box) {
        margin-right: calc(var(--feat-list-width, 150px) + var(--el-spacing, 10px) * 2);
        margin-left: 0
    }

    .is-m-list .grid-box,
    .is-m-list .list-box {
        position: static;
        padding-top: var(--box-spacing);
        padding-right: var(--feat-list-width, 150px);
        padding-bottom: var(--box-spacing);
        padding-left: 0
    }

    .block-wrap:not(.is-m-list) .grid-box>:first-child:not(.feat-holder) {
        padding-top: calc(var(--box-spacing) + 5px)
    }

    .is-m-list .grid-box .feat-holder,
    .is-m-list .list-box .feat-holder {
        bottom: 0;
        --box-spacing: 7px
    }

    .is-m-list .grid-box .p-featured,
    .is-m-list .list-box .p-featured {
        overflow: hidden;
        height: 100%;
        padding-bottom: 0;
        border-radius: 0 var(--wrap-border, var(--round-3)) var(--wrap-border, var(--round-3)) 0
    }

    .is-m-list .p-list .list-feat-holder {
        margin-bottom: 0
    }

    .is-m-list .p-list:not(.p-box) .p-content {
        padding-right: 0;
        padding-left: 0
    }

    .is-m-list .list-box .p-content {
        margin: 0
    }

    .is-m-list:not(.res-feat-left) .list-box .p-content {
        padding-left: var(--box-spacing)
    }

    .is-m-list .list-box .list-holder {
        margin-right: 0;
        margin-left: 0
    }
}

@media all {
    .p-content>:last-child {
        margin-bottom: 0
    }
}

@font-face {
    font-family: 'ruby-icon';
    font-display: swap;
    src: url(../../assets/fonts/icons.woff2?2.2.0) format('woff2');
    font-weight: 400;
    font-style: normal
}

@media print {
    body[data-theme=dark] {
        --h1-fcolor: #000000;
        --h2-fcolor: #000000;
        --h3-fcolor: #000000;
        --h4-fcolor: #000000;
        --h5-fcolor: #000000;
        --h6-fcolor: #000000;
        --meta-fcolor: #000000;
        --body-fcolor: #000000;
        --meta-b-fcolor: #000000
    }

    .featured-gallery-wrap,
    .footer-wrap,
    .header-wrap,
    .ruby-table-contents,
    .tipsy,
    .top-site-ad {
        display: none !important
    }

    body .site-outer {
        margin-top: 0 !important
    }

    .smeta-sec * {
        color: #000 !important
    }
}

:root {
    --body-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --body-fweight: 400;
    --body-fcolor: #282828;
    --body-fsize: 20px;
    --h1-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h1-fweight: 900;
    --h1-fsize: 36px;
    --h2-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h2-fweight: 900;
    --h2-fsize: 26px;
    --h3-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h3-fweight: 700;
    --h3-fsize: 19px;
    --h4-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h4-fweight: 700;
    --h4-fsize: 16px;
    --h5-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h5-fweight: 700;
    --h5-fsize: 14px;
    --h6-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --h6-fweight: 700;
    --h6-fsize: 12px;
    --cat-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --cat-transform: uppercase;
    --cat-fsize: 11px;
    --cat-fspace: -0.02273em;
    --meta-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --meta-fweight: 400;
    --meta-transform: uppercase;
    --meta-fsize: 11px;
    --meta-b-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --meta-b-fweight: 600;
    --meta-b-transform: uppercase;
    --input-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --input-fweight: 500;
    --input-fsize: 14px;
    --btn-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --btn-fweight: 700;
    --btn-transform: uppercase;
    --btn-fsize: 12px;
    --menu-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --menu-fweight: 500;
    --menu-fsize: 16px;
    --submenu-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --submenu-fweight: 500;
    --submenu-fsize: 15px;
    --dwidgets-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --dwidgets-fweight: 700;
    --dwidgets-fsize: 13px;
    --headline-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --headline-fweight: 900;
    --headline-fsize: 46px;
    --tagline-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --tagline-fweight: 500;
    --tagline-fsize: 17px;
    --heading-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --heading-fweight: 700;
    --subheading-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --subheading-fweight: 400;
    --subheading-fsize: 14px;
    --quote-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --quote-fweight: 900;
    --quote-fspace: -1px;
    --excerpt-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --excerpt-fweight: 500;
    --readmore-fsize: 12px;
    --bcrumb-fsize: 14px;
    --excerpt-fsize: 15px;
    --headline-s-fsize: 46px;
    --tagline-s-fsize: 18px
}

@media (max-width:767px) {
    body {
        --body-fsize: 18px;
        --h1-fsize: 28px;
        --h2-fsize: 20px;
        --h3-fsize: 16px;
        --h4-fsize: 14px;
        --h5-fsize: 12px;
        --h6-fsize: 12px;
        --meta-fsize: 11px;
        --readmore-fsize: 11px;
        --input-fsize: 13px;
        --btn-fsize: 11px;
        --bcrumb-fsize: 12px;
        --excerpt-fsize: 12px;
        --dwidgets-fsize: 12px;
        --headline-fsize: 26px;
        --headline-s-fsize: 26px;
        --tagline-fsize: 16px;
        --tagline-s-fsize: 16px
    }
}

:root {
    /*--g-color: #ff0070;*/
    --g-color: #ffaa12;
    /*--g-color-90: #ff0070e6;*/
    --g-color-90: #ffaa12e6;
    --bcrumb-color: #282828
}

.light-scheme,
[data-theme=dark] {
    --dark-accent: #171a22;
    --dark-accent-90: #171a22e6;
    --dark-accent-0: #171a2200;
    --bcrumb-color: #ffffff
}

:root {
    --max-width-wo-sb: 860px;
    --round-3: 7px;
    --round-5: 10px;
    --round-7: 15px;
    --hyperlink-line-color: var(--g-color);
    --slideup-bg: rgba(255, 255, 255, 1);
    --slideup-icon-color: #000000
}

tr:nth-child(odd) {
    background-color: #fafafa
}

/* tr:hover {
    background-color: #f0f0f0
} */

.mywikiad-close svg {
    height: auto;
    width: 20px
}

.wiki-input {
    width: 100% !important;
    max-width: 800px !important
}

#larapush {
    display: block !important
}

#larapush-optin {
    display: none
}

@media screen and (max-width:440px) {
    body {
        margin: 0
    }
}

.elementor-widget-divider {
    --divider-border-style: none;
    --divider-border-width: 1px;
    --divider-color: #0c0d0e;
    --divider-icon-size: 20px;
    --divider-element-spacing: 10px;
    --divider-pattern-height: 24px;
    --divider-pattern-size: 20px;
    --divider-pattern-url: none;
    --divider-pattern-repeat: repeat-x
}

.elementor-widget-divider .elementor-divider {
    display: flex
}

.elementor-widget-divider .elementor-divider-separator {
    display: flex;
    margin: 0;
    direction: ltr
}

.elementor-widget-divider:not(.elementor-widget-divider--view-line_text):not(.elementor-widget-divider--view-line_icon) .elementor-divider-separator {
    border-block-start: var(--divider-border-width) var(--divider-border-style) var(--divider-color)
}

.elementor-widget-divider--separator-type-pattern {
    --divider-border-style: none
}

.elementor-widget-divider--separator-type-pattern.elementor-widget-divider--view-line .elementor-divider-separator,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:after,
.elementor-widget-divider--separator-type-pattern:not(.elementor-widget-divider--view-line) .elementor-divider-separator:before,
.elementor-widget-divider--separator-type-pattern:not([class*=elementor-widget-divider--view]) .elementor-divider-separator {
    width: 100%;
    min-height: var(--divider-pattern-height);
    -webkit-mask-size: var(--divider-pattern-size) 100%;
    mask-size: var(--divider-pattern-size) 100%;
    -webkit-mask-repeat: var(--divider-pattern-repeat);
    mask-repeat: var(--divider-pattern-repeat);
    background-color: var(--divider-color);
    -webkit-mask-image: var(--divider-pattern-url);
    mask-image: var(--divider-pattern-url)
}

.e-con-inner>.elementor-widget-divider,
.e-con>.elementor-widget-divider {
    width: var(--container-widget-width, 100%);
    --flex-grow: var(--container-widget-flex-grow)
}

.elementor-column .elementor-spacer-inner {
    height: var(--spacer-size)
}

.e-con {
    --container-widget-width: 100%
}

.e-con-inner>.elementor-widget-spacer,
.e-con>.elementor-widget-spacer {
    width: var(--container-widget-width, var(--spacer-size));
    --align-self: var(--container-widget-align-self, initial);
    --flex-shrink: 0
}

.e-con-inner>.elementor-widget-spacer>.elementor-widget-container,
.e-con>.elementor-widget-spacer>.elementor-widget-container {
    height: 100%;
    width: 100%
}

.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer,
.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer {
    height: 100%
}

.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner,
.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner {
    height: var(--container-widget-height, var(--spacer-size))
}

.res-uid_0d3d340 {
    width: 320px;
    height: 100px
}

@media (min-width:500px) {
    .res-uid_0d3d340 {
        width: 728px;
        height: 90px
    }
}

@media (min-width:800px) {
    .res-uid_0d3d340 {
        width: 728px;
        height: 90px
    }
}

#ays_tooltip,
.ays_tooltip_class {
    display: none;
    position: absolute;
    z-index: 999999999;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    border: 1px solid #b7b7b7;
    border-radius: 3px;
    box-shadow: transparent 0 0 15px 1px;
    color: red;
    padding: 5px;
    text-transform: none
}

#ays_tooltip>*,
.ays_tooltip_class>* {
    color: red;
    font-size: 12px
}

#ays_tooltip_block {
    font-size: 12px;
    backdrop-filter: blur(0px)
}

@media screen and (max-width:768px) {

    #ays_tooltip,
    .ays_tooltip_class {
        font-size: 12px
    }

    #ays_tooltip>*,
    .ays_tooltip_class>* {
        font-size: 12px
    }
}

/* :not(input):not(textarea)::selection {
    background-color: transparent !important;
    color: inherit !important
}

:not(input):not(textarea)::-moz-selection {
    background-color: transparent !important;
    color: inherit !important
} */

.elementor-50846 .elementor-element.elementor-element-47004a9 {
    --display: flex;
    --background-transition: 0.3s
}







/* .site-wrap {
    padding-top: 80px;
} */
ul.primary-menu {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    max-width: 1011px;
    margin: 0 auto;
}

ul.primary-menu>li {
    /*display: inline-block;*/
    position: relative;
    transition: all 400ms ease;
    /*text-align: center;*/
}

ul.primary-menu>li>a {
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    /*text-transform: uppercase;*/
    color: var(--heading-sub-color);
    padding: 5px 10px;
    display: inline-block;
    font-family: 'Proxima Nova Lt';
    text-align: left;
    margin: 5px 0;
}

ul.primary-menu>li>ul.sub-menu {
    position: absolute;
    left: 50%;
    right: 0;
    width: 200px;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50%);
}

/*ul.primary-menu >li >a:hover { background-color: #1bb14b; }*/
/*ul.primary-menu li.current-menu-item >a { background-color: #1bb14b; }*/
ul.primary-menu>li>a small.nav_desc {
    display: block;
    text-align: center;
}

ul.primary-menu>li.current-menu-item:before {
    content: '';
    width: 100%;
    height: 2px;
    background: red;
    z-index: 999;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    display: block !important;
    bottom: 0;
}

ul.primary-menu>li:hover>ul.sub-menu {
    opacity: 1;
    visibility: visible;
}

ul.primary-menu>li>ul.sub-menu li a {
    right: 0;
}

ul.primary-menu>li>ul.sub-menu li a {
    /* border-top: 1px solid #fff; */
    /* background-color: rgba(13, 14, 62, 0.6); */
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    display: inline-block;
    padding: 8px 13px;
    font-family: 'Proxima Nova Lt';
    /* width: 100%; */
    letter-spacing: 0.5px;
}

.testimonials-wrap {
    padding: 0;
}

ul.primary-menu>li>ul.sub-menu li:hover a {
    /* background-color: #1bb14b; */
}

.menulinks {
    display: none;
    text-align: center;
    width: 41px;
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 22px;
    position: absolute;
    top: -70px;
    right: 15px;
    cursor: pointer;
}

ul.primary-menu>li>a small.nav_desc {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    color: #ffff;
    opacity: 0.7;
}

ul.primary-menu>li.home-menu-icon a:before {
    content: '\f015';
    left: -10px;
    display: block;
    font-size: 30px;
    font-family: FontAwesome;
    font-weight: normal;
    position: relative;
    top: 12px;
    top: 50%;
    transform: translate(10px, 10px);
}

ul.primary-menu>li.home-menu-icon a {
    font-size: 0;
}

ul.primary-menu>li a.child-triggerm {
    display: none;
}

ul.primary-menu>li:hover>ul.sub-menu li {
    background-color: rgba(13, 14, 62, 0.84);
    border-top: 1px solid #fff;
    transition: all 400ms ease;
}

ul.primary-menu>li:hover>ul.sub-menu li:hover {
    background-color: #1bb14b;
}

ul.primary-menu>li>a:after {
    content: '';
    position: absolute;
    height: 70%;
    width: 1px;
    /*background: #595959;*/
    right: -3px;
    top: 50%;
    transform: translateY(-50%);
}

ul.primary-menu>li:last-child>a:after {
    display: none;
}

/*23-12-2019*/
ul.primary-menu>li.current-menu-item a {
    color: #f1473f;
}

.main-header-section {
    transition: all 400ms ease;
}

.main-header-section.fixed .main-header-top .main_menu ul>li>ul.sub-menu li {
    background-color: rgb(13, 14, 62);
}

.main-header-section.fixed .main-header-top .main_menu {
    background-color: rgb(13, 14, 62);
}

.main-header-section.fixed .main-header-top ul.primary-menu>li:hover>ul.sub-menu li:hover {
    background-color: #1bb14b;
}

.main-header-section.fixed header.header {
    padding: 15px 0;
}

@media(max-width:991px) {
    ul.primary-menu>li>a {
        padding: 3px 0;
        font-size: 16px;
    }

    ul.primary-menu {
        max-width: 100%;
        justify-content: space-around;
    }

    ul.primary-menu>li>a:after {
        right: -20px;
    }

    ul.primary-menu>li>a small.nav_desc {
        font-size: 11px;
        line-height: normal;
    }

    .sub-footer {
        max-width: 100%;
        padding: 0 0px;
    }
}

@media(max-width:1024px) {
    ul.primary-menu {
        /*position: fixed;*/
        background: linear-gradient(83deg, #1b1b1b 25.29%, rgba(27, 27, 27, .69) 49.52%, rgba(27, 27, 27, .39) 66.36%, rgba(27, 27, 27, 0) 94.89%);
        top: 100px;
        left: 0;
        right: 0;
    }

    ul.primary-menu>li {
        width: 100%;
    }

    /* ul.primary-menu {
        display: none;
    } */

    ul.primary-menu>li>a {
        width: 100%;
    }

    ul.primary-menu>li>ul.sub-menu {
        position: relative;
        left: auto;
        top: auto;
        float: left;
        width: 100%;
        display: none;
    }

    ul.primary-menu>li:hover>ul.sub-menu {
        display: none;
        visibility: visible;
        opacity: 1;
        transform: translateX(0%);
    }

    ul.primary-menu>li>a {
        padding: 10px 0;
    }

    ul.primary-menu li.current-menu-item>a {}

    ul.primary-menu>li>a {
        border-top: 1px solid #fff;
        margin: 0px 0;
        padding: 10px 10px;
    }

    ul.primary-menu>li>ul.sub-menu {
        width: 100%;
    }

    ul.primary-menu>li:hover>ul.sub-menu li {
        border-color: transparent;
    }

    ul.primary-menu>li.current-menu-item:before {
        display: none !important;
    }
}

ul.primary-menu {
    max-width: 100% !important;
}

.footer-span {
    color: #ffffff; 
    font-family: Söhne, ui-sans-serif, system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell, 'Noto Sans', sans-serif, 'Helvetica Neue', Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 16px; 
    font-style: normal; 
    font-weight: 400; 
    white-space-collapse: preserve;
}
.page404-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 70vh;
}
.edge-padding {
    padding-right: 20px;
    padding-left: 20px;
}
.page404-inner {
    display: block;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}
.page404-featured {
    display: block;
    margin-bottom: 40px;
    text-align: center;
}
.page404-inner .page-title, .page404-inner .page404-description {
    margin-bottom: 20px;
}
.page404-inner>*:not(.page404-featured) {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.page404-description {
    color: var(--meta-fcolor);
}
.page404-btn-wrap {
    padding-top: 10px;
}
a.page404-btn {
    line-height: var(--height-40);
    display: inline-block;
    color: inherit;
    border-radius: var(--round-7);
    background-color: var(--flex-gray-7);
}
@media (min-width: 1025px) {
    .page404-inner {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .page404-inner .page-title, .page404-inner .page404-description {
        margin-bottom: 25px;
    }
    .page404-btn-wrap {
        padding-top: 20px;
    }
}
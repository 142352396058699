.styles_hero_section_circleImg__hsJQL {
    position: absolute;
    top: 32%;
}
.styles_bottomVectorImage__nBS73, .styles_topVectorImage__OV2Yx {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.styles_topVectorImage__OV2Yx {
    position: absolute;
    top: -15px;
}
.heading-title .quicklink {
    color: var(--heading-sub-color, currentColor)
}
#site-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it's higher than other elements */
}
.loading-wrapper {
    background: linear-gradient(83deg, #1b1b1b 25.29%, rgba(27, 27, 27, .69) 49.52%, rgba(27, 27, 27, .39) 66.36%, rgba(27, 27, 27, 0) 94.89%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full page height */
  
}
.loading-text {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    animation: blink 1.5s steps(5, start) infinite;
}
  
@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}
.is-body {
    font-family: inherit;
    overflow-wrap: break-word;
    text-transform: none;
}
.gh-card-excerpt {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* font-size: 1.45rem; */
    line-height: 1.4;
    /* margin-top: 8px; */
    overflow-y: hidden;
}
.elementor-element-2523998 .is-body {
    color: var(--wp--preset--color--white);
}
/* Ensure that the dropdown is hidden by default */    
#mega-dropdown-1 {
    left: -300px;
}
#mega-dropdown-2 {
    left: -451px;
}
#mega-dropdown-3 {
    left: -583px;
}
#mega-dropdown-4 {
    left: -740px;
}
#mega-dropdown-5 {
    left: -933px;
}
#mega-dropdown-6 {
    left: -1048px;
}
#menu-header-menu li .mega-dropdown {
    border-radius: 17px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%; /* Position the dropdown below the menu item */
    z-index: 999;
    transition: visibility 0s, opacity 0.3s ease; /* Smooth transition */
}

#menu-header-menu li:hover .mega-dropdown {
    width: 1272px;
}

/* Show the dropdown when the li is hovered */
#menu-header-menu li:hover .mega-dropdown {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s 0s, opacity 0.3s ease; /* Visibility appears instantly, opacity fades in */
}

/* Make sure li is positioned relative to correctly align the dropdown */
#menu-header-menu li {
    position: relative;
}

/* Additional styling for the dropdown to look clean */
.mega-dropdown {
    /* padding: 15px;  */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
    background: linear-gradient(to right, var(--submbnav-bg-from, #f9f9f9), var(--submbnav-bg-to, #f1f1f1)); /* Optional gradient background */
}
@media (min-width: 768px) {
    .styles_hero_section_circleImg__hsJQL {
        top: 55%;
        width: 40%;
    }
}
@media (min-width: 992px) {
    .styles_hero_section_circleImg__hsJQL {
        top: 28%;
        width: auto;
    }
}
@media (min-width: 1021px) and (max-width: 1228px) {
    /* Ensure this applies specifically to the mega-dropdown inside the menu */
    #menu-header-menu li:hover .mega-dropdown {
        width: 1020px;
    }
  
    /* Adjust the positioning of each mega-dropdown */
    #mega-dropdown-1 {
      left: -221px;
    }
  
    #mega-dropdown-2 {
      left: -371px;
    }
  
    #mega-dropdown-3 {
      left: -504px;
    }
  
    #mega-dropdown-4 {
      left: -662px;
    }
  
    #mega-dropdown-5 {
      left: -854px;
    }
  
    #mega-dropdown-6 {
      left: -221px;
    }
}
.search-header {
    /* background-color: #151515; */
    position: relative;
    display: block;
    padding-top: 25px;
}
.search-header:before {
    background: #151515;
    background-image: url(../../assets/images/bg-image.31b166a9648293966af1.svg);
    padding: 50px 0px;
    margin-top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(../images/Roman-Reigns-Traditional-Samoan-Tribal-Tattoo.jpg); */
    background-attachment: scroll;
    background-position: center center;
}
.search-header:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 27px;
    left: 0;
    content: '';
    pointer-events: none;
    /* background-color: var(--flex-gray-7); */
}
.search-title {
    color: var(--awhite);
}
.edge-padding {
    padding-right: 20px;
    padding-left: 20px;
}
.rb-container, .rb-wide-container, .rb-small-container {
    position: static;
    display: block;
    width: 100%;
    max-width: var(--rb-width, 1280px);
    margin-right: auto;
    margin-left: auto;
}
.search-header-inner {
    position: relative;
    z-index: 1;
}
.search-header-content {
    display: block;
    margin-bottom: 25px;
    text-align: center;
}
.search-subtitle {
    display: block;
    margin-top: 10px;
    color: var(--meta-fcolor);
}
.search-header-form .search-form, .page404-inner .search-form {
    font-size: 1.5rem;
}
.search-header-form .search-form {
    max-width: 620px;
    margin-right: auto;
    margin-left: auto;
    border-radius: var(--round-5);
    background-color: var(--solid-white);
    box-shadow: 0 0 40px var(--shadow-12);
}
.search-form, .wp-block-search__inside-wrapper {
    position: relative;
    /* display: flex; */
    overflow: hidden;
    align-items: stretch;
    flex-flow: row nowrap;
    width: 100%;
    border-radius: var(--round-7);
    background-color: var(--flex-gray-7);
}
.search-form-icon {
    display: flex;
    align-items: center;
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    padding-left: 15px;
}
.search-form-icon span {
    font-size: var(--em-small);
}
.rbi-search:before {
    content: '\e946';
}
.search-form-input {
    display: inline-flex;
    flex-grow: 1;
}
.screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    word-wrap: normal !important;
    border: 0;
    clip-path: inset(50%);
    -webkitcolorip-path: inset(50%);
}
.search-form-submit:before {
    position: absolute;
    top: 50%;
    left: 0;
    height: .8em;
    margin-top: -.4em;
    content: '';
    border-left: 2px solid var(--g-color);
}
.search-form-submit {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
}
.wp-block-search [type=submit] {
    position: relative;
    height: 100%;
    padding: 5px 20px;
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    color: inherit;
    border: none;
    background-color: transparent;
    box-shadow: none;
}
input[type=submit], .is-btn, .button, div:where(.entry-content) button {
    line-height: var(--height-40);
    padding: var(--padding-40);
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    white-space: nowrap;
    color: var(--awhite);
    border: none;
    border-radius: var(--round-3);
    outline: none !important;
    background: var(--g-color);
    -webkit-box-shadow: none;
    box-shadow: none;
}
.wp-block-search [type=submit]:before {
    position: absolute;
    top: 50%;
    left: 0;
    height: .8em;
    margin-top: -.4em;
    content: '';
    border-left: 2px solid var(--g-color);
}
.elementor-50417 .elementor-element.elementor-element-117d2b4:not(.elementor-motion-effects-element-type-background), .elementor-50417 .elementor-element.elementor-element-117d2b4>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #88888811;
}
.elementor-50417 .elementor-element.elementor-element-117d2b4 {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    margin-top: -30px;
    margin-bottom: 0;
    padding: 30px 0;
}
.elementor-element {
    --widgets-spacing: 20px 20px;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-section {
    position: relative;
}
.elementor-50417 .elementor-element.elementor-element-117d2b4>.elementor-container {
    max-width: 1280px;
}
.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}
.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}
.elementor-element, .elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
}
.elementor-element {
    --widgets-spacing: 20px 20px;
}
.elementor-50417 .elementor-element.elementor-element-117d2b4 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px;
}
.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: flex;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
}
*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Nunito', sans-serif;
    color: #384047;
  }
  
  form {
    max-width: 300px;
    margin: 10px auto;
    padding: 10px 20px;
    background: #f4f7f8;
    border-radius: 8px;
  }
  
  h1 {
    margin: 0 0 30px 0;
    text-align: center;
  }
  
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="file"],
  textarea,
  select {
    background: rgba(255,255,255,0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px;
    width: 100%;
    background-color: #e8eeef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    /* margin-bottom: 30px; */
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 4px 8px 0;
  }
  
  select {
    padding: 6px;
    /* height: 32px; */
    border-radius: 2px;
  }
  
  button {
    padding: 19px 39px 18px 39px;
    color: #FFF;
    background-color: var(--g-color);
    font-size: 18px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    width: 100%;
    border: 1px solid var(--g-color);
    border-width: 1px 1px 3px;
    box-shadow: 0 -1px 0 rgba(255,255,255,0.1) inset;
    margin-bottom: 10px;
  }
  
  fieldset {
    margin-bottom: 30px;
    border: none;
  }
  
  legend {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  label.light {
    font-weight: 300;
    display: inline;
  }

  .row {
    width: 100%;
  }
  
  .number {
    background-color: #5fcf80;
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255,255,255,0.2);
    border-radius: 100%;
  }

  .coverphoto {
    border: 1px solid #f6cd66;
    margin: 15px 15px 15px 0px;
  }

  .sun-editor {
    margin: 15px 15px 15px 0px;
  }

  .Toastify {
    max-width: 100%; /* Ensures responsiveness */
  }

  .Toastify__toast-body {
    width: 100%;
  }
  
  @media screen and (min-width: 480px) {
  
    form {
      max-width: 100%;
    }
  
  }
  
@media (min-width: 1025px) {
    .search-header {
        padding-top: 50px;
    }
    .search-header-content {
        margin-bottom: 40px;
    }
    .search-header-form .search-form input[type=search], .page404-inner .search-form input[type=search] {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .wp-block-search input[type=search] {
        position: relative;
        width: 100%;
        padding: 0 15px;
        border: none;
        background: 0 0 !important;
    }
}
@media (min-width: 768px) {
    .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
        width: 100%;
    }
}
@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px
    }

    .e-con {
        --container-max-width: 1024px
    }
}
@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px
    }

    .e-con {
        --container-max-width: 767px
    }
}
.elementor-50414 .elementor-element.elementor-element-8ab9c3d>.elementor-container {
    max-width: 1280px
}

.elementor-50414 .elementor-element.elementor-element-8ab9c3d .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 20px
}

.elementor-50414 .elementor-element.elementor-element-8ab9c3d:not(.elementor-motion-effects-element-type-background) {
    /*background-color: #88888811*/
    background: linear-gradient(267deg, #eea900 -2.97%, #0e448a 96.94%);
    box-shadow: inset 0 4px 184px 93px #fff3e1;
}

.elementor-50414 .elementor-element.elementor-element-8ab9c3d {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 30px 0
}

.elementor-50414 .elementor-element.elementor-element-8ab9c3d>.elementor-shape-top svg {
    height: 70px
}

.elementor-50414 .elementor-element.elementor-element-8ab9c3d>.elementor-shape-bottom svg {
    height: 70px
}

.elementor-50414 .elementor-element.elementor-element-a8abb6a {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-50414 .elementor-element.elementor-element-a8abb6a .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-50414 .elementor-element.elementor-element-a8abb6a .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-50414 .elementor-element.elementor-element-a8abb6a .p-wrap {
    --el-spacing: 15px
}

.elementor-50414 .elementor-element.elementor-element-a8abb6a .block-wrap {
    --bottom-spacing: 40px
}

@media(max-width:1024px) {
    .elementor-50414 .elementor-element.elementor-element-8ab9c3d {
        padding: 20px 0
    }

    .elementor-50414 .elementor-element.elementor-element-a8abb6a {
        --feat-ratio: 60
    }
}
.breadcrumb-inner {
    font-family: 'encode sans condensed', sans-serif;
    font-size: var(--bcrumb-fsize, 13px);
    font-weight: 600;
    font-style: normal;
    line-height: 1.5;
    color: var(--bcrumb-color, inherit)
}

.rbi-share:before {
    content: '\e94e'
}
.menu-ani-4 .main-menu>.menu-item.current-menu-item>a>span{
    border-bottom-color: var(--nav-color-h-accent, var(--g-color))
}
.breadcrumb-inner a {
    -webkit-transition: var(--effect);
    transition: var(--effect);
    text-decoration-color: transparent;
    text-decoration-line: underline;
    text-underline-offset: 1px
}
.breadcrumb-inner a:hover {
    text-decoration: underline solid var(--g-color)
}
[data-theme=dark] .sub-cat-item a {
    --g-color: var(--awhite)
}
.sub-cat-item a:hover {
    text-decoration: underline;
    color: var(--g-color)
}
.rb-follow {
    font-size: 12px;
    display: flex;
    visibility: hidden;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    width: 34px;
    height: 34px;
    cursor: pointer;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    pointer-events: none;
    border: 1px solid var(--flex-gray-15);
    border-radius: 50%
}
.rb-follow.loaded {
    visibility: visible;
    pointer-events: auto
}
.rb-follow i {
    line-height: 32px;
    width: 100%;
    height: 100%;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    text-align: center
}
.light-scheme .rb-follow:not(:hover) {
    color: var(--awhite);
    border-color: var(--awhite)
}
.breadcrumb-wrap:not(.breadcrumb-line-wrap) .breadcrumb-inner {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis
}

body.rtl .breadcrumb-inner {
    display: flex
}

body.rtl .breadcrumb-inner>* {
    margin: 0 2px
}

body.rtl .breadcrumb-inner>:first-child {
    margin-right: 0
}

.breadcrumb-wrap:not(.breadcrumb-line-wrap) .current-item,
.breadcrumb-wrap:not(.breadcrumb-line-wrap) .last {
    opacity: .5
}
.archive-header {
    position: relative;
    z-index: 1;
    padding-top: 25px;
    padding-bottom: 25px
}

.is-pattern:before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    pointer-events: none;
    opacity: .15
}

.pattern-dot:before {
    background-image: radial-gradient(var(--body-fcolor) 1px, transparent 1px);
    background-size: 15px 15px
}

.archive-inner {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-flow: row nowrap
}

.archive-title.b-follow {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 20px
}

.b-follow .rb-follow {
    width: auto;
    min-width: 100px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: var(--round-7)
}

.b-follow .rb-follow i {
    -webkit-transform: none !important;
    transform: none !important
}

.b-follow .rb-follow i:after {
    font-family: var(--meta-b-family);
    font-size: inherit;
    font-weight: var(--meta-b-fweight);
    margin-left: 5px;
    content: attr(data-title)
}

.light-scheme .b-follow .rb-follow {
    border-color: currentColor
}

.archive-header-content {
    flex-grow: 1
}

.archive-header-content .qlinks-inner {
    justify-content: center
}

.subcat-wrap {
    display: flex;
    align-items: center;
    flex-flow: row wrap
}

.subcat-wrap>* {
    font-size: .8rem;
    display: inline-flex
}

.subcat-heading {
    align-items: center;
    margin-right: 4px;
    color: var(--g-color)
}

.subcat-heading i {
    font-size: 11px;
    margin-right: 4px
}

.h5.sub-cat-item a {
    position: relative;
    margin-right: 1px;
    padding: 4px;
    -webkit-transition: var(--effect);
    transition: var(--effect);
    white-space: nowrap
}

.sub-cat-item:not(:last-child) a:after {
    position: absolute;
    top: 50%;
    right: -1px;
    display: inline-flex;
    height: 10px;
    margin-top: -5px;
    content: '';
    border-left: 1px solid var(--flex-gray-15)
}

.archive-header-content>:not(:last-child) {
    margin-bottom: 15px
}
.rb-follow:hover i {
    color: var(--bookmark-color);
    text-shadow: var(--btn-nshadow, 0 0 4px var(--bookmark-color-90))
}
.overlay-text .breadcrumb-inner {
    color: var(--awhite)
}
.archive-header {
    padding-top: 40px;
    padding-bottom: 40px
}

.archive-header-content>:not(:last-child) {
    margin-bottom: 20px
}
@media(max-width:767px) {
    .elementor-50414 .elementor-element.elementor-element-a8abb6a {
        --title-size: 12px
    }

    .elementor-50414 .elementor-element.elementor-element-a8abb6a .block-wrap {
        --bottom-spacing: 20px
    }
    .rb-follow {
        font-size: 11px
    }
    .category-header-1 .archive-inner {
        align-items: flex-start;
        flex-flow: column nowrap
    }
    .breadcrumb-wrap {
        display: none !important
    }
}
.breadcrumb-inner {
    font-family: Roboto;
    font-weight: 500
}
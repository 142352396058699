.site-wrap {
    z-index: 1;
    min-height: 45vh;
}
.site-wrap {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-top: 60px;
}
.page-header-1 {
    margin-bottom: 30px;
    padding-top: 30px;
}
.page-header {
    position: relative;
    display: block;
}
.rb-small-container {
    max-width: var(--rb-small-width, 860px);
}
.entry-content {
    counter-reset: footnotes;
}
.rbct {
    position: relative;
    display: block;
}
.rbct ul {
    list-style: circle;
    padding-left: 1rem;
}
.rbct li {
    position: relative;
    margin-bottom: .5rem;
}
@media (min-width: 1025px) {
    .page-header-1 {
        margin-bottom: 40px;
        padding-top: 40px;
    }
    .e-ct-outer>* {
        margin-bottom: 35px;
    }
}
@media (min-width: 992px) {
    body .without-sidebar .grid-container>* {
        flex: 0 0 100%;
        width: 100%;
    }
}
@media (max-width: 768px) {
    .site-wrap {
        padding-top: 40px;
    }
}
@media (max-width: 992px) {
    .site-wrap {
        padding-top: 40px;
    }
}
:where(.wp-block-button__link) {
    border-radius: 9999px;
    box-shadow: none;
    padding: calc(.667em + 2px) calc(1.333em + 2px);
    text-decoration: none
}

:where(.wp-block-calendar table:not(.has-background) th) {
    background: #ddd
}

:where(.wp-block-columns) {
    margin-bottom: 1.75em
}

:where(.wp-block-columns.has-background) {
    padding: 1.25em 2.375em
}

:where(.wp-block-post-comments input[type=submit]) {
    border: none
}

:where(.wp-block-cover-image:not(.has-text-color)),
:where(.wp-block-cover:not(.has-text-color)) {
    color: #fff
}

:where(.wp-block-cover-image.is-light:not(.has-text-color)),
:where(.wp-block-cover.is-light:not(.has-text-color)) {
    color: #000
}

:where(.wp-block-file) {
    margin-bottom: 1.5em
}

:where(.wp-block-file__button) {
    border-radius: 2em;
    display: inline-block;
    padding: .5em 1em
}

:where(.wp-block-file__button):is(a):active,
:where(.wp-block-file__button):is(a):focus,
:where(.wp-block-file__button):is(a):hover,
:where(.wp-block-file__button):is(a):visited {
    box-shadow: none;
    color: #fff;
    opacity: .85;
    text-decoration: none
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment)) {
    line-height: 1.1
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment-excerpt p)) {
    line-height: 1.8
}

ol,
ul {
    box-sizing: border-box
}

:where(.wp-block-navigation.has-background .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation.has-background .wp-block-navigation-submenu a:not(.wp-element-button)) {
    padding: .5em 1em
}

:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu button.wp-block-navigation-item__content),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-pages-list__item button.wp-block-navigation-item__content) {
    padding: .5em 1em
}

:where(p.has-text-color:not(.has-link-color)) a {
    color: inherit
}

:where(.wp-block-post-excerpt) {
    margin-bottom: var(--wp--style--block-gap);
    margin-top: var(--wp--style--block-gap)
}

:where(.wp-block-preformatted.has-background) {
    padding: 1.25em 2.375em
}

:where(.wp-block-pullquote) {
    margin: 0 0 1em
}

:where(.wp-block-search__button) {
    border: 1px solid #ccc;
    padding: 6px 10px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) {
    border: 1px solid #949494;
    box-sizing: border-box;
    padding: 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input {
    border: none;
    border-radius: 0;
    padding: 0 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input:focus {
    outline: 0
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) :where(.wp-block-search__button) {
    padding: 4px 8px
}

:where(.wp-block-term-description) {
    margin-bottom: var(--wp--style--block-gap);
    margin-top: var(--wp--style--block-gap)
}

:where(pre.wp-block-verse) {
    font-family: inherit
}

.entry-content {
    counter-reset: footnotes
}

:root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px
}

.aligncenter {
    clear: both
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important
}

html :where(.has-border-color) {
    border-style: solid
}

html :where([style*=border-top-color]) {
    border-top-style: solid
}

html :where([style*=border-right-color]) {
    border-right-style: solid
}

html :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

html :where([style*=border-left-color]) {
    border-left-style: solid
}

html :where([style*=border-width]) {
    border-style: solid
}

html :where([style*=border-top-width]) {
    border-top-style: solid
}

html :where([style*=border-right-width]) {
    border-right-style: solid
}

html :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

html :where([style*=border-left-width]) {
    border-left-style: solid
}

html :where(img[class*=wp-image-]) {
    height: auto;
    max-width: 100%
}

:where(figure) {
    margin: 0 0 1em
}

html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

.e-ct-outer h2{
    color: var(--cat-highlight, var(--g-color));
}

.e-ct-outer .link{
    color: var(--cat-highlight, var(--g-color));
}

.e-ct-outer .listmarginleft {
    margin-left: 22px;
}

.image-actions {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    gap: 20px; /* Space between icons */
  }
  
  .action-icon {
    width: 28px; /* Adjust size */
    height: 28px; /* Adjust size */
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .action-icon:hover {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
  }

@media screen and (max-width:600px) {
    html :where(.is-position-sticky) {
        --wp-admin--admin-bar--position-offset: 0px
    }
}
.schema-faq .schema-faq-question {
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    margin: 0;
    padding: 15px 40px 15px 15px;
    line-height: 1.4;
    cursor: pointer;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block
}
.schema-faq .schema-faq-question.faq-q-open {
    border-bottom: 1px solid #d1dfee
}
.schema-faq .schema-faq-question:after {
    content: "+";
    position: absolute;
    top: 0;
    right: 15px;
    text-align: center;
    font-weight: 700;
    color: #000;
    font-size: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center
}
.schema-faq .schema-faq-question.faq-q-open:after {
    content: "-"
}
.schema-faq p.schema-faq-answer {
    margin: 0;
    padding: 15px;
    background-color: #fff;
    font-size: 16px;
    line-height: 1.4;
    border-bottom: 1px solid #dedee0;
    display: none
}
.elementor-hidden {
    display: none
}
.screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}
.e-con {
    --border-radius: 0;
    --border-top-width: 0px;
    --border-right-width: 0px;
    --border-bottom-width: 0px;
    --border-left-width: 0px;
    --border-style: initial;
    --border-color: initial;
    --container-widget-width: 100%;
    --container-widget-height: initial;
    --container-widget-flex-grow: 0;
    --container-widget-align-self: initial;
    --content-width: min(100%, var(--container-max-width, 1140px));
    --width: 100%;
    --min-height: initial;
    --height: auto;
    --text-align: initial;
    --margin-top: 0px;
    --margin-right: 0px;
    --margin-bottom: 0px;
    --margin-left: 0px;
    --padding-top: var(--container-default-padding-top, 10px);
    --padding-right: var(--container-default-padding-right, 10px);
    --padding-bottom: var(--container-default-padding-bottom, 10px);
    --padding-left: var(--container-default-padding-left, 10px);
    --position: relative;
    --z-index: revert;
    --overflow: visible;
    --gap: var(--widgets-spacing, 20px);
    --overlay-mix-blend-mode: initial;
    --overlay-opacity: 1;
    --overlay-transition: 0.3s;
    --e-con-grid-template-columns: repeat(3, 1fr);
    --e-con-grid-template-rows: repeat(2, 1fr);
    position: var(--position);
    width: var(--width);
    min-width: 0;
    min-height: var(--min-height);
    height: var(--height);
    border-radius: var(--border-radius);
    z-index: var(--z-index);
    overflow: var(--overflow);
    transition: background var(--background-transition, .3s), border var(--border-transition, .3s), box-shadow var(--border-transition, .3s), transform var(--e-con-transform-transition-duration, .4s);
    --flex-wrap-mobile: wrap;
    margin-block-start: var(--margin-block-start);
    margin-inline-end: var(--margin-inline-end);
    margin-block-end: var(--margin-block-end);
    margin-inline-start: var(--margin-inline-start);
    padding-inline-start: var(--padding-inline-start);
    padding-inline-end: var(--padding-inline-end);
    --margin-block-start: var(--margin-top);
    --margin-block-end: var(--margin-bottom);
    --margin-inline-start: var(--margin-left);
    --margin-inline-end: var(--margin-right);
    --padding-inline-start: var(--padding-left);
    --padding-inline-end: var(--padding-right);
    --padding-block-start: var(--padding-top);
    --padding-block-end: var(--padding-bottom);
    --border-block-start-width: var(--border-top-width);
    --border-block-end-width: var(--border-bottom-width);
    --border-inline-start-width: var(--border-left-width);
    --border-inline-end-width: var(--border-right-width)
}
body.rtl .e-con {
    --padding-inline-start: var(--padding-right);
    --padding-inline-end: var(--padding-left);
    --margin-inline-start: var(--margin-right);
    --margin-inline-end: var(--margin-left);
    --border-inline-start-width: var(--border-right-width);
    --border-inline-end-width: var(--border-left-width)
}
:is(.elementor-section-wrap, [data-elementor-id])>.e-con {
    --margin-left: auto;
    --margin-right: auto;
    max-width: min(100%, var(--width))
}
.e-con:before,
.e-con>.elementor-background-slideshow:before,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container:before {
    content: var(--background-overlay);
    display: block;
    position: absolute;
    mix-blend-mode: var(--overlay-mix-blend-mode);
    opacity: var(--overlay-opacity);
    transition: var(--overlay-transition, .3s);
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-top-width));
    left: calc(0px - var(--border-left-width));
    width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
    height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%)
}
.e-con>.elementor-background-slideshow,
:is(.e-con, .e-con>.e-con-inner)>.elementor-background-video-container {
    border-radius: var(--border-radius);
    border-style: var(--border-style);
    border-color: var(--border-color);
    border-block-start-width: var(--border-block-start-width);
    border-inline-end-width: var(--border-inline-end-width);
    border-block-end-width: var(--border-block-end-width);
    border-inline-start-width: var(--border-inline-start-width);
    top: calc(0px - var(--border-top-width));
    left: calc(0px - var(--border-left-width));
    width: max(100% + var(--border-left-width) + var(--border-right-width), 100%);
    height: max(100% + var(--border-top-width) + var(--border-bottom-width), 100%)
}
@media (max-width:767px) {
    .e-con.e-flex {
        --width: 100%;
        --flex-wrap: var(--flex-wrap-mobile)
    }
}
.elementor-lightbox {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px
}
.elementor-element .swiper-container .swiper-slide figure,
.elementor-lightbox .swiper .swiper-slide figure,
.elementor-lightbox .swiper-container .swiper-slide figure {
    line-height: 0
}
.elementor-element .swiper .elementor-lightbox-content-source,
.elementor-element .swiper-container .elementor-lightbox-content-source,
.elementor-lightbox .swiper .elementor-lightbox-content-source,
.elementor-lightbox .swiper-container .elementor-lightbox-content-source {
    display: none
}
.elementor-element .swiper .elementor-swiper-button,
.elementor-element .swiper-container .elementor-swiper-button,
.elementor-element .swiper-container~.elementor-swiper-button,
.elementor-element .swiper~.elementor-swiper-button,
.elementor-lightbox .swiper .elementor-swiper-button,
.elementor-lightbox .swiper-container .elementor-swiper-button,
.elementor-lightbox .swiper-container~.elementor-swiper-button,
.elementor-lightbox .swiper~.elementor-swiper-button {
    position: absolute;
    display: inline-flex;
    z-index: 1;
    cursor: pointer;
    font-size: 25px;
    color: hsla(0, 0%, 93.3%, .9);
    top: 50%;
    transform: translateY(-50%)
}
.elementor-element .swiper .elementor-swiper-button svg,
.elementor-element .swiper-container .elementor-swiper-button svg,
.elementor-element .swiper-container~.elementor-swiper-button svg,
.elementor-element .swiper~.elementor-swiper-button svg,
.elementor-lightbox .swiper .elementor-swiper-button svg,
.elementor-lightbox .swiper-container .elementor-swiper-button svg,
.elementor-lightbox .swiper-container~.elementor-swiper-button svg,
.elementor-lightbox .swiper~.elementor-swiper-button svg {
    fill: hsla(0, 0%, 93.3%, .9);
    height: 1em;
    width: 1em
}
.elementor-element .swiper .elementor-swiper-button-prev,
.elementor-element .swiper-container .elementor-swiper-button-prev,
.elementor-element .swiper-container~.elementor-swiper-button-prev,
.elementor-element .swiper~.elementor-swiper-button-prev,
.elementor-lightbox .swiper .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container~.elementor-swiper-button-prev,
.elementor-lightbox .swiper~.elementor-swiper-button-prev {
    left: 10px
}
.elementor-element .swiper .elementor-swiper-button-next,
.elementor-element .swiper-container .elementor-swiper-button-next,
.elementor-element .swiper-container~.elementor-swiper-button-next,
.elementor-element .swiper~.elementor-swiper-button-next,
.elementor-lightbox .swiper .elementor-swiper-button-next,
.elementor-lightbox .swiper-container .elementor-swiper-button-next,
.elementor-lightbox .swiper-container~.elementor-swiper-button-next,
.elementor-lightbox .swiper~.elementor-swiper-button-next {
    right: 10px
}
.elementor-element .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper-container~.elementor-swiper-button.swiper-button-disabled,
.elementor-element .swiper~.elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper-container .elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper-container~.elementor-swiper-button.swiper-button-disabled,
.elementor-lightbox .swiper~.elementor-swiper-button.swiper-button-disabled {
    opacity: .3
}
.elementor-element .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-element .swiper-container .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-lightbox .swiper .swiper-image-stretch .swiper-slide .swiper-slide-image,
.elementor-lightbox .swiper-container .swiper-image-stretch .swiper-slide .swiper-slide-image {
    width: 100%
}
.elementor-element .swiper .swiper-horizontal>.swiper-pagination-bullets,
.elementor-element .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper .swiper-pagination-custom,
.elementor-element .swiper .swiper-pagination-fraction,
.elementor-element .swiper-container .swiper-horizontal>.swiper-pagination-bullets,
.elementor-element .swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container .swiper-pagination-custom,
.elementor-element .swiper-container .swiper-pagination-fraction,
.elementor-element .swiper-container~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper-container~.swiper-pagination-custom,
.elementor-element .swiper-container~.swiper-pagination-fraction,
.elementor-element .swiper~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-element .swiper~.swiper-pagination-custom,
.elementor-element .swiper~.swiper-pagination-fraction,
.elementor-lightbox .swiper .swiper-horizontal>.swiper-pagination-bullets,
.elementor-lightbox .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper .swiper-pagination-custom,
.elementor-lightbox .swiper .swiper-pagination-fraction,
.elementor-lightbox .swiper-container .swiper-horizontal>.swiper-pagination-bullets,
.elementor-lightbox .swiper-container .swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container .swiper-pagination-custom,
.elementor-lightbox .swiper-container .swiper-pagination-fraction,
.elementor-lightbox .swiper-container~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper-container~.swiper-pagination-custom,
.elementor-lightbox .swiper-container~.swiper-pagination-fraction,
.elementor-lightbox .swiper~.swiper-pagination-bullets.swiper-pagination-horizontal,
.elementor-lightbox .swiper~.swiper-pagination-custom,
.elementor-lightbox .swiper~.swiper-pagination-fraction {
    bottom: 5px
}
.elementor-element .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-element .swiper-container.swiper-cube~.elementor-swiper-button,
.elementor-element .swiper.swiper-cube .elementor-swiper-button,
.elementor-element .swiper.swiper-cube~.elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper-container.swiper-cube~.elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube .elementor-swiper-button,
.elementor-lightbox .swiper.swiper-cube~.elementor-swiper-button {
    transform: translate3d(0, -50%, 1px)
}
.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-bullets {
    bottom: 5px;
    left: 0;
    width: 100%
}
.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets .swiper-pagination-bullet,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px)
}
.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-progressbar,
.elementor-lightbox :where(.swiper-container-horizontal)~.swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}

.elementor-element.elementor-pagination-position-outside .swiper-container,
.elementor-lightbox.elementor-pagination-position-outside .swiper,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container {
    padding-bottom: 30px
}
.elementor-element.elementor-pagination-position-outside .swiper-container .elementor-swiper-button,
.elementor-element.elementor-pagination-position-outside .swiper-container~.elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container .elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper-container~.elementor-swiper-button,
.elementor-lightbox.elementor-pagination-position-outside .swiper~.elementor-swiper-button {
    top: calc(50% - 30px / 2)
}
.elementor-lightbox .elementor-swiper {
    position: relative
}
.elementor-lightbox .elementor-main-swiper {
    position: static
}

.elementor-element.elementor-arrows-position-outside .swiper-container,
.elementor-lightbox.elementor-arrows-position-outside .swiper,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container {
    width: calc(100% - 60px)
}
.elementor-element.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-prev,
.elementor-element.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-prev,
.elementor-lightbox.elementor-arrows-position-outside .swiper~.elementor-swiper-button-prev {
    left: 0
}
.elementor-element.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-next,
.elementor-element.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container .elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper-container~.elementor-swiper-button-next,
.elementor-lightbox.elementor-arrows-position-outside .swiper~.elementor-swiper-button-next {
    right: 0
}
.elementor-lightbox {
    --lightbox-ui-color: hsla(0, 0%, 93.3%, 0.9);
    --lightbox-ui-color-hover: #fff;
    --lightbox-text-color: var(--lightbox-ui-color);
    --lightbox-header-icons-size: 20px;
    --lightbox-navigation-icons-size: 25px
}
.elementor-lightbox .elementor-swiper-button {
    color: var(--lightbox-ui-color);
    transition: all .3s;
    opacity: 1
}
.elementor-lightbox .elementor-swiper-button svg {
    fill: var(--lightbox-ui-color)
}
.elementor-lightbox .elementor-swiper-button:hover {
    color: var(--lightbox-ui-color-hover)
}
.elementor-lightbox .elementor-swiper-button:hover svg {
    fill: var(--lightbox-ui-color-hover)
}
.elementor-lightbox .swiper,
.elementor-lightbox .swiper-container {
    height: 100%
}
.elementor-lightbox .elementor-lightbox-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 70px;
    box-sizing: border-box;
    height: 100%;
    margin: auto
}
.elementor-lightbox .elementor-lightbox-image {
    max-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}
.elementor-lightbox .elementor-lightbox-image,
.elementor-lightbox .elementor-lightbox-image:hover {
    opacity: 1;
    filter: none;
    border: none
}
.elementor-lightbox .elementor-lightbox-image {
    box-shadow: 0 0 30px rgba(0, 0, 0, .3), 0 0 8px -5px rgba(0, 0, 0, .3);
    border-radius: 2px
}
.elementor-lightbox .swiper .elementor-swiper-button-prev,
.elementor-lightbox .swiper-container .elementor-swiper-button-prev {
    left: 0
}

.elementor-lightbox .swiper .elementor-swiper-button-next,
.elementor-lightbox .swiper-container .elementor-swiper-button-next {
    right: 0
}

.elementor-lightbox .swiper .swiper-pagination-fraction,
.elementor-lightbox .swiper-container .swiper-pagination-fraction {
    width: -moz-max-content;
    width: max-content;
    color: #fff
}

.elementor-lightbox .elementor-swiper-button:focus {
    outline-width: 1px
}

.elementor-lightbox .elementor-swiper-button-next,
.elementor-lightbox .elementor-swiper-button-prev {
    height: 100%;
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: center;
    font-size: var(--lightbox-navigation-icons-size)
}

@media (max-width:767px) {
    .elementor-lightbox .elementor-lightbox-item {
        padding: 70px 0
    }

    .elementor-lightbox .elementor-swiper-button:focus {
        outline: 0
    }

    .elementor-lightbox .elementor-swiper-button-next,
    .elementor-lightbox .elementor-swiper-button-prev {
        width: 20%
    }

    .elementor-lightbox .elementor-swiper-button-next i,
    .elementor-lightbox .elementor-swiper-button-prev i {
        padding: 10px;
        background-color: rgba(0, 0, 0, .5)
    }

    .elementor-lightbox .elementor-swiper-button-prev {
        left: 0;
        justify-content: flex-start
    }

    .elementor-lightbox .elementor-swiper-button-next {
        right: 0;
        justify-content: flex-end
    }
}

.elementor-slideshow__counter {
    color: currentColor;
    font-size: .75em;
    width: -moz-max-content;
    width: max-content
}

.elementor-slideshow__header {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 15px 20px;
    transition: .3s
}

.elementor-slideshow__header {
    color: var(--lightbox-ui-color);
    display: flex;
    flex-direction: row-reverse;
    font-size: var(--lightbox-header-icons-size);
    padding-inline-start: 1em;
    padding-inline-end: 2.6em;
    top: 0;
    align-items: center;
    z-index: 10
}

.elementor-slideshow__header>i,
.elementor-slideshow__header>svg {
    cursor: pointer;
    padding: .25em;
    margin: 0 .35em
}

.elementor-slideshow__header>i {
    font-size: inherit
}

.elementor-slideshow__header>i:hover {
    color: var(--lightbox-ui-color-hover)
}

.elementor-slideshow__header>svg {
    box-sizing: content-box;
    fill: var(--lightbox-ui-color);
    height: 1em;
    width: 1em
}

.elementor-slideshow__header>svg:hover {
    fill: var(--lightbox-ui-color-hover)
}

.elementor-slideshow__header .elementor-slideshow__counter {
    margin-inline-end: auto
}

.elementor-slideshow__header .elementor-icon-share {
    z-index: 5
}

.elementor-slideshow--ui-hidden .elementor-slideshow__header {
    opacity: 0;
    pointer-events: none
}
.elementor-slideshow--zoom-mode .elementor-slideshow__header {
    background-color: rgba(0, 0, 0, .5)
}
.e-contact-buttons {
    --e-contact-buttons-chat-box-width: 360px;
    --e-contact-buttons-size-small: 65px;
    --e-contact-buttons-size-medium: 75px;
    --e-contact-buttons-size-large: 85px;
    --e-contact-buttons-dot: red;
    --e-contact-buttons-dot-size: 20px;
    --e-contact-buttons-profile-dot-bg: #39aa59;
    --e-contact-buttons-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .25);
    --e-contact-buttons-border-radius: 20px;
    --e-contact-button-chat-button-animation-duration: 800ms;
    --e-contact-button-chat-button-animation-delay: 0;
    --e-contact-buttons-chat-box-animation-duration: 800ms;
    --e-contact-buttons-button-bg: #467ff7;
    --e-contact-buttons-button-bg-hover: #1c2448;
    --e-contact-buttons-button-icon: #fff;
    --e-contact-buttons-button-icon-hover: #fff;
    --e-contact-buttons-top-bar-bg: #1c2448;
    --e-contact-buttons-top-bar-name: #fff;
    --e-contact-buttons-top-bar-title: #fff;
    --e-contact-buttons-close-button-color: #fff;
    --e-contact-buttons-message-bubble-name: #000;
    --e-contact-buttons-message-bubble-body: #000;
    --e-contact-buttons-message-bubble-time: #000;
    --e-contact-buttons-message-bubble-bubble-bg: #fff;
    --e-contact-buttons-message-bubble-chat-bg: #c8d5dc;
    --e-contact-buttons-send-button-icon: #fff;
    --e-contact-buttons-send-button-bg: #467ff7;
    --e-contact-buttons-send-button-icon-hover: #fff;
    --e-contact-buttons-send-button-bg-hover: #1c2448;
    --e-contact-buttons-chat-box-bg: #fff;
    position: fixed;
    bottom: 20px;
    inset-inline-end: 25px;
    width: var(--e-contact-buttons-chat-box-width);
    z-index: 99
}
@media (max-width:767px) {
    .e-contact-buttons {
        inset-inline-end: 0
    }
}
.e-contact-buttons:not(.has-animations) .e-contact-buttons__content-wrapper.hidden {
    display: none
}

.e-contact-buttons.has-animations .e-contact-buttons__content-wrapper.hidden {
    display: block;
    visibility: hidden;
    transition: var(--e-contact-buttons-chat-box-animation-duration)
}
.e-contact-buttons.has-animations .e-contact-buttons__content-wrapper.animated-wrapper {
    visibility: hidden;
    opacity: 0;
    transform: none;
    animation: e-contact-buttons-close var(--e-contact-buttons-chat-box-animation-duration)
}
.e-contact-buttons__content {
    border-radius: var(--e-contact-buttons-border-radius);
    box-shadow: var(--e-contact-buttons-box-shadow);
    font-family: var(--e-global-typography-text-font-family, "Poppins"), Sans-serif;
    margin-block-end: 20px;
    overflow: hidden
}
.e-contact-buttons__content.animated {
    animation-duration: var(--e-contact-buttons-chat-box-animation-duration)
}

.e-contact-buttons__close-button {
    padding: 0;
    position: absolute;
    inset-inline-end: 20px;
    top: 20px
}
.e-contact-buttons__close-button,
.e-contact-buttons__close-button:focus,
.e-contact-buttons__close-button:hover {
    background: 0 0;
    border: 0;
    color: var(--e-contact-buttons-close-button-color)
}
.e-contact-buttons__chat-button {
    align-items: center;
    background-color: var(--e-contact-buttons-button-bg);
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    position: relative
}
.e-contact-buttons__chat-button.animated {
    animation-delay: var(--e-contact-button-chat-button-animation-delay);
    animation-duration: var(--e-contact-button-chat-button-animation-duration)
}

.e-contact-buttons__chat-button svg {
    fill: var(--e-contact-buttons-button-icon)
}
.e-contact-buttons__chat-button:focus,
.e-contact-buttons__chat-button:hover {
    background-color: var(--e-contact-buttons-button-bg-hover)
}

.e-contact-buttons__chat-button:focus svg,
.e-contact-buttons__chat-button:hover svg {
    fill: var(--e-contact-buttons-button-icon-hover)
}
.e-contact-buttons__message-bubble-time {
    color: var(--e-contact-buttons-message-bubble-time);
    font-size: 20px;
    line-height: 25px;
    font-weight: 600;
    text-align: end
}
@keyframes e-contact-buttons-close {

    0%,
    99.99% {
        visibility: visible;
        opacity: 1
    }

    to {
        visibility: hidden;
        opacity: 0;
        transform: none
    }
}
:root {
    --swiper-theme-color: #007aff
}

.swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
}
.swiper-vertical>.swiper-wrapper {
    flex-direction: column
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box
}
.swiper-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0, 0, 0)
}

.swiper-pointer-events {
    touch-action: pan-y
}

.swiper-pointer-events.swiper-vertical {
    touch-action: pan-x
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform
}

.swiper-autoheight,
.swiper-autoheight .swiper-slide {
    height: auto
}
.swiper-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height
}

.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}
.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
    perspective: 1200px
}

.swiper-3d .swiper-cube-shadow,
.swiper-3d .swiper-slide,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-wrapper {
    transform-style: preserve-3d
}
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10
}
.swiper-3d .swiper-slide-shadow {
    background: rgba(0, 0, 0, .15)
}

.swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}
.swiper-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-css-mode>.swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none
}
.swiper-css-mode>.swiper-wrapper::-webkit-scrollbar {
    display: none
}

.swiper-css-mode>.swiper-wrapper>.swiper-slide {
    scroll-snap-align: start start
}

.swiper-horizontal.swiper-css-mode>.swiper-wrapper {
    scroll-snap-type: x mandatory
}

.swiper-vertical.swiper-css-mode>.swiper-wrapper {
    scroll-snap-type: y mandatory
}

.swiper-centered>.swiper-wrapper::before {
    content: '';
    flex-shrink: 0;
    order: 9999
}

.swiper-centered.swiper-horizontal>.swiper-wrapper>.swiper-slide:first-child {
    margin-inline-start: var(--swiper-centered-offset-before)
}

.swiper-centered.swiper-horizontal>.swiper-wrapper::before {
    height: 100%;
    min-height: 1px;
    width: var(--swiper-centered-offset-after)
}

.swiper-centered.swiper-vertical>.swiper-wrapper>.swiper-slide:first-child {
    margin-block-start: var(--swiper-centered-offset-before)
}

.swiper-centered.swiper-vertical>.swiper-wrapper::before {
    width: 100%;
    min-width: 1px;
    height: var(--swiper-centered-offset-after)
}

.swiper-centered>.swiper-wrapper>.swiper-slide {
    scroll-snap-align: center center
}

.swiper-virtual .swiper-slide {
    -webkit-backface-visibility: hidden;
    transform: translateZ(0)
}

.swiper-virtual.swiper-css-mode .swiper-wrapper::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none
}

.swiper-virtual.swiper-css-mode.swiper-horizontal .swiper-wrapper::after {
    height: 1px;
    width: var(--swiper-virtual-size)
}

.swiper-virtual.swiper-css-mode.swiper-vertical .swiper-wrapper::after {
    width: 1px;
    height: var(--swiper-virtual-size)
}

:root {
    --swiper-navigation-size: 44px
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color))
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none
}

.swiper-button-next.swiper-button-hidden,
.swiper-button-prev.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none
}

.swiper-navigation-disabled .swiper-button-next,
.swiper-navigation-disabled .swiper-button-prev {
    display: none !important
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 10px;
    right: auto
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: 'prev'
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 10px;
    left: auto
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: 'next'
}

.swiper-button-lock {
    display: none
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10
}

.swiper-pagination-disabled>.swiper-pagination,
.swiper-pagination.swiper-pagination-disabled {
    display: none !important
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%
}

.swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(.33);
    position: relative
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(.33)
}

.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: 50%;
    background: var(--swiper-pagination-bullet-inactive-color, #000);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, .2)
}

button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}

.swiper-pagination-bullet:only-child {
    display: none !important
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: var(--swiper-pagination-color, var(--swiper-theme-color))
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical>.swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0, -50%, 0)
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
    display: block
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: .2s transform, .2s top
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px)
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s left
}

.swiper-horizontal.swiper-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s right
}

.swiper-pagination-progressbar {
    background: rgba(0, 0, 0, .25);
    position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top
}

.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}

.swiper-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-vertical>.swiper-pagination-progressbar {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0
}

.swiper-pagination-lock {
    display: none
}

.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    -ms-touch-action: none;
    background: rgba(0, 0, 0, .1)
}

.swiper-scrollbar-disabled>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-disabled {
    display: none !important
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%
}

.swiper-scrollbar.swiper-scrollbar-vertical,
.swiper-vertical>.swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%
}

.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    left: 0;
    top: 0
}

.swiper-scrollbar-lock {
    display: none
}

.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-top-color: transparent
}

.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader,
.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader {
    animation: 1s linear infinite swiper-preloader-spin
}

.swiper-lazy-preloader-white {
    --swiper-preloader-color: #fff
}

.swiper-lazy-preloader-black {
    --swiper-preloader-color: #000
}

@keyframes swiper-preloader-spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

.swiper .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000
}

.swiper-free-mode>.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto
}

.swiper-grid>.swiper-wrapper {
    flex-wrap: wrap
}

.swiper-grid-column>.swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column
}

.swiper-fade.swiper-free-mode .swiper-slide {
    transition-timing-function: ease-out
}

.swiper-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity
}

.swiper-fade .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-fade .swiper-slide-active,
.swiper-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-cube {
    overflow: visible
}

.swiper-cube .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1;
    visibility: hidden;
    transform-origin: 0 0;
    width: 100%;
    height: 100%
}

.swiper-cube .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-cube.swiper-rtl .swiper-slide {
    transform-origin: 100% 0
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-cube .swiper-slide-active,
.swiper-cube .swiper-slide-next,
.swiper-cube .swiper-slide-next+.swiper-slide,
.swiper-cube .swiper-slide-prev {
    pointer-events: auto;
    visibility: visible
}

.swiper-cube .swiper-slide-shadow-bottom,
.swiper-cube .swiper-slide-shadow-left,
.swiper-cube .swiper-slide-shadow-right,
.swiper-cube .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-cube .swiper-cube-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
    z-index: 0
}

.swiper-cube .swiper-cube-shadow:before {
    content: '';
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    filter: blur(50px)
}

.swiper-flip {
    overflow: visible
}

.swiper-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1
}

.swiper-flip .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-flip .swiper-slide-active,
.swiper-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-flip .swiper-slide-shadow-bottom,
.swiper-flip .swiper-slide-shadow-left,
.swiper-flip .swiper-slide-shadow-right,
.swiper-flip .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-creative .swiper-slide {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    transition-property: transform, opacity, height
}

.swiper-cards {
    overflow: visible
}

.swiper-cards .swiper-slide {
    transform-origin: center bottom;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden
}

.elementor-kit-50343 {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px
}

.e-con {
    --container-max-width: 1140px
}

.elementor-widget:not(:last-child) {
    margin-block-end: 20px
}

.elementor-element {
    --widgets-spacing: 20px 20px
}

h1.entry-title {
    display: var(--page-title-display)
}
@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px
    }

    .e-con {
        --container-max-width: 1024px
    }
}
@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px
    }

    .e-con {
        --container-max-width: 767px
    }
}
.elementor-1845 .elementor-element.elementor-element-fc0ae81>.elementor-container {
    max-width: 1280px
}

.elementor-1845 .elementor-element.elementor-element-fc0ae81 .elementor-column-gap-custom .elementor-column>.elementor-element-populated {
    padding: 0
}

.elementor-1845 .elementor-element.elementor-element-fc0ae81 {
    margin-top: 0;
    margin-bottom: 50px
}

.elementor-1845 .elementor-element.elementor-element-51bf7b6>.elementor-widget-container {
    padding: 50px 50px 120px;
    background-color: #111318;
    border-radius: 20px 20px 10px 10px
}

.elementor-1845 .elementor-element.elementor-element-28f6f0c {
    --feat-ratio: 80;
    --meta-fsize: 13px;
    --box-color: #FFFFFF;
    --dark-box-color: #111318;
    --feat-list-width: 120px;
    z-index: 2
}

.elementor-1845 .elementor-element.elementor-element-28f6f0c .is-gap-custom {
    margin-left: -20px;
    margin-right: -20px;
    --column-gap: 20px
}

.elementor-1845 .elementor-element.elementor-element-28f6f0c .is-gap-custom .block-inner>* {
    padding-left: 20px;
    padding-right: 20px
}

.elementor-1845 .elementor-element.elementor-element-28f6f0c .p-wrap {
    --el-spacing: 15px
}

.elementor-1845 .elementor-element.elementor-element-28f6f0c>.elementor-widget-container {
    margin: -100px 20px 0
}

@media(max-width:1024px) {
    .elementor-1845 .elementor-element.elementor-element-fc0ae81 {
        margin-top: 0;
        margin-bottom: 40px
    }

    .elementor-1845 .elementor-element.elementor-element-28f6f0c {
        --feat-ratio: 60
    }

    .elementor-1845 .elementor-element.elementor-element-28f6f0c .block-wrap {
        --bottom-spacing: 40px
    }
}

@media(max-width:767px) {
    .elementor-1845 .elementor-element.elementor-element-51bf7b6 .heading-tagline>* {
        font-size: 12px;
        line-height: 1.3em
    }

    .elementor-1845 .elementor-element.elementor-element-51bf7b6>.elementor-widget-container {
        padding: 30px 20px;
        border-radius: 10px 10px 10px 10px
    }

    .elementor-1845 .elementor-element.elementor-element-28f6f0c {
        --title-size: 12px
    }

    .elementor-1845 .elementor-element.elementor-element-28f6f0c .block-wrap {
        --bottom-spacing: 20px
    }

    .elementor-1845 .elementor-element.elementor-element-28f6f0c>.elementor-widget-container {
        margin: 0
    }
}
h5,
ol,
td {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0
}
.screen-reader-text {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    word-wrap: normal !important;
    border: 0;
    clip-path: inset(50%);
    -webkitcolorip-path: inset(50%)
}
.clearfix:after,
.clearfix:before {
    display: table;
    clear: both;
    content: ' '
}
.h5,
h5 {
    font-family: var(--h5-family);
    font-size: var(--h5-fsize);
    font-weight: var(--h5-fweight);
    font-style: var(--h5-fstyle);
    line-height: var(--h5-fheight, 1.5);
    letter-spacing: var(--h5-fspace);
    text-transform: var(--h5-transform)
}

h5 {
    color: var(--h5-fcolor)
}
h5.entry-title {
    font-size: var(--title-size, var(--h5-fsize))
}
.p-categories {
    font-family: var(--cat-family);
    font-size: var(--cat-fsize);
    font-weight: var(--cat-fweight);
    font-style: var(--cat-fstyle);
    letter-spacing: var(--cat-fspace);
    text-transform: var(--cat-transform)
}
.meta-label {
    font-family: var(--meta-family);
    font-weight: var(--meta-fweight);
    font-style: var(--meta-fstyle);
    letter-spacing: var(--meta-fspace);
    text-transform: var(--meta-transform);
    color: var(--meta-fcolor)
}
.rbbsl a>span {
    font-family: var(--btn-family);
    font-size: var(--btn-fsize);
    font-weight: var(--btn-fweight);
    font-style: var(--btn-fstyle);
    letter-spacing: var(--btn-fspace);
    text-transform: var(--btn-transform)
}
.breadcrumb-inner {
    font-family: 'encode sans condensed', sans-serif;
    font-size: var(--bcrumb-fsize, 13px);
    font-weight: 600;
    font-style: normal;
    line-height: 1.5;
    color: var(--bcrumb-color, inherit)
}
.s-title {
    font-family: var(--headline-family, var(--h1-family));
    font-size: var(--headline-fsize, var(--h1-fsize));
    font-weight: var(--headline-fweight, var(--h1-fweight));
    font-style: var(--headline-fstyle, var(--h1-fstyle));
    line-height: var(--headline-fheight, var(--h1-fheight, 1.2));
    letter-spacing: var(--headline-fspace, var(--h1-fspace));
    text-transform: var(--headline-transform, var(--h1-transform));
    color: var(--headline-fcolor, var(--h1-fcolor))
}

.s-title:not(.fw-headline) {
    font-size: var(--headline-s-fsize, var(--h1-fsize))
}
.rb-section ol,
.rb-section ul {
    list-style: none
}
h5 {
    -ms-word-wrap: break-word;
    word-wrap: break-word
}
.is-color .icon-facebook {
    color: var(--fb-color)
}

.is-color .icon-facebook:hover {
    color: var(--fb-hcolor);
    text-shadow: 0 3px 12px var(--fb-hcolor)
}

.is-bg .icon-facebook {
    background-color: var(--fb-color)
}

.is-bg .icon-facebook:hover {
    background-color: var(--fb-hcolor);
    box-shadow: 0 3px 12px var(--fb-hcolor)
}

.is-color .icon-twitter {
    color: var(--twitter-color)
}

.is-color .icon-twitter:hover {
    color: var(--twitter-hcolor);
    text-shadow: 0 3px 12px var(--twitter-hcolor)
}

.is-bg .icon-twitter {
    background-color: var(--twitter-color)
}

.is-bg .icon-twitter:hover {
    background-color: var(--twitter-hcolor);
    box-shadow: 0 3px 12px var(--twitter-hcolor)
}

.is-color .icon-whatsapp {
    color: #00e676
}

.is-color .icon-whatsapp:hover {
    color: #00e537;
    text-shadow: 0 3px 12px #00e537
}

.is-bg .icon-whatsapp {
    background-color: #00e676
}

.is-bg .icon-whatsapp:hover {
    background-color: #00e537;
    box-shadow: 0 3px 12px #00e537
}

.is-color .icon-reddit {
    color: #ff4500
}

.is-color .icon-reddit:hover {
    color: #e02200;
    text-shadow: 0 3px 12px #e02200
}

.is-bg .icon-reddit {
    background-color: #ff4500
}

.is-bg .icon-reddit:hover {
    background-color: #e02200;
    box-shadow: 0 3px 12px #e02200
}

.is-color .icon-copy {
    color: #66bbbf
}

.is-color .icon-copy:hover {
    color: #38787a
}

.is-bg .icon-copy {
    background-color: #66bbbf
}

.is-bg .icon-copy:hover {
    background-color: #38787a;
    box-shadow: 0 3px 12px #38787a
}
.is-bg {
    color: var(--awhite)
}

.is-bg * {
    color: inherit
}
.e-shared-header i {
    margin-right: 7px
}
.rtl .rbi.rbi-whatsapp:before,
.rtl .rbi.rbi-youtube:before {
    -webkit-transform: none;
    transform: none
}
.rbi-share:before {
    content: '\e94e'
}

.rbi-read:before {
    content: '\e94c'
}
.rbi-whatsapp:before {
    content: '\e93b'
}
.rbi-link-o:before {
    content: '\e9cb'
}
.rbi-more:before {
    content: '\e987'
}
.swiper-container-vertical>.swiper-wrapper {
    flex-direction: column
}
.swiper-container-android .swiper-slide {
    transform: translate3d(0, 0, 0)
}
.swiper-container-multirow>.swiper-wrapper {
    flex-wrap: wrap
}

.swiper-container-multirow-column>.swiper-wrapper {
    flex-direction: column;
    flex-wrap: wrap
}

.swiper-container-free-mode>.swiper-wrapper {
    margin: 0 auto;
    transition-timing-function: ease-out
}

.swiper-container-pointer-events {
    touch-action: pan-y
}

.swiper-container-pointer-events.swiper-container-vertical {
    touch-action: pan-x
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto
}

.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height
}

.swiper-container-3d {
    perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
    transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none
}

.swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-css-mode>.swiper-wrapper {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none
}

.swiper-container-css-mode>.swiper-wrapper::-webkit-scrollbar {
    display: none
}

.swiper-container-css-mode>.swiper-wrapper>.swiper-slide {
    scroll-snap-align: start start
}

.swiper-container-horizontal.swiper-container-css-mode>.swiper-wrapper {
    scroll-snap-type: x mandatory
}

.swiper-container-vertical.swiper-container-css-mode>.swiper-wrapper {
    scroll-snap-type: y mandatory
}

.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    z-index: 10;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    cursor: pointer;
    color: var(--swiper-navigation-color, var(--g-color))
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    cursor: auto;
    pointer-events: none;
    opacity: .35
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: var(--swiper-navigation-size);
    font-variant: initial;
    line-height: 1;
    letter-spacing: 0;
    text-transform: none
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    right: auto;
    left: 10px
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: 'prev'
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: 'next'
}

.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff
}

.swiper-button-next.swiper-button-black,
.swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000
}
.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%
}
.swiper-container-vertical>.swiper-pagination-bullets {
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    display: block;
    margin: 6px 0
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    width: 8px;
    transform: translateY(-50%)
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: .2s transform, .2s top
}

.block-wrap .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s left
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s right
}

.swiper-pagination-progressbar {
    position: absolute;
    background: var(--dark-accent-90)
}

body .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
    background: var(--g-color)
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    right: 30%;
    bottom: 15px;
    left: 30%;
    overflow: hidden;
    height: 3px;
    border-radius: 5px
}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
    top: 0;
    left: 0;
    height: 2px
}

.swiper-scrollbar {
    position: relative;
    border-radius: var(--round-7);
    background: var(--dark-accent-90);
    -ms-touch-action: none
}

.swiper-container-horizontal>.swiper-scrollbar {
    position: absolute;
    z-index: 50;
    bottom: 3px;
    left: 1%;
    width: 98%;
    height: 5px
}

.swiper-container-vertical>.swiper-scrollbar {
    position: absolute;
    z-index: 50;
    top: 1%;
    right: 3px;
    width: 5px;
    height: 98%
}
.swiper-scrollbar-cursor-drag {
    cursor: move
}
.swiper-lazy-preloader-white {
    --swiper-preloader-color: #ffffff
}

.swiper-lazy-preloader-black {
    --swiper-preloader-color: #000000
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
    transition-property: opacity;
    pointer-events: none
}

.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-cube {
    overflow: visible
}

.swiper-container-cube .swiper-slide {
    z-index: 1;
    visibility: hidden;
    width: 100%;
    height: 100%;
    transform-origin: 0 0;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-container-cube .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
    transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
    visibility: visible;
    pointer-events: auto
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-container-cube .swiper-cube-shadow {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6
}

.swiper-container-cube .swiper-cube-shadow:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: #000;
    -webkit-filter: blur(50px);
    filter: blur(50px)
}

.swiper-container-flip {
    overflow: visible
}

.swiper-container-flip .swiper-slide {
    z-index: 1;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}
/*.navbar-wrap:not(.navbar-transparent),
.sticky-on .navbar-wrap {
    background: var(--nav-bg);
    background: -webkit-linear-gradient(left, var(--nav-bg-from) 0%, var(--nav-bg-to) 100%);
    background: linear-gradient(to right, var(--nav-bg-from) 0%, var(--nav-bg-to) 100%)
}*/
.sticky-on .navbar-wrap,
.style-shadow .navbar-wrap:not(.navbar-transparent) {
    box-shadow: 0 4px 30px var(--shadow-7)
}
/*body:not([data-theme=dark]) .is-mega-category .mega-dropdown-inner {
    background: var(--mega-bg, var(--subnav-bg));
    background: -webkit-linear-gradient(left, var(--mega-bg, var(--subnav-bg-from)) 0%, var(--mega-bg, var(--subnav-bg-to)) 100%);
    background: linear-gradient(to right, var(--mega-bg, var(--subnav-bg-from)) 0%, var(--mega-bg, var(--subnav-bg-to)) 100%)
}*/
.sidebar-wrap .ad-wrap {
    padding-right: 0;
    padding-left: 0
}
.breadcrumb-inner a,
.is-meta .meta-author a {
    -webkit-transition: var(--effect);
    transition: var(--effect);
    text-decoration-color: transparent;
    text-decoration-line: underline;
    text-underline-offset: 1px
}
.breadcrumb-inner a:hover,
.is-meta a:hover,
.single-meta .meta-author a {
    text-decoration: underline solid var(--g-color)
}

.single-meta .meta-author a:hover,
a.nice-name:hover {
    color: var(--g-color)
}
.sticky-share-list .t-shared-header {
    opacity: .5
}
.t-shared-header {
    font-size: var(--em-mini);
    display: inline-flex;
    margin-right: 7px;
    white-space: nowrap;
    gap: 5px
}
.qlink-label {
    margin-right: var(--label-spacing, 0);
    color: var(--label-color, var(--meta-fcolor))
}
.swiper-slide-active .breaking-news-title.entry-title {
    opacity: 1
}
.breadcrumb-wrap:not(.breadcrumb-line-wrap) .breadcrumb-inner {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis
}

body.rtl .breadcrumb-inner {
    display: flex
}

body.rtl .breadcrumb-inner>* {
    margin: 0 2px
}

body.rtl .breadcrumb-inner>:first-child {
    margin-right: 0
}

.breadcrumb-wrap:not(.breadcrumb-line-wrap) .current-item,
.breadcrumb-wrap:not(.breadcrumb-line-wrap) .last {
    opacity: .5
}
.sidebar-inner .widget {
    clear: both;
    width: 100%;
    margin-bottom: 30px
}
.sidebar-inner {
    top: 0
}

.sidebar-inner .widget {
    overflow: inherit
}

.socials-counter.is-bg *,
.socials-counter.is-h-bg .follower-el:hover * {
    color: var(--awhite)
}
.is-bg {
    --twitter-color: #00151c;
    --medium-color: #000000
}

.is-bg .follower-inner {
    background: var(--s-icon-color)
}

.is-bg .follower-el:hover .follower-inner,
.is-h-bg .follower-el:hover .follower-inner {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
    background-color: var(--s-icon-hcolor);
    box-shadow: var(--btn-shadow, 0 3px 12px var(--s-icon-hcolor))
}
.grid-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px
}

.grid-container>* {
    flex: 0 0 100%;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px
}

.single-meta {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 15px
}

.single-meta.yes-wrap {
    flex-flow: row wrap
}

.single-meta.yes-border {
    padding-top: 15px;
    border-top: 4px solid var(--flex-gray-15)
}

.smeta-in {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 12px
}

.smeta-in>.meta-avatar img {
    width: var(--b-avatar-size, 60px);
    height: var(--b-avatar-size, 60px);
    margin-left: 0 !important;
    border-radius: var(--avatar-radius, 100%)
}
.is-meta-author-color .meta-author a {
    color: var(--g-color);
    text-decoration-color: transparent
}

.is-meta-author-color .meta-author a:hover {
    text-decoration-color: var(--g-color)
}

.share-action i {
    text-shadow: 3px 3px 0 var(--flex-gray-15)
}
.share-action.is-mobile.icon-whatsapp {
    display: flex
}

.share-action.is-web.icon-whatsapp {
    display: none
}

.smeta-extra {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-shrink: 0
}

.share-action {
    -webkit-transition: var(--effect);
    transition: var(--effect)
}

.t-shared-sec {
    display: flex;
    align-items: center
}
.t-shared-sec>:not(.t-shared-header) {
    display: flex;
    flex-flow: row wrap;
    gap: 3px
}

.sticky-share-list .share-action,
.t-shared-sec .share-action {
    font-size: max(1.05rem, 15px);
    line-height: 32px;
    display: inline-flex;
    justify-content: center;
    min-width: 28px
}

a.native-share-trigger {
    background-color: transparent
}

a.native-share-trigger:hover {
    color: var(--g-color)
}

.native-share-trigger i {
    font-size: 1.3em
}

.yes-wrap .share-action {
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 1px solid var(--flex-gray-15);
    border-radius: 50%
}

.yes-wrap .native-share-trigger {
    border: none
}

.yes-wrap .t-shared-header {
    margin-right: 12px
}

.yes-wrap>* {
    width: 100%
}

.single-header {
    position: relative;
    z-index: 109;
    display: block
}

.s-breadcrumb,
.s-cats,
.s-title,
.single-meta {
    margin-bottom: 15px
}

.s-feat-outer {
    margin-bottom: 30px
}
.s-feat img {
    display: block;
    width: 100%;
    object-fit: cover
}

.ubox:not(:first-child) {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid var(--flex-gray-15)
}

.ubox-header {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: 100%
}

.author-info-wrap {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-grow: 1
}

.author-avatar {
    width: 50px;
    height: auto;
    margin-right: 12px
}

.author-avatar img {
    border-radius: 50%
}

.author-job {
    line-height: 1.5;
    display: flex
}

.ubox .ubio {
    display: block;
    margin-top: 20px;
    margin-bottom: 0
}
.e-shared-sec {
    display: flex;
    align-items: center;
    flex-flow: row nowrap
}

.rbbsl {
    font-size: 18px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin-left: auto;
    gap: 7px
}

.rbbsl>* {
    line-height: var(--height-40);
    display: inline-flex;
    padding: 0 20px;
    border-radius: var(--round-3);
    background-color: var(--flex-gray-7)
}

.rbbsl a>span {
    margin-left: 7px
}

.rbbsl a:not(:first-child)>span {
    display: none
}

.rbbsl a:not(:first-child) {
    justify-content: center;
    padding: 0 12px
}

.entry-sec {
    position: relative;
    padding-top: 37px;
    padding-bottom: 35px
}

.entry-sec:before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    height: 3px;
    content: '';
    background-image: linear-gradient(to right, var(--flex-gray-15) 20%, transparent 21%, transparent 100%);
    background-image: -webkit-linear-gradient(right, var(--flex-gray-15) 20%, transparent 21%, transparent 100%);
    background-repeat: repeat-x;
    background-position: 0 0;
    background-size: 5px
}
.e-shared-sec.entry-sec {
    padding-top: 18px;
    padding-bottom: 15px
}

.e-shared-header {
    display: flex;
    align-items: center;
    flex-flow: row nowrap
}

.e-shared-sec .native-share-trigger {
    color: var(--body-fcolor)
}

.reading-indicator {
    position: fixed;
    z-index: 9041;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    overflow: hidden;
    width: 100%;
    height: var(--indicator-height)
}
.sfoter-sec {
    position: relative
}

.sfoter-sec>.block-h {
    margin-bottom: 20px
}

.single-related {
    background: #151515;
    background-image: url(../../assets/images/bg-image.31b166a9648293966af1.svg);
    /*margin-bottom: 40px;*/
    padding-top: 30px;
    padding-bottom: 30px;
}

.single-related:before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 1px;
    margin-left: -50vw;
    content: '';
    pointer-events: none;
    background-color: var(--flex-gray-15)
}

.single-standard-1 .single-header {
    padding-top: 15px
}

.single-standard-1 .s-feat img {
    border-radius: var(--round-5)
}
.format-gallery-carousel .swiper-container {
    overflow: visible
}

.format-gallery-carousel .swiper-slide {
    width: auto
}

.format-gallery-carousel .swiper-scrollbar {
    position: relative;
    height: 2px;
    margin-top: 20px
}

.format-gallery-coverflow .swiper-pagination {
    position: relative;
    margin-top: 20px
}
.block-wrap .swiper-container-horizontal>.swiper-pagination-bullets {
    line-height: 0;
    position: absolute;
    z-index: 20;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: auto;
    height: auto;
    padding: 15px
}
.e-ct-outer>* {
    margin-bottom: 30px
}
.optimal-line-length:not(.without-sidebar) .s-ct-wrap:not(.has-lsl) {
    max-width: 730px;
    margin-right: auto;
    margin-left: auto
}

.has-lsl .s-ct-inner {
    display: flex;
    flex-flow: row nowrap
}

.l-shared-sec-outer {
    position: relative;
    flex-shrink: 0;
    padding-right: 30px;
    padding-bottom: 40px
}

.has-lsl .e-ct-outer {
    flex-grow: 1;
    width: calc(100% - 78px);
    max-width: calc(100% - 78px);
    margin-left: auto
}

.l-shared-sec {
    font-size: 18px;
    position: sticky;
    position: -webkit-sticky;
    top: 12px;
    -webkit-transition: top .3s;
    transition: top .3s ease;
    will-change: top
}

.l-shared-items {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    border-radius: var(--round-5);
    box-shadow: 0 5px 30px var(--shadow-7)
}

[data-theme=dark] .l-shared-items,
[data-theme=dark] .rbtoc {
    background-color: var(--dark-accent)
}

.l-shared-sec .share-action {
    line-height: 48px;
    display: inline-flex;
    justify-content: center;
    width: 48px;
    border-radius: var(--round-5)
}

.l-shared-header {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    margin-bottom: 10px
}

.l-shared-header i {
    font-size: 18px
}
.rbct>h4,
.rbct>h5 {
    margin-bottom: var(--rem-mini)
}
.rbct .clearfix,
.rbct>:last-child {
    margin-bottom: 0
}
.rbct p img.aligncenter,
.rbct>.aligncenter,
img.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto
}
.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto
}
.rbct ol,
.rbct ul {
    padding-left: 1rem;
    list-style-type: decimal
}
.rbct li>ol,
.rbct li>ul {
    padding-top: .5rem
}
.rbct>ol[class*=wp-block],
.rbct>ul[class*=wp-block] {
    padding-left: 0
}
td {
    border: 1px solid var(--flex-gray-15)
}

td {
    border-color: var(--flex-gray-15)
}
.rbtoc {
    display: inline-flex;
    float: none;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    padding: 20px 20px 13px;
    border-radius: var(--round-5);
    box-shadow: 0 5px 30px var(--shadow-7)
}

.elementor-widget-container .rbtoc,
.elementor-widget-text-editor p:not(:last-of-type) {
    margin-bottom: 1.5em
}

.toc-header {
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 100%
}

.toc-header .rbi-read {
    line-height: 0;
    margin-right: 7px;
    color: var(--g-color)
}
.table-link {
    counter-reset: table-content-subsection
}

.table-link:before {
    content: counter(table-content);
    counter-increment: table-content
}
.medium-entry-size .rbct h5 {
    font-size: calc(var(--h5-fsize) * 1.1)
}
.big-entry-size .rbct h5 {
    font-size: calc(var(--h5-fsize) * 1.2)
}
.ubio {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    flex-grow: 1
}

.w-sidebar {
    margin-bottom: 50px
}
.overlay-text .meta-label,
.overlay-text .meta-text {
    color: var(--absolute-light);
    --meta-b-fcolor: var(--awhite);
    --meta-fcolor: var(--absolute-light)
}
.overlay-text .breadcrumb-inner,
.overlay-text .entry-title,
.overlay-text .meta-author :not(em),
.overlay-text .rb-bookmark,
.overlay-text .s-title,
.overlay-text .s-title.fw-headline,
.overlay-text .t-shared-sec .share-action,
[data-theme=dark] .l-shared-sec .share-action,
[data-theme=dark] .sticky-share-list .share-action,
[data-theme=dark] .t-shared-sec .share-action,
[data-theme=dark] a.native-share-trigger {
    color: var(--awhite)
}
@media(min-width:768px) {
    .nname-info .nice-name,
    .smeta-in .is-meta>*,
    .smeta-in .meta-text>* {
        font-size: 1.15em
    }

    .sticky-on .l-shared-sec {
        top: calc(var(--nav-height) + 12px)
    }

    .sticky-on.admin-bar .l-shared-sec {
        top: calc(var(--nav-height) + 44px)
    }

    .s-ct {
        padding-bottom: 15px
    }

    .single-related {
        /*margin-bottom: 50px;*/
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .sidebar-inner .widget {
        margin-bottom: 40px
    }

    .table-fw {
        padding: 25px 25px 18px
    }

    .table-fw .toc-content {
        column-count: 2;
        gap: 30px
    }
}
@media(min-width:992px) {
    .grid-container {
        flex-flow: row nowrap;
        text-align: justify;
        text-justify: inter-word;
    }

    .grid-container>:first-child {
        flex: 0 0 80.67%;
        width: 80.67%
    }

    .grid-container>:nth-child(2) {
        flex: 0 0 33.33%;
        width: 33.33%
    }
}
@media(min-width:1025px) {
    .grid-container {
        margin-right: -30px;
        margin-left: -30px
    }

    .grid-container>* {
        padding-right: 30px;
        padding-left: 30px
    }
    .admin-bar .reading-indicator {
        top: 32px
    }

    .s-feat-outer {
        margin-bottom: 40px
    }

    .single-standard-1 .single-header {
        padding-top: 20px;
        padding-bottom: 10px
    }

    .single-standard-1 .s-feat img {
        width: auto;
        max-width: 100%
    }

    .s-breadcrumb,
    .s-title {
        text-align: left;
        margin-bottom: 20px
    }

    .single-meta {
        margin-bottom: 30px
    }
    .share-action.is-mobile {
        display: none
    }

    .share-action.is-web {
        display: flex
    }

    .sticky-sidebar .sidebar-inner {
        position: -webkit-sticky;
        position: sticky;
        top: 12px;
        padding-bottom: 12px;
        -webkit-transition: .3s;
        transition: all .3s ease;
        will-change: top
    }
    .admin-bar .l-shared-sec,
    .admin-bar .sticky-sidebar .sidebar-inner {
        top: 44px
    }

    .sticky-on .sticky-sidebar .sidebar-inner {
        top: calc(var(--nav-height) + 12px)
    }

    .sticky-on.admin-bar .sticky-sidebar .sidebar-inner {
        top: calc(var(--nav-height) + 44px)
    }
    .e-ct-outer>* {
        margin-bottom: 35px
    }
    .rbbsl a:nth-child(2) {
        padding: 0 15px
    }

    .rbbsl a:nth-child(2)>span {
        display: inline-flex
    }
}
@media(max-width:1024px) {
    .sidebar-inner {
        max-width: 370px;
        margin-top: 40px;
        margin-right: auto;
        margin-left: auto
    }

    .single-post .sidebar-inner {
        margin-top: 15px
    }
}
@media(max-width:991px) {
    .w-sidebar {
        margin-bottom: 40px
    }

    .single-meta {
        flex-flow: column-reverse nowrap
    }

    .single-meta>* {
        width: 100%;
        gap: 10px
    }

    .smeta-extra:not(:empty) {
        justify-content: space-between;
        padding: 7px 15px;
        border-radius: var(--round-5);
        background-color: var(--flex-gray-7)
    }

    .yes-wrap .smeta-extra:not(:empty) {
        padding: 0;
        background-color: transparent
    }

    .yes-wrap .share-action {
        width: 34px;
        height: 34px
    }

    .sticky-share-list .share-action {
        font-size: 14px
    }
}
@media(max-width:767px) {

    .l-shared-header,
    .l-shared-sec-outer:not(.show-mobile),
    .single-post-infinite .l-shared-sec-outer.show-mobile {
        display: none
    }

    .l-shared-sec-outer.show-mobile,
    .single-post-infinite .activated .l-shared-sec-outer.show-mobile {
        position: fixed;
        z-index: 9900;
        right: 0;
        bottom: 10px;
        left: 0;
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0
    }

    .show-mobile .l-shared-items {
        flex-flow: row nowrap;
        background: var(--solid-white)
    }

    .show-mobile .l-shared-sec {
        position: relative;
        top: 0;
        display: flex;
        flex-grow: 1;
        justify-content: center
    }

    .l-shared-sec-outer.show-mobile .share-action {
        line-height: 42px;
        width: 42px
    }

    .has-lsl .e-ct-outer,
    body .has-lsl .e-ct-outer {
        width: 100%;
        max-width: 100%
    }

    .e-shared-header span {
        display: none
    }

    .rbbsl {
        font-size: 14px
    }

    .rbbsl>* {
        padding: 0 15px
    }

    .entry-sec {
        padding-top: 27px;
        padding-bottom: 25px
    }
    .format-gallery-coverflow .swiper-pagination {
        margin-top: 10px
    }
    .collapse-sections:before,
    .entry-sec:before {
        height: 2px
    }
    .author-avatar {
        width: 36px
    }

    .nname-info .meta-label {
        display: none
    }
    .is-m-list .grid-box {
        position: static;
        padding-top: var(--box-spacing);
        padding-right: var(--feat-list-width, 150px);
        padding-bottom: var(--box-spacing);
        padding-left: 0
    }
    .t-shared-header .share-label {
        display: none
    }
}
@media print {
    .breadcrumb-wrap,
    .entry-sec,
    .featured-gallery-wrap,
    .footer-wrap,
    .header-wrap,
    .l-shared-sec-outer,
    .ruby-table-contents,
    .s-cats,
    .s-ct .widget,
    .s-ct img,
    .sfoter-sec,
    .single-meta .meta-avatar,
    .single-related,
    .single-sidebar,
    .smeta-extra,
    .t-shared-sec,
    .tipsy,
    .top-site-ad {
        display: none !important
    }

    .s-ct {
        flex-basis: 100% !important;
        width: 100% !important;
        max-width: 100% !important
    }
}
/*:root {
    --g-color: #ff0070;
    --g-color-90: #ff0070e6;
    --bcrumb-color: #282828
}*/
.breadcrumb-inner {
    font-family: Roboto;
    font-weight: 500
}

tbody td {
    border: 2px solid;
    border-color: var(--heading-sub-color);
    padding: 8px
}
@media screen and (max-width:480px) {
    tbody td {
        width: auto;
        max-width: 175px;
        margin: auto
    }
}
.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full page height */
  
}
  
  
.loading-text {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    animation: blink 1.5s steps(5, start) infinite;
}
  
@keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.s-ct-wrap bold {
    font-weight: bold;
}